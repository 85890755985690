import { forwardRef } from 'react';

import {
  FieldWrapper,
  FieldWrapperProps,
} from '../field-wrapper/field-wrapper';
import { Label } from '../label/label';

import { StyledInput } from './input.styled';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inline?: boolean;
  label?: string;
  wrapperProps?: Omit<FieldWrapperProps, 'children'>;
  wrapperStyle?: React.CSSProperties;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label = '',
      id,
      inline,
      wrapperProps,
      type = 'text',
      wrapperStyle,
      ...rest
    },
    ref,
  ) => {
    return (
      <FieldWrapper inline={inline} style={wrapperStyle}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <StyledInput id={id} type={type} ref={ref} {...rest} />
      </FieldWrapper>
    );
  },
);
