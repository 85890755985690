import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';
import { buttonReset } from '@hultafors/shared/helpers';

import {
  colors,
  depth,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

import { Icon } from '../icon/icon';

export const ThumbNailWrapper = styled.div`
  display: none;

  img {
    object-fit: contain;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
    flex-direction: column;
    gap: ${spacing.m};
  }
`;

export const Thumbnail = styled.button<{ $active?: boolean }>`
  ${buttonReset};
  background-color: ${colors.white};
  position: relative;
  display: block;
  line-height: 0;
  padding: ${spacing.xs};
  cursor: pointer;
  border: thin solid ${({ $active }) => ($active ? colors.grey3 : colors.grey2)};
`;

export const SwiperContainer = styled.div`
  background-color: ${colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
  overflow-x: hidden;
  border: thin solid ${colors.grey3};
  position: relative;
`;

export const SwiperWrapper = styled.div`
  aspect-ratio: 1 / 1;
  display: flex;
`;

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacing.m};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    grid-template-columns: 100px 1fr;
  }
`;

export const SwiperSlide = styled.div`
  position: relative;
  min-width: 100%;

  img {
    object-fit: contain;
    inset: 0;
    padding: 40px;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing.xs};
  z-index: 2;
`;

export const Pagination = styled.div`
  text-align: end;
  pointer-events: none;
  font-size: ${fontSizes.paragraph12};
  line-height: ${lineHeights.$paragraph12};
  padding-block-end: ${spacing.xxs};
  padding-inline-end: ${spacing.s};
  position: absolute;
  bottom: 0;
  inline-size: 100%;
  align-items: flex-end;
`;

export const NavigationButton = styled.button<{ $flip?: boolean }>`
  ${buttonReset};
  display: none;
  position: absolute;
  inset-block-start: calc(50% - 24px);
  inset-inline-end: 0;
  cursor: pointer;
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
  z-index: 1;

  svg {
    transform: rotate(-90deg);
  }

  ${({ $flip }) =>
    $flip &&
    css`
      inset-inline-start: 0;

      svg {
        transform: rotate(90deg);
      }
    `};
  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
  }
`;

export const ModalImageWrapper = styled.div`
  position: relative;
  aspect-ratio: 1 / 1;
  max-height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
  }
`;

export const ZoomContainer = styled.div`
  display: none;
  block-size: 100%;
  inline-size: 100%;
  justify-content: flex-end;
  position: absolute;
  z-index: 1;
  padding: ${spacing.xs};

  svg path {
    color: ${colors.grey4};
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
  }
`;

export const ProductFeaturesList = styled.ul<{
  $absolute?: boolean;
  $direction: 'row' | 'column';
}>`
  margin: 0;
  z-index: 3;
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  gap: 8px;

  ${({ $absolute }) =>
    $absolute &&
    css`
      left: ${spacing.xs};
      position: absolute;
      top: ${spacing.xs};
      z-index: ${depth.elevated};
    `}
  li {
    display: flex;
    margin-inline-end: ${spacing.xs};
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s};
`;

export const FeatureListItem = styled.li`
  list-style: none;
`;

export const CarbonLabel = styled(Icon).attrs({ svg: 'reduced_co2' })`
  z-index: 3;
  top: ${spacing.xs};
  left: ${spacing.xs};
`;

export const AluminiumLabel = styled(Icon).attrs({ svg: 'reduced_aluminium' })`
  z-index: 3;
  top: ${spacing.xs};
  left: ${spacing.xs};
`;
