import styled from 'styled-components';

import { spacing } from '@hultafors/wibe/helpers';

import { Button } from '../button/button';
import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';
import { TextButton } from '../text-button/text-button';

export const IntroSectionStyled = styled.div`
  margin-block-end: ${spacing.l};
`;

export const Title = styled(HTag).attrs({
  styleType: 'header1',
  type: 'h1',
})`
  max-width: 24ch;
  margin-block-end: ${spacing.m};
`;

export const Description = styled(Paragraph).attrs({
  styleType: 'header4',
})`
  max-width: 50ch;
`;

export const SecondCta = styled(TextButton).attrs({
  arrow: true,
})`
  margin-block-start: ${spacing.m};
  margin-inline-start: ${spacing.m};
`;

export const StyledTextButton = styled(TextButton).attrs({
  arrow: true,
})`
  margin-block-start: ${spacing.m};
  margin-inline-end: ${spacing.m};
`;

export const RoundedButton = styled(Button).attrs({
  variant: 'rounded',
})`
  margin-block-start: ${spacing.m};
  margin-inline-end: ${spacing.m};
`;
