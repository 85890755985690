import { ContentWrapper } from './not-found-container.styled';

interface NotFoundContainerProps {
  children?: React.ReactNode;
}

export const NotFoundContainer: React.FC<NotFoundContainerProps> = ({
  children,
}) => {
  return <ContentWrapper>{children}</ContentWrapper>;
};
