import { useEffect, useState } from 'react';

import Head from 'next/head';

interface HeadComponentProps {
  /** Meta description of the current page */
  description?: string;
  /** OpenGraph sharing image */
  image: {
    alt?: string;
    url?: string;
    width?: number;
    height?: number;
  };
  /** HTML lang attribute */
  lang?: string;
  /** Status Code used by prerendering engines (e.g. Netlify) to catch client-side status and apply it as an HTTP status code */
  prerenderStatusCode?: number;
  /** Meta title of the current page */
  title?: string;
  /** OpenGraph document type */
  type?: string;
  /** OpenGraph document address */
  url: string;
}

export const HeadComponent: React.FC<HeadComponentProps> = ({
  description,
  image,
  lang = 'en',
  prerenderStatusCode,
  title,
  type = 'website',
  url,
}) => {
  const [pageUrl, setPageUrl] = useState<{ href: string } | null>({ href: '' });
  const [imageUrl, setImageUrl] = useState<{ href: string } | null>({
    href: '',
  });

  useEffect(() => {
    // config.application.baseUrl will be a fallback
    // if an URL scheme (protocol, hostname and optional port) is provided as url and/or image?.url || ''
    // it will not be overriden by the config one (useful to define external links)
    const BASE_URL = window.location.origin;
    const PAGE_URL = new URL(url, BASE_URL);
    const IMAGE_URL =
      image?.url || '' ? new URL(image?.url || '', BASE_URL) : null;
    setPageUrl(PAGE_URL);
    setImageUrl(IMAGE_URL);
  }, [image, url]);
  return (
    <Head>
      <meta charSet="utf-8" />
      <link rel="canonical" href={pageUrl?.href || ''} />
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={pageUrl?.href || ''} />
      {/* <meta property="og:site_name" content="{siteName}" /> */}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {imageUrl && <meta property="og:image" content={imageUrl.href} />}
      {image?.width && (
        <meta property="og:image:width" content={`${image.width}`} />
      )}
      {image?.height && (
        <meta property="og:image:height" content={`${image.height}`} />
      )}
      <meta name="twitter:card" content="summary" />
      {/* <meta name="twitter:site" content="@{TWITTER_USERNAME}" /> */}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {imageUrl && <meta property="twitter:image" content={imageUrl.href} />}
      {image?.alt && (
        <meta property="twitter:image:alt" content={image?.alt || ''} />
      )}
      {prerenderStatusCode && (
        <meta
          name="prerender-status-code"
          content={`${prerenderStatusCode}`}
        ></meta>
      )}
    </Head>
  );
};
