import {
  DocumentBlockFragment,
  HeaderBlockFragment,
  isDocumentBlockFragment,
  isHeaderBlockFragment,
} from '@hultafors/wibe/types';

import {
  BigTitle,
  StyledDocumentsContent,
  StyledDownloadFileBox,
  Title,
} from './documents-content.styled';

type content = HeaderBlockFragment | DocumentBlockFragment;

interface DocumentsContentProps {
  content: content[];
}

export const DocumentsContent: React.FC<DocumentsContentProps> = ({
  content,
}) => {
  const contentMapper = (item: content, index: number) => {
    const key = `DC-${index}`;
    if (isHeaderBlockFragment(item)) {
      return item.isBigHeader ? (
        <BigTitle key={key}>{item.header}</BigTitle>
      ) : (
        <Title key={key}>{item.header}</Title>
      );
    }
    if (isDocumentBlockFragment(item)) {
      return item.document ? (
        <StyledDownloadFileBox
          key={key}
          className="DownloadBox"
          url={item.document.url}
          label={item.title || item.document.filename}
          filetype={item.document.format}
        />
      ) : null;
    }
    return null;
  };

  return (
    <StyledDocumentsContent>
      {content?.map(contentMapper)}
    </StyledDocumentsContent>
  );
};
