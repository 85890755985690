import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/wibe/helpers';

export const BackLinkStyled = styled.div`
  color: ${colors.grey1};
  font-size: ${fontSizes.paragraph12};
  padding-left: ${spacing.s};
  padding-right: ${spacing.s};
  padding-top: ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-inline: ${spacing.m};
    padding-block-start: ${spacing.s};
  }

  &.ProductDetail {
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-inline: ${spacing.s};
      padding-block-start: ${spacing.s};
    }

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      padding-inline: ${spacing.m};
      padding-block-start: ${spacing.s};
    }
  }

  &.InformationPage {
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-inline: ${spacing.s};
      padding-block-start: ${spacing.s};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      padding-inline: ${spacing.m};
      padding-block-start: ${spacing.s};
    }
  }

  a {
    color: ${colors.wibeDarkGrey};
    font-size: ${fontSizes.paragraph12};
    display: flex;
    align-items: center;
    gap: ${spacing.xs};
  }
`;
