import { StyledH1 } from './h1.styled';

interface H1Props {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  center?: boolean;
  border?: boolean;
  bold?: boolean;
}

export const H1: React.FC<H1Props> = ({
  children,
  center,
  border,
  bold,
  className,
  style,
}) => (
  <StyledH1
    $center={center}
    $border={border}
    $bold={bold}
    className={className}
    style={style}
  >
    {children}
  </StyledH1>
);
