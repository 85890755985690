import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

interface ColumnSpan {
  columns?: number;
  breakpoint?: Breakpoint;
  columnGap?: number;
  start?: number;
  display?: CSSProperties['display'];
}

export type GridChildColumnSpanProp = ColumnSpan[] | number;

interface StyledGridChildProps {
  $align?: CSSProperties['justifySelf'];
  $alignSelf?: CSSProperties['alignSelf'];
  $padding?: CSSProperties['padding'];
  $rowStart?: CSSProperties['gridRowStart'];
  $rowSpan?: string;
  $columnSpan?: GridChildColumnSpanProp;
}

function columnSpanMapper({ breakpoint, display, start, columns }: ColumnSpan) {
  const gridColumn = `${start ? `${start} / ` : ''}${
    columns ? `span ${columns}` : -1
  }`;
  const styles = css`
    display: ${display || 'initial'};
    grid-column: ${gridColumn};
  `;
  if (!breakpoint) {
    return styles;
  }
  return css`
    @media screen and (min-width: ${breakpoints[breakpoint]}) {
      ${styles};
    }
  `;
}

function parseColumnSpan({ $columnSpan }: StyledGridChildProps) {
  if (!$columnSpan || typeof $columnSpan === 'number') {
    return css`
      grid-column: ${typeof $columnSpan === 'number' ? $columnSpan : '1 / -1'};
    `;
  }
  return $columnSpan.map(columnSpanMapper);
}

const placeSelf = ({
  $alignSelf,
  $align = 'stretch',
}: StyledGridChildProps) => {
  return `${$alignSelf} ${$align}`;
};
export const StyledGridChild = styled.div<StyledGridChildProps>`
  place-self: ${placeSelf};
  padding: ${({ $padding }) => $padding};
  grid-row: ${({ $rowStart = '' }) => $rowStart} /
    ${({ $rowSpan }) => $rowSpan && `span ${$rowSpan}`};

  ${parseColumnSpan}
`;
