import Image from 'next/image';

import { ImageFragment, Maybe } from '@hultafors/wibe/types';

import { ImageContainer } from './small-dynamic-image.styled';
interface SmallDynamicImageProps {
  image?: Maybe<ImageFragment>;
  sizes?: string;
}
export const SmallDynamicImage: React.FC<SmallDynamicImageProps> = ({
  image,
  sizes = '100vw',
}) => {
  if (!image?.responsiveImage) {
    return null;
  }
  const style: React.CSSProperties = {
    aspectRatio: image.responsiveImage.aspectRatio || '4 / 3',
  };
  return (
    <ImageContainer style={style}>
      <Image
        src={image.responsiveImage.src}
        alt={image.alt || ''}
        placeholder={image.responsiveImage.base64 ? 'blur' : 'empty'}
        blurDataURL={image.responsiveImage.base64 || ''}
        sizes={sizes}
        fill
      />
    </ImageContainer>
  );
};
