import { defaultPy, SectionPaddingProp, StyledSection } from './section.styled';

interface SectionProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  paddingBlock?: SectionPaddingProp;
  paddingBlockStart?: SectionPaddingProp;
  paddingBlockEnd?: SectionPaddingProp;
  hide?: boolean;
}

export const Section: React.FC<SectionProps> = ({
  paddingBlock = defaultPy,
  paddingBlockStart,
  paddingBlockEnd,
  children,
  style,
  className,
}) => {
  if (!children) {
    return null;
  }
  return (
    <StyledSection
      className={className}
      style={style}
      $paddingBlock={paddingBlock}
      $paddingBlockStart={paddingBlockStart}
      $paddingBlockEnd={paddingBlockEnd}
    >
      {children}
    </StyledSection>
  );
};
