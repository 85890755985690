import { Paragraph } from '../paragraph/paragraph';

import { StyledCertificationBox } from './certification-box.styled';

export const CertificationBox: React.FC<CertificationBoxProps> = (props) => {
  return (
    <StyledCertificationBox>
      {props.imageUrl && <img alt="Certification" src={`${props.imageUrl}`} />}
      <Paragraph styleType="paragraph12">{props.text}</Paragraph>
    </StyledCertificationBox>
  );
};

interface CertificationBoxProps {
  text: string;
  imageUrl?: string;
}
