import { Icon } from '../icon/icon';

import { StyledLoader } from './loader.styled';

interface LoaderProps {
  fullscreen?: boolean;
  small?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ fullscreen, small }) => {
  return (
    <StyledLoader $fullscreen={fullscreen} $small={small}>
      <Icon svg="loader" />
    </StyledLoader>
  );
};
