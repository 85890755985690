import styled from 'styled-components';

import { spacing } from '@hultafors/wibe/helpers';

export const StyledPicture = styled.picture`
  display: block;
  margin: ${spacing.m} auto;
  width: 100%;

  img {
    display: block;
  }
`;
