import { EmptyProduct } from '../empty-product/empty-product';

export const EmptyProducts: React.FC<EmptyProductsProps> = (props) => {
  return (
    <>
      {Array.from(Array(props.pageSize)).map((item, i) => {
        return <EmptyProduct key={`EP-${i}`} />;
      })}
    </>
  );
};

interface EmptyProductsProps {
  pageSize: number;
}
