import { useId } from 'react';

import { useRouter } from 'next/router';
import useSWR from 'swr';

import { breakpoints } from '@hultafors/shared/constants';
import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import {
  PRODUCT_LIST_ID,
  RELATED_PRODUCTS_PAGE_SIZE_SMALL,
} from '@hultafors/wibe/api';
import { WibeProduct } from '@hultafors/wibe/types';

import { EmptyProducts } from '../empty-products/empty-products';
import { Product } from '../product/product';

import {
  Header,
  ProductsGrid,
  RelatedProductsStyled,
} from './related-products.styled';

interface RelatedProductsProps {
  header?: string;

  pageSize?: number;

  productCatalogNodeId?: string;
  padded?: boolean;
}

export const RelatedProducts: React.FC<RelatedProductsProps> = ({
  pageSize = RELATED_PRODUCTS_PAGE_SIZE_SMALL,
  header = '',
  productCatalogNodeId = '',
  padded,
}) => {
  const { locale } = useRouter();
  const uid = useId();

  const params = new URLSearchParams({
    pageSize: `${pageSize}`,
    productCatalogNodeId,
  });

  if (locale && locale !== 'com') {
    params.set('locale', locale);
  }

  const { data, error, isLoading } = useSWR<
    ParsedProductsApiResponse<WibeProduct>
  >(
    productCatalogNodeId
      ? `/api/productslist/${PRODUCT_LIST_ID}?${params.toString()}`
      : null,
  );

  if (!productCatalogNodeId) {
    return null;
  }

  const sizes = [
    '(min-width: 1504px) 382px',
    `(min-width: ${breakpoints.desktop}) calc((100vw - 358px) / 3 )`,
    `(min-width: ${breakpoints.mobileMax}) calc((100vw - 244px) / 2 )`,
    'calc(100vw - 98px)',
  ].join(', ');
  function productsMapper(product: WibeProduct) {
    return (
      <Product
        key={`RelatedProducts-${uid}-${product.productId}`}
        product={product}
        sizes={sizes}
        noMargin
      />
    );
  }

  return (
    <RelatedProductsStyled $padded={padded}>
      {header && <Header>{header}</Header>}
      {isLoading ? (
        <EmptyProducts pageSize={pageSize} />
      ) : !error ? (
        <ProductsGrid>{data?.items.map(productsMapper)}</ProductsGrid>
      ) : null}
    </RelatedProductsStyled>
  );
};
