import { ImageFragment } from '@hultafors/wibe/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { HeroImage } from '../hero-image/hero-image';

import {
  Description,
  HeroTextWrapper,
  StyledCampaignHero,
  Title,
} from './campaign-hero.styled';

interface CampaignHeroProps {
  description?: string;
  image?: ImageFragment;
  imageMobile?: ImageFragment;
  imageTablet?: ImageFragment;
  title: string;
}

export const CampaignHero: React.FC<CampaignHeroProps> = ({
  title,
  description,
  image,
  imageMobile,
  imageTablet,
}) => {
  const descriptionLines = description?.trim().split('\n');

  return (
    <StyledCampaignHero title={title} $imageMobile={!!imageMobile}>
      <div className="hero-content">
        <HeroImage
          desktop={image}
          mobile={imageMobile}
          tablet={imageTablet}
          priority={true}
        />
        <Grid columnGap={0}>
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 8, start: 3 },
              { breakpoint: 'desktop', columns: 6, start: 4 },
            ]}
          >
            <HeroTextWrapper>
              <Title>
                <span>{title}</span>
              </Title>
              <Description>
                {descriptionLines?.map((line, key) => (
                  <span key={key}>{line}</span>
                ))}
              </Description>
            </HeroTextWrapper>
          </GridChild>
        </Grid>
      </div>
    </StyledCampaignHero>
  );
};
