import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

export const VideoStyled = styled.div`
  margin-inline: auto;
  padding-block: ${spacing.m};
  padding-inline: ${spacing.s};
  inline-size: 100%;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    max-inline-size: 1180px;
    padding-block: var(--header-height) ${spacing.l};
  }
`;
