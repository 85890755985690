import { ContactCardFragment, Maybe } from '@hultafors/wibe/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import {
  Container,
  Header,
  OuterGridChild,
  StyledImage,
  StyledMarkdown,
} from './contact-card.styled';

interface ContactCardProps {
  header?: Maybe<string>;
  body?: Maybe<string>;
  image?: ContactCardFragment['contactImage'];
}

export const ContactCard: React.FC<ContactCardProps> = ({
  header,
  body,
  image,
}) => {
  return (
    <Container>
      <Grid
        columns={[{ columns: 8 }, { breakpoint: 'desktop', columns: 12 }]}
        columnGap="s"
      >
        <OuterGridChild>
          <Grid
            columns={[{ columns: 8 }, { breakpoint: 'desktop', columns: 10 }]}
            columnGap="s"
          >
            <GridChild
              columnSpan={[
                { columns: 8 },
                { breakpoint: 'mobileMax', columns: 3, start: 1 },
                { breakpoint: 'desktop', columns: 3, start: 2 },
              ]}
            >
              <StyledImage src={image?.url} alt={image?.alt || ''} />
            </GridChild>
            <GridChild
              columnSpan={[
                { columns: 8 },
                { breakpoint: 'mobileMax', columns: 4, start: 5 },
                { breakpoint: 'desktop', columns: 4, start: 6 },
              ]}
            >
              <Header>{header}</Header>
              <StyledMarkdown>{body}</StyledMarkdown>
            </GridChild>
          </Grid>
        </OuterGridChild>
      </Grid>
    </Container>
  );
};
