import Image from 'next/image';

import { Breakpoint } from '@hultafors/shared/constants';

import { ImageFragment, Maybe } from '@hultafors/wibe/types';

import { DesktopImage, MobileImage, TabletImage } from './hero-image.styled';

export interface HeroImageProps {
  desktop?: Maybe<ImageFragment>;
  tablet?: Maybe<ImageFragment>;
  mobile?: Maybe<ImageFragment>;
  priority?: boolean;
  breakpointTablet?: Breakpoint;
  breakpointDesktop?: Breakpoint;
}

export const HeroImage: React.FC<HeroImageProps> = ({
  mobile,
  tablet,
  desktop,
  priority,
  breakpointTablet = 'tablet',
  breakpointDesktop = 'desktop',
}) => {
  const ImageElement: React.FC<{
    src: string;
    base64: string;
    hasPriority?: boolean;
    alt: string;
  }> = ({ src, base64, hasPriority = priority, alt }) => {
    return src && base64 ? (
      <Image
        src={src}
        alt={alt || 'image'}
        sizes="100vw"
        fill
        priority={hasPriority}
        placeholder={base64 ? 'blur' : 'empty'}
        blurDataURL={base64 || ''}
        quality={100}
      />
    ) : null;
  };
  return desktop?.responsiveImage ||
    tablet?.responsiveImage ||
    mobile?.responsiveImage ? (
    <>
      {mobile?.responsiveImage && (
        <MobileImage
          $breakpointMax={
            tablet?.responsiveImage ? breakpointTablet : breakpointDesktop
          }
        >
          <ImageElement
            src={mobile.responsiveImage.src}
            base64={mobile.responsiveImage.base64 || ''}
            alt={mobile.alt || ''}
          />
        </MobileImage>
      )}

      {tablet?.responsiveImage && (
        <TabletImage
          $breakpointMin={breakpointTablet}
          $breakpointMax={breakpointDesktop}
        >
          <ImageElement
            src={tablet.responsiveImage.src}
            base64={tablet.responsiveImage.base64 || ''}
            alt={tablet.alt || ''}
            hasPriority={false}
          />
        </TabletImage>
      )}
      {desktop?.responsiveImage && (
        <DesktopImage
          $breakpointMin={breakpointDesktop}
          $force={!mobile?.responsiveImage && !tablet?.responsiveImage}
        >
          <ImageElement
            src={desktop.responsiveImage.src}
            base64={desktop.responsiveImage.base64 || ''}
            alt={desktop.alt || ''}
            hasPriority={!mobile?.responsiveImage && !tablet?.responsiveImage}
          />
        </DesktopImage>
      )}
    </>
  ) : null;
};
