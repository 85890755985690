import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { GridChild } from '../grid-child/grid-child';
import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

export const Container = styled.div`
  margin: ${spacing.l} auto;
  padding: 0;

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 0 ${spacing.s};
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.xl} auto;
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${colors.grey1};
  max-width: 1440px;
`;

export const StyledImage = styled.img`
  height: auto;
  width: 100%;
`;

export const Header = styled(HTag).attrs({
  styleType: 'header3',
  type: 'h3',
})`
  margin-bottom: ${spacing.s};
  margin-top: ${spacing.m};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-start: 0;
  }
`;

export const StyledMarkdown = styled(Markdown).attrs({
  options: {
    forceBlock: true,
    overrides: {
      h2: {
        component: Paragraph,
        props: {
          style: { marginBottom: 0 },
          styleType: 'paragraph16Medium',
        },
      },
    },
  },
})`
  margin-top: 0;

  & > *:not(:last-child) {
    margin-block-end: ${spacing.xs};
  }

  a {
    color: ${colors.black};
    text-decoration: none;
    text-decoration: underline;
  }
`;

export const OuterGridChild = styled(GridChild).attrs({
  columnSpan: [
    { columns: 8 },
    { breakpoint: 'mobileMax', columns: 8, start: 1 },
    { breakpoint: 'desktop', columns: 10, start: 2 },
  ],
})`
  background-color: ${colors.grey1};
  padding: ${spacing.l} ${spacing.s};

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${spacing.l};
  }
`;
