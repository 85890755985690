import { StyledH3 } from './h3.styled';

interface H3Props {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  center?: boolean;
}

export const H3: React.FC<H3Props> = ({
  children,
  center,
  className,
  style,
}) => {
  return (
    <StyledH3 $center={center} className={className} style={style}>
      {children}
    </StyledH3>
  );
};
