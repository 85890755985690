import styled from 'styled-components';

import { colors, spacing } from '@hultafors/wibe/helpers';

export const StyledSlideInTop = styled.div<{ $open?: boolean }>`
  background-color: ${colors.white};
  left: 0;
  padding: ${spacing.s} ${spacing.m};
  position: absolute;
  top: 100%;
  transform: ${({ $open }) => ($open ? 'translateY(0)' : 'translateY(-100%)')};
  transition: 0.2s;
  width: 100%;
`;
