import dynamic from 'next/dynamic';

import { VideoStyled } from './video-player.styled';

const BynderVideo = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.BynderVideo),
);
interface VideoPlayerProps {
  videoId: string;
  style?: React.CSSProperties;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ style, videoId }) => {
  return (
    <VideoStyled>
      <BynderVideo videoId={videoId} style={style} />
    </VideoStyled>
  );
};
