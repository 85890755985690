import Image from 'next/image';

import {
  createDetailPageUrl,
  createProductImageSrc,
} from '@hultafors/wibe/helpers';
import { WibeProduct } from '@hultafors/wibe/types';

import { ImageWrapper, ProductImageStyled } from './product-image.styled';

interface ProductImageProps {
  product: WibeProduct;
  sizes?: string;
}

export const ProductImage: React.FC<ProductImageProps> = ({
  product,
  sizes,
}) => {
  const src = createProductImageSrc(product.image, '/assets/gfx/noImage.png');
  return (
    <ProductImageStyled>
      <ImageWrapper href={createDetailPageUrl(product)}>
        <Image src={src} alt={product.name || ''} fill sizes={sizes} />
      </ImageWrapper>
    </ProductImageStyled>
  );
};
