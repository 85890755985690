import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { Color, colors, spacing } from '@hultafors/wibe/helpers';

export const getColorByIndex = (index: number): Color => {
  switch (index % 3) {
    case 0:
      return 'wibeYellow';
    case 1:
      return 'white';
    case 2:
      return 'wibeDarkGrey';
    default:
      return 'wibeDarkGrey';
  }
};

export const ColorPlugContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: ${spacing.m};

  @media screen and (min-width: ${breakpoints.maxGridWidth}) {
    margin-block-start: 0;
  }

  @media screen and (min-width: ${breakpoints.maxGridWidth}) {
    flex-direction: row;
    justify-content: unset;
    padding-block-start: ${spacing.l};
  }
`;

export const ColorPlugWrap = styled.div`
  width: 100%;

  @media screen and (min-width: ${breakpoints.maxGridWidth}) {
    margin-inline-end: ${spacing.l};
    inline-size: unset;
  }
`;

export const ColorPlugInner = styled(Link)<{ $index: number }>`
  background-color: ${({ $index }) => {
    switch ($index % 3) {
      case 0:
        return colors.wibeDarkGrey;
      case 1:
        return colors.wibeDarkSteel;
      case 2:
        return colors.wibeLightSteel;
      default:
        return 'inherit';
    }
  }};
  display: flex;

  /* align-items: center; */

  /* align-content: center; */
  height: 120px;

  /* justify-content: space-between; */
  margin-bottom: 2px;
  max-width: 100%;
  padding: ${spacing.s} ${spacing.s} ${spacing.s} ${spacing.s};
  transition: 0.2s background-color;
  width: 100%;

  &:hover {
    background-color: ${({ $index }) => {
      switch ($index) {
        case 0:
          return colors.wibeDarkGreyHover;
        case 1:
          return colors.wibeDarkSteelHover;
        case 2:
          return colors.wibeLightSteelHover;
        default:
          return 'inherit';
      }
    }};
  }

  @media screen and (min-width: ${breakpoints.maxGridWidth}) {
    margin-block-end: ${spacing.m};
    inline-size: 287px;
  }
`;

export const Arrow = styled.div<{ $index: number }>`
  margin-left: auto;
  align-self: flex-end;

  path {
    fill: ${({ $index }) => colors[getColorByIndex($index)]};
  }
`;
