import styled from 'styled-components';

import { colors, fontSizes, lineHeights } from '@hultafors/wibe/helpers';

export const FindRetailersListStyled = styled.div``;

export const Label = styled.span`
  display: block;
`;

export const SubLabel = styled.span`
  display: block;
  color: ${colors.grey4};
  font-size: ${fontSizes.paragraph12};
  line-height: ${lineHeights.$paragraph12};
`;
