import { SecondaryMenuItemFragment } from '@hultafors/wibe/types';

import { HTag } from '../h-tag/h-tag';
import { Icon } from '../icon/icon';

import {
  Arrow,
  ColorPlugContainer,
  ColorPlugInner,
  ColorPlugWrap,
  getColorByIndex,
} from './menu-plugs.styled';

const checkExternalLink = (url?: string): boolean => {
  if (url?.startsWith('http') || url?.startsWith('HTTP')) {
    return true;
  } else {
    return false;
  }
};

interface MenuPlug {
  title?: string;
  url?: string;
  ctaLinkText?: string;
}

interface MenuPlugsProps {
  plugs: SecondaryMenuItemFragment[];
}

export const MenuPlugs: React.FC<MenuPlugsProps> = ({ plugs }) => {
  const plugsMapper = (
    { url = '', ...item }: SecondaryMenuItemFragment,
    index: number,
  ) => {
    if (!url) {
      return null;
    }
    const linkProps = checkExternalLink(url)
      ? { rel: 'noopener noreferrer', target: '_blank' }
      : {};
    const color = getColorByIndex(index);

    return (
      <ColorPlugWrap key={`menu-cp-${index}`}>
        <ColorPlugInner href={url || ''} $index={index} {...linkProps}>
          <HTag type="h4" styleType="header4" color={color}>
            {item.title}
          </HTag>
          <Arrow $index={index}>
            <Icon svg="xl-arrow-right" />
          </Arrow>
        </ColorPlugInner>
      </ColorPlugWrap>
    );
  };
  return <ColorPlugContainer>{plugs?.map(plugsMapper)}</ColorPlugContainer>;
};
