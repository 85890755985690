import { ApiSettings, ParttrapApiSettings } from '@hultafors/shared/types';

import { constructFilterUrlParameter } from './productFilter';

const getBase = ({ baseUrl, market }: ParttrapApiSettings) => {
  const mode = process.env['NEXT_PUBLIC_PT_MODE'] || 'production';
  return `${baseUrl}-${market}-api-${mode}.parttrap.com/contentapi/2.0`;
};

const getBaseEndpoint = (settings: ParttrapApiSettings) =>
  `${getBase(settings)}/${settings.siteId}/`;

export const getAuthEndpoint = (settings: ParttrapApiSettings) =>
  `${getBase(settings)}/auth/token`;

export const getProductsEndpoint = (
  settings: ParttrapApiSettings,
  productListId: number | string,
) => `${getBaseEndpoint(settings)}sections/productslist/${productListId}`;

export const getProductEndpoint = (
  settings: ParttrapApiSettings,
  productListId: number | string,
) => `${getBaseEndpoint(settings)}pages/productdetails/${productListId}`;

export const getRelatedProductsEndpoint = (
  settings: ParttrapApiSettings,
  productRelationListId: number | string,
) =>
  `${getBaseEndpoint(
    settings,
  )}sections/productrelationlist/${productRelationListId}`;

export const getSearchEndpoint = (
  settings: ParttrapApiSettings,
  searchSectionId: number | string,
) => `${getBaseEndpoint(settings)}sections/searchlist/${searchSectionId}`;

export const getClearCacheEndpoint = (settings: ParttrapApiSettings): string =>
  `${getBaseEndpoint(settings)}layout/clearcache?cacheType=0`;

/**
 * @desc Construct header for all Parttrap studio requests. Do not use OHC_API_USER from process.env as it changes between markets
 * @param object settings - All api settings
 * @return object - Request headers
 */
export const getHeaders = (settings: ApiSettings) => ({
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${settings.auth.token}`,
    'Content-Type': 'application/json',
    'x-api-key': settings?.pt?.user || '',
  },
  method: 'GET',
});

export const authHeaders = (settings: ParttrapApiSettings) => {
  // Lang specific token within a market uses api user and lang e.g "hellbergsafety-fr"
  const client_secret = settings?.lang
    ? `${settings.user}-${settings.lang}`
    : settings?.user;
  const params = {
    client_secret,
    grant_type: 'client_credentials',
    pt_site_id: settings?.siteId,
  };

  return {
    body: urlEncodedObject(params),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  };
};

const urlEncodedObject = (obj: any) =>
  Object.keys(obj)
    .map((k: any) => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
    .join('&');

/**
 * @desc Add parameters when constructing url to API
 * @param {string} endpoint http://api.com
 * @param {object} params {page:1,pageSize:12}
 * @param {array} filters array of filters
 * @return {string} - Final endpoint for API
 */
export const addQueryParams = (endpoint: string, params: any, filters = []) => {
  let isFirstParamSet = false;

  if (!params) {
    return endpoint;
  }

  Object.entries(params).map((entry: any) => {
    if (isFirstParamSet) {
      endpoint += entry[1] ? `&${entry[0]}=${entry[1]}` : '';
    } else {
      endpoint += entry[1] ? `?${entry[0]}=${entry[1]}` : '';
      isFirstParamSet = true;
    }
  });

  endpoint += constructFilterUrlParameter(filters);

  return endpoint;
};

/**
 * @desc Add parameters when constructing url to API
 * @param {string} url https://api.com?name=John
 * @return {array} - array of values from query params
 */
export const getParametersFromUrl = (url: string) => {
  if (!url) {
    return null;
  }
  return url
    .split('/')
    ?.pop()
    ?.match(/^\d+|\d+\b|\d+(?=\w)/g);
};

/**
 * @desc Get query parameter by key
 * @param {string} url http://api.com?name=John
 * @param {string} parameterName name
 * @return {string} - value "John"
 */
export const getParameterFromUrlByName = (
  url: string,
  parameterName: string,
) => {
  let result = '';
  if (url && parameterName) {
    const searchParams = new URLSearchParams(url.split('?')[1]);
    result = searchParams.get(parameterName) || '';
  }
  return result;
};

/**
 * @desc Internal helper function used by all PT endpoints to fetch data and set request status
 * @param {string} endpoint
 * @return {object}
 */
export const getData = async (settings: ApiSettings, endpoint: string) => {
  let result: any;

  const apiHeaders = getHeaders(settings);
  try {
    const response = await fetch(endpoint, apiHeaders);

    if (response?.status === 200) {
      result = {
        data: response,
      };
    } else {
      result = { error: true, status: response.status };
    }
    if (!result.error) {
      result = await result.data.json();
    }
  } catch (ex) {
    console.error(ex);
  }

  return result;
};
