import styled from 'styled-components';

import { spacing } from '@hultafors/wibe/helpers';

export const PagerStyled = styled.div`
  margin-top: ${spacing.m};
`;

export const PagerInner = styled.div`
  margin: 0 auto;
  max-width: 250px;
`;

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  button {
    justify-content: center;
    text-align: center;
  }
`;
