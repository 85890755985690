import { useGlobal } from '@hultafors/wibe/hooks';

import { Icon } from '../icon/icon';

import { Index, SliderWrapper, StepButton } from './modal-bottom-slider.styled';

interface ModalBottomSliderProps {
  onClick(direction: string): void;
  index?: number;
  total?: number;
}

export const ModalBottomSlider: React.FC<ModalBottomSliderProps> = ({
  onClick,
  index,
  total,
}) => {
  const { global } = useGlobal();
  return (
    <SliderWrapper>
      <StepButton
        onClick={() => onClick('prev')}
        aria-label={global?.previous || ''}
      >
        <Icon svg="arrow-left-24" height={24} />
      </StepButton>
      <Index>
        {index}
        {!!total && ` / ${total}`}
      </Index>
      <StepButton
        onClick={() => onClick('next')}
        aria-label={global?.next || ''}
      >
        <Icon svg="arrow-left-24" height={24} rotation={180} />
      </StepButton>
    </SliderWrapper>
  );
};
