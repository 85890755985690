import { Fragment, useId, useMemo } from 'react';

import clsx from 'clsx';
import Link from 'next/link';

import { DatoProps } from '@hultafors/shared/types/dato-props';

import { spacing } from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';
import { AddressBlockFragment } from '@hultafors/wibe/types';

import { HTag } from '../h-tag/h-tag';
import { Icon } from '../icon/icon';

import styles from './address-block.module.scss';

interface AddressBlockProps extends DatoProps<AddressBlockFragment> {
  inline?: boolean;
}

interface AddressBlockSectionProps {
  title?: string | null;
  children?: React.ReactNode;
}
const AddressBlockSection: React.FC<AddressBlockSectionProps> = ({
  title,
  children,
}) => {
  return (
    <div className={styles['section']}>
      {title && (
        <p className={clsx('text__p16-medium', styles['title'])}>{title}</p>
      )}
      <div className={clsx('text__p16', styles['section-inner'])}>
        {children}
      </div>
    </div>
  );
};

interface AddressBlockContainerProps extends Pick<AddressBlockProps, 'inline'> {
  children?: React.ReactNode;
}

const AddressBlockContainer: React.FC<AddressBlockContainerProps> = ({
  inline,
  children,
}) => {
  if (inline) {
    return children;
  }
  return <div className={clsx('grid', styles['container'])}>{children}</div>;
};

export const AddressBlock: React.FC<AddressBlockProps> = ({
  address,
  className,
  inline,
}) => {
  const uid = useId();
  const {
    global: {
      directionsLabel,
      addressLabel,
      phoneNumberLabel,
      emailLabel,
      visitingAddressLabel,
    },
  } = useGlobal();

  const { mainAddress, visitingAddress } = useMemo(() => {
    return {
      mainAddress: [
        address?.companyName,
        address?.streetAddress,
        address?.city,
        address?.country,
      ].filter(Boolean),
      visitingAddress: [
        address?.visitingAddress,
        address?.visitingCity,
        address?.visitingCountry,
      ].filter(Boolean),
    };
  }, [address]);

  if (!address) {
    return null;
  }
  const { addressHeader } = address;

  function lineMapper(
    value: string | null | undefined,
    index: number,
    all: (string | null | undefined)[],
  ) {
    if (!value) {
      return null;
    }
    return (
      <Fragment key={`AddressBlock-${uid}-${value}-${index}`}>
        {value}
        {index <= all.length - 1 && <br />}
      </Fragment>
    );
  }

  return (
    <AddressBlockContainer inline={inline}>
      <div className={clsx(styles['address-block'], className)}>
        {addressHeader && (
          <HTag
            type="h2"
            styleType="header3"
            style={{ marginBottom: spacing.m }}
          >
            {addressHeader}
          </HTag>
        )}
        {!!mainAddress?.length && (
          <AddressBlockSection title={addressLabel}>
            {mainAddress.map(lineMapper)}
            {address?.addressDirectionsLink && (
              <Link
                className={clsx(
                  'text__p16',
                  styles['link'],
                  styles['directions'],
                )}
                href={address.addressDirectionsLink}
              >
                <Icon svg="place-tag" />
                {directionsLabel || address.addressDirectionsLink}
              </Link>
            )}
          </AddressBlockSection>
        )}
        {address?.phoneNumber && (
          <AddressBlockSection title={phoneNumberLabel}>
            <a
              className={clsx('text__p16', styles['link'])}
              href={`tel:${address.phoneNumber.replace(/ /g, '')}`}
            >
              <Icon svg="phone" />
              {address.phoneNumber}
            </a>
          </AddressBlockSection>
        )}
        {address?.email && (
          <AddressBlockSection title={emailLabel}>
            <a
              className={clsx('text__p16', styles['link'])}
              href={`mailto:${address.email}`}
            >
              <Icon svg="mail" />
              {address.email}
            </a>
          </AddressBlockSection>
        )}

        {!!visitingAddress?.length && (
          <AddressBlockSection title={visitingAddressLabel}>
            {visitingAddress.map(lineMapper)}
          </AddressBlockSection>
        )}
        {address?.description && (
          <p className={clsx('text__p14', styles['disclaimer'])}>
            {address?.description}
          </p>
        )}
      </div>
    </AddressBlockContainer>
  );
};
