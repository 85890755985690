import Slider from 'react-slick';
import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { clampedContainer, colors, spacing } from '@hultafors/wibe/helpers';

import { Paragraph } from '../paragraph/paragraph';

interface ImgWrapperProps {
  $aspectRatio?: CSSProperties['aspectRatio'];
}

export const ImgWrapper = styled.div<ImgWrapperProps>`
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
  background-color: ${colors.grey1};

  ${({ $aspectRatio }) =>
    $aspectRatio &&
    css`
      aspect-ratio: ${$aspectRatio};
    `}
  img {
    object-fit: cover;
  }
`;

export const SliderStyled = styled(Slider)`
  .slick-prev,
  .slick-next {
    background: url('/assets/gfx/image-slider-arrow-circle.svg') center center
      no-repeat;
    background-position: center;
    inset-block-end: 0;
    block-size: ${spacing.l};
    position: absolute;
    inline-size: ${spacing.l};
    z-index: 1;
  }

  .slick-prev {
    inset-inline-end: 50px;
    transform: rotate(180deg);
  }

  .slick-next {
    inset-inline-end: 0;
  }

  .slick-prev :hover,
  .slick-next :hover {
    background: url('/assets/gfx/image-slider-arrow-circle-hover.svg') center
      center no-repeat;
  }

  .ImgWrapper {
    background-color: #fff;
    display: flex !important;
    justify-content: center;
    aspect-ratio: 16 / 9;

    img {
      block-size: auto;
      max-block-size: 800px;
      max-inline-size: ${breakpoints.desktop};
      max-inline-size: 100%;
      object-fit: contain;
      inline-size: 100%;
    }
  }
`;

export const Counter = styled(Paragraph).attrs({ styleType: 'paragraph16' })`
  bottom: 0;
  display: flex;
  justify-content: right;
  position: absolute;
  right: 116px;
  margin: 0;
  height: 50px;
  line-height: 50px;
`;

export const ImageSliderStyled = styled.section`
  ${clampedContainer('desktopMedium')};
  height: 100%;
  margin: ${spacing.l} auto;
  position: relative;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.xl} auto;
  }

  .slick-slider {
    box-sizing: border-box;
    display: block;
    block-size: 100%;
    padding-block-end: 50px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    user-select: none;
  }

  .slick-prev,
  .slick-next {
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  .slick-list {
    display: block;
    block-size: 100%;
    margin: 0;
    overflow: hidden;
    position: relative;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-track {
    display: block;
    inset-inline-start: 0;
    position: relative;
    inset-block-start: 0;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    block-size: 100%;
    transform: translate3d(0, 0, 0);
  }

  .slick-track::before,
  .slick-track::after {
    content: '';
    display: table;
  }

  .slick-track::after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: inline-start;
    block-size: 100%;
    min-block-size: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: inline-end;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    border: thin solid transparent;
    display: block;
    block-size: auto;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;
