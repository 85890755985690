import Link from 'next/link';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import styled, { css } from 'styled-components';

import { colors } from '@hultafors/wibe/helpers';

interface MenuProps {
  $menu?: boolean;
}

interface HeightProps {
  $height?: number;
}

const AccordionLinkShared = css<MenuProps>`
  display: flex;
  width: 100%;
  padding: ${({ $menu }) => ($menu ? '8px' : '1.5rem')} 0;
  min-height: 48px;
  cursor: pointer;
  position: relative;
  margin: 0;
`;

export const AccordionLinkStyled = styled(Link)<MenuProps>`
  ${AccordionLinkShared};
`;

export const AccordionItemStyled = styled(AccordionItem)<MenuProps>`
  position: relative;
  width: 100%;
  border-block-end: ${({ $menu }) =>
    $menu ? '0' : `thin solid ${colors.grey2}`};
`;

export const AccordionItemHeadingStyled = styled(AccordionItemHeading)`
  width: 100%;
`;

export const ButtonInner = styled.div<MenuProps>`
  position: relative;
  ${AccordionLinkShared};
  min-height: 32px;
`;

export const AccordionItemButtonStyled = styled(AccordionItemButton)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;

  ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 20px;
  }
  ${AccordionItemStyled} ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 40px;
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    inset-inline-end: 0;
    inset-block-start: calc(50% - 8px);
    transition: all 0.3s ease-out;
    transform-origin: 8px 8px;
    inline-size: 16px;
    block-size: 16px;
    background-image: url('/assets/gfx/open_2_24.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  &[aria-expanded='true'] {
    ::after {
      transform: rotate(45deg);
    }
  }
`;

export const AccordionItemPanelStyled = styled(AccordionItemPanel)<HeightProps>`
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;

  &[hidden] {
    display: block;
  }
`;

export const AccordionItemContent = styled.div`
  padding-block: 8px;
`;
