import Link from 'next/link';

import XlArrowLeft from '../../svg/xl-arrow-left.svg';

import { BackLinkStyled } from './back-link.styled';

interface BackLinkProps {
  title: string;
  url: string;
}

export const BackLink: React.FC<BackLinkProps> = ({ title, url }) => {
  return url ? (
    <BackLinkStyled>
      <Link href={url}>
        <XlArrowLeft width={40} height={40} />
        {title}
      </Link>
    </BackLinkStyled>
  ) : null;
};
