import { createGlobalStyle, css } from 'styled-components';
import '@hultafors/shared/style/main.scss';
import './global.scss';

const globalStyle = css`
  *,
  ::before,
  ::after {
    box-sizing: border-box; /* 1 */
    background-repeat: no-repeat; /* 2 */
  }
  html {
    font-size: 16px;
    min-height: 100vh;
    /* scroll-behavior: smooth; */ // Seem to be known issue in next.js and preventing scroll to top
  }

  body {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    margin: 0;
    padding: 64px 0 0;
  }

  #root {
    min-height: 100vh;
  }

  img {
    max-width: 100%;
  }

  .no-js img.lazyload {
    display: none;
  }

  .map {
    position: relative;
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h6,
  p {
    margin: 0;
    padding: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  button {
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }
  .loadingAnimation {
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${globalStyle};
`;
