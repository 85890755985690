import { useState } from 'react';

import { Button } from '../button/button';
import { SubscriptionDialog } from '../subscription-dialog/subscription-dialog';

import { SubscribeText } from './subscribe-area.styled';

export const SubscribeArea: React.FC<SubscribeAreaProps> = ({
  subscribeInfoText,
  subscribeButtonText,
  userEmailFormUrl,
  userEmailFormImage,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  return (
    <>
      <SubscribeText>{subscribeInfoText}</SubscribeText>
      <Button onClick={toggleDialog} variant="primary">
        {subscribeButtonText}
      </Button>

      {isDialogOpen && (
        <SubscriptionDialog
          formUrl={userEmailFormUrl}
          image={userEmailFormImage}
          onHandleClose={toggleDialog}
        />
      )}
    </>
  );
};

interface SubscribeAreaProps {
  subscribeInfoText?: string;
  subscribeButtonText?: string;
  userEmailFormUrl?: string;
  userEmailFormImage?: any;
}
