import { breakpoints } from '@hultafors/shared/constants';

import { Maybe } from '@hultafors/wibe/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { GridChildColumnSpanProp } from '../grid-child/grid-child.styled';
import { HTag } from '../h-tag/h-tag';
import { LinkMarkdownWrapper } from '../link-markdown-wrapper/link-markdown-wrapper';
import { Paragraph } from '../paragraph/paragraph';

import { StyledGreyInfoBox, StyledMarkdown } from './grey-info-box.styled';

interface GreyInfoBoxProps {
  title?: Maybe<string>;
  body?: Maybe<string>;
  big?: boolean;
}

export const GreyInfoBox: React.FC<GreyInfoBoxProps> = ({
  big,
  title,
  body,
}) => {
  if (!title && !body) {
    return null;
  }
  let columnSpan: GridChildColumnSpanProp = [
    { columns: 4 },
    { breakpoint: 'mobileMax', columns: 8, start: 3 },
    { breakpoint: 'desktop', columns: 6, start: 4 },
  ];
  if (big) {
    columnSpan = [{ columns: 4 }, { breakpoint: 'mobileMax', columns: 5 }];
  }
  return (
    <StyledGreyInfoBox $big={big}>
      <Grid maxWidth={breakpoints.maxPageWidth}>
        <GridChild columnSpan={columnSpan}>
          <div className="content">
            {title && (
              <HTag type="h2" styleType="header4">
                {title}
              </HTag>
            )}

            {body && (
              <StyledMarkdown
                options={{
                  overrides: {
                    a: {
                      component: LinkMarkdownWrapper,
                      props: {
                        style: {
                          display: 'block',
                        },
                      },
                    },
                    p: {
                      component: Paragraph,
                      props: {
                        styleType: 'paragraph16',
                      },
                    },
                  },
                }}
              >
                {body}
              </StyledMarkdown>
            )}
          </div>
        </GridChild>
      </Grid>
    </StyledGreyInfoBox>
  );
};
