import styled from 'styled-components';

import { fontSizes, spacing } from '@hultafors/wibe/helpers';
export const StyledRetailersAdditionalSelections = styled.div`
  margin-top: ${spacing.m};

  .RadiusSelectContainer {
    label {
      font-size: ${fontSizes.paragraph12};
    }
  }

  .InternationalSearchToggle {
    margin-block-start: ${spacing.m};
  }
`;
