import styled, { CSSProperties } from 'styled-components';

interface IFrameWrapperProps {
  $width?: CSSProperties['width'];
  $height?: CSSProperties['height'];
}

export const IFrameWrapper = styled.div<IFrameWrapperProps>`
  height: ${({ $height = '100%' }) => $height};
  overflow: auto;
  width: ${({ $width = '100%' }) => $width};

  iframe {
    border: 0;
    block-size: 100%;
    inline-size: 100%;
  }
`;
