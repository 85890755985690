import styled from 'styled-components';

import { colors } from '@hultafors/wibe/helpers';

import { Icon } from '../icon/icon';

interface StyledIndicatorProps {
  $active?: boolean;
}

export const StyledIndicator = styled(Icon).attrs({
  svg: 'favorite',
})<StyledIndicatorProps>`
  path {
    fill: ${({ $active }) => ($active ? colors.wibeYellow : 'transparent')};
    transition: all 0.2s;

    &:hover {
      fill: ${({ $active }) => ($active ? colors.wibeYellow : colors.grey1)};
    }
  }
`;
