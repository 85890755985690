import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

import { Paragraph } from '../paragraph/paragraph';

export const Container = styled.div`
  margin: ${spacing.l} 0;
  position: relative; /* anchor for .background */

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.xl} 0;
  }

  .content {
    padding: ${spacing.l} 0;

    .image {
      display: block;
      margin: auto;
      max-block-size: 65vh;
      min-inline-size: inherit;
    }
  }
`;

export const BackgroundContainer = styled.div`
  display: block;
  position: absolute;
  inset: 0;
  z-index: -1;

  > div {
    position: absolute;
    inset: 0;
  }

  img {
    object-fit: cover;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: calc(72vh - 64px);
  max-width: 100%;
  max-height: 65vh;
  aspect-ratio: 1;

  img {
    object-fit: contain;
    max-inline-size: 100%;
    max-block-size: 100%;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  margin-top: ${spacing.m};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: 1.25rem;
    line-height: 2.25rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.m};
`;
