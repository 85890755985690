import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

import { HTag } from '../h-tag/h-tag';

export const Container = styled.div`
  margin: ${spacing.l} ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.xl} 0;
  }
`;

export const Header = styled(HTag).attrs({ type: 'h2' })`
  padding-bottom: ${spacing.s};
`;

interface StyledListProps {
  $isBig?: boolean;
}

export const StyledList = styled.div<StyledListProps>`
  ul {
    list-style: none;
    margin: 0;
    padding: 0 ${spacing.s};

    li {
      color: ${({ $isBig }) =>
        $isBig ? colors.wibeDarkGrey : colors.systemBlack};
      font-size: ${({ $isBig }) =>
        $isBig ? fontSizes.header4 : fontSizes.paragraph16};
      font-weight: ${({ $isBig }) =>
        $isBig ? fontWeights.light : fontWeights.medium};
      line-height: ${({ $isBig }) =>
        $isBig ? lineHeights.header4 : lineHeights.paragraph16};
      padding-block-end: ${({ $isBig }) => ($isBig ? spacing.s : spacing.xs)};
    }

    li::before {
      color: ${colors.systemBlack};
      content: '\2022';
      display: inline-block;
      margin-inline-start: -1em;
      inline-size: 1em;
    }
  }

  ol {
    margin: 0;
    padding: 0 ${spacing.s};

    li {
      padding-block-end: ${spacing.s};
    }

    li::before {
      color: ${colors.systemBlack};
      display: inline-block;
      margin-inline-start: -1em;
      inline-size: 1em;
    }
  }

  ul li:last-child {
    padding-block-end: ${({ $isBig }) => ($isBig ? spacing.s : spacing.m)};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;
