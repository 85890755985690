import { ProductDetailsStyled } from './product-details.styled';
export { ProductDetailsInner } from './product-details.styled';

interface ProductDetailsProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
export const ProductDetails: React.FC<ProductDetailsProps> = ({
  children,
  className,
  style,
}) => {
  return (
    <ProductDetailsStyled style={style} className={className}>
      {children}
    </ProductDetailsStyled>
  );
};
