import styled, { css, CSSProperties } from 'styled-components';

import {
  Color,
  colors,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
} from '@hultafors/wibe/helpers';

export type ParagraphStyleType =
  | 'paragraph16'
  | 'paragraph16Medium'
  | 'paragraph14'
  | 'paragraph14Medium'
  | 'paragraph12'
  | 'paragraph12Medium'
  | 'paragraph10'
  | 'label'
  | 'header4'
  | 'heroLabel';

interface StyledParagraphProps {
  $color?: Color;
  $styleType?: ParagraphStyleType;
  $margin?: CSSProperties['margin'];
}

export const paragraph16Style = css`
  font-size: ${fontSizes.paragraph16};
  font-weight: ${fontWeights.regular};
  line-height: ${lineHeights.paragraph16};
`;

export const paragraph16MediumStyle = css`
  font-size: ${fontSizes.paragraph16};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeights.paragraph16};
`;

export const paragraph14Style = css`
  font-size: ${fontSizes.paragraph14};
  font-weight: ${fontWeights.regular};
  line-height: ${lineHeights.paragraph14};
`;

export const paragraph14MediumStyle = css`
  font-size: ${fontSizes.paragraph14};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeights.paragraph14};
`;

export const paragraph12Style = css`
  font-size: ${fontSizes.paragraph12};
  font-weight: ${fontWeights.regular};
  line-height: ${lineHeights.$paragraph12};
`;

export const paragraph12MediumStyle = css`
  font-size: ${fontSizes.paragraph12};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeights.$paragraph12};
`;

export const paragraph10Style = css`
  font-size: ${fontSizes.paragraph10};
  font-weight: ${fontWeights.regular};
  line-height: ${lineHeights.paragraph10};
`;

export const labelStyle = css`
  font-size: ${fontSizes.paragraph16};
  font-weight: ${fontWeights.medium};
  letter-spacing: ${letterSpacings.heroLabel};
  line-height: ${lineHeights.paragraph16};
  text-transform: uppercase;
`;

export const heroLabelStyle = css`
  font-size: ${fontSizes.paragraph16};
  font-weight: ${fontWeights.extraBold};
  letter-spacing: ${letterSpacings.heroLabel};
  line-height: ${lineHeights.paragraph16};
  text-transform: uppercase;
`;

export const header4Style = css`
  font-size: ${fontSizes.header4};
  font-weight: ${fontWeights.light};
  letter-spacing: ${letterSpacings.header4};
  line-height: ${lineHeights.header4};
`;

export const fontStyle = (styleType?: ParagraphStyleType) => {
  switch (styleType) {
    case 'paragraph16':
      return paragraph16Style;
    case 'paragraph16Medium':
      return paragraph16MediumStyle;
    case 'paragraph14':
      return paragraph14Style;
    case 'paragraph14Medium':
      return paragraph14MediumStyle;
    case 'paragraph12':
      return paragraph12Style;
    case 'paragraph12Medium':
      return paragraph12MediumStyle;
    case 'paragraph10':
      return paragraph10Style;
    case 'label':
      return labelStyle;
    case 'heroLabel':
      return heroLabelStyle;
    case 'header4':
      return header4Style;
    default:
      return paragraph14Style;
  }
};

export const StyledParagraph = styled.p<StyledParagraphProps>`
  color: ${({ $color = 'systemBlack' }) => colors[$color]};
  margin: ${({ $margin = 'inherit' }) => $margin};
  ${({ $styleType }) => fontStyle($styleType)}
`;
