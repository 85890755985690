import { ProductDetailStyled } from './product-detail-container.styled';

interface ProductDetailContainerProps {
  children?: React.ReactNode;
}

export const ProductDetailContainer: React.FC<ProductDetailContainerProps> = ({
  children,
}) => {
  return <ProductDetailStyled>{children}</ProductDetailStyled>;
};
