import clsx from 'clsx';

import { DatoProps } from '@hultafors/shared/types/dato-props';

import { DividerBlockFragment } from '@hultafors/wibe/types';

import styles from './divider.module.scss';

type DividerProps = DatoProps<DividerBlockFragment>;

export const Divider: React.FC<DividerProps> = ({ extraMargin }) => {
  return (
    <hr
      className={clsx(styles['divider'], extraMargin && styles['extra-margin'])}
    />
  );
};
