import { CSSProperties } from 'styled-components';

import { GridChildColumnSpanProp, StyledGridChild } from './grid-child.styled';

interface GridChildProps {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  align?: CSSProperties['justifySelf'];
  alignSelf?: CSSProperties['alignSelf'];
  padding?: CSSProperties['padding'];
  rowStart?: CSSProperties['gridRowStart'];
  rowSpan?: string;
  columnSpan?: GridChildColumnSpanProp;
}

export const GridChild: React.FC<GridChildProps> = ({
  className,
  children,
  align = 'stretch',
  alignSelf,
  padding,
  rowSpan,
  rowStart,
  columnSpan,
  style,
}) => (
  <StyledGridChild
    className={className}
    $align={align}
    $alignSelf={alignSelf}
    $padding={padding}
    $rowSpan={rowSpan}
    $rowStart={rowStart}
    $columnSpan={columnSpan}
    style={style}
  >
    {children}
  </StyledGridChild>
);
