import { Maybe } from '@hultafors/wibe/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import { Container, StyledMarkdown } from './wide-text-block.styled';

interface WideTextBlockProps {
  body?: Maybe<string>;
  className?: string;
}

export const WideTextBlock: React.FC<WideTextBlockProps> = ({
  className,
  body,
}) => {
  return (
    <Container className={className}>
      <Grid
        columns={[{ columns: 8 }, { breakpoint: 'desktop', columns: 12 }]}
        columnGap="s"
      >
        <GridChild
          columnSpan={[
            { columns: 8 },
            { breakpoint: 'mobileMax', columns: 8, start: 1 },
            { breakpoint: 'desktop', columns: 10, start: 2 },
          ]}
        >
          <StyledMarkdown>{body}</StyledMarkdown>
        </GridChild>
      </Grid>
    </Container>
  );
};
