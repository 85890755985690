import Link from 'next/link';
import styled from 'styled-components';

import {
  colors,
  fontSizes,
  letterSpacings,
  lineHeights,
} from '@hultafors/wibe/helpers';

export type RouterLinkVariant = 'black' | 'white';

interface StyledLinkProps {
  $variant?: RouterLinkVariant;
  $small?: boolean;
  $clean?: boolean;
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  align-items: center;
  color: ${({ $variant }) =>
    $variant === 'black' ? colors.systemBlack : colors.white};
  display: inline-flex;
  font-size: ${({ $small }) =>
    $small ? fontSizes.paragraph12 : fontSizes.paragraph16};
  height: 100%;
  letter-spacing: ${({ $small }) =>
    $small ? letterSpacings.paragraph12 : letterSpacings.paragraph16};
  line-height: ${({ $small }) =>
    $small ? lineHeights.$paragraph12 : lineHeights.paragraph16};
  text-decoration: ${({ $clean }) => ($clean ? 'none' : 'underline')};
  transition: 0.2s;
`;
