import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { clampedContainer, spacing } from '@hultafors/wibe/helpers';

import { HTag } from '../h-tag/h-tag';

interface RelatedProductsStyledProps {
  $padded?: boolean;
}

function padding({ $padded }: RelatedProductsStyledProps) {
  if (!$padded) {
    return;
  }
  return css`
    padding-block-end: ${spacing.xl};
  `;
}

export const RelatedProductsStyled = styled.section<RelatedProductsStyledProps>`
  ${clampedContainer('desktopMediumLarge')}
  ${padding}
`;

export const Header = styled(HTag).attrs({
  styleType: [
    { styleType: 'header3' },
    { breakpoint: 'mobileMax', styleType: 'header2' },
  ],
  type: 'h3',
})`
  margin-block: ${spacing.l};

  &:empty {
    display: none;
  }
`;

export const ProductsGrid = styled.div`
  display: grid;
  gap: ${spacing.s};
  grid-template-columns: auto;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
