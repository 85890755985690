import styled from 'styled-components';

import { buttonReset } from '@hultafors/shared/helpers';

import { colors, spacing } from '@hultafors/wibe/helpers';

interface MarginProps {
  $margin?: boolean;
}

export const MenuStyled = styled.div``;

export const Flex = styled.div<{ $pt?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: ${({ $pt }) => $pt && spacing.m};
  position: relative;
`;

export const MenuItem = styled.div<MarginProps>`
  align-items: center;
  color: ${colors.wibeDarkGrey};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: ${({ $margin }) => !!$margin && `${spacing.xs} 0`};
`;

interface MenuButtonProps {
  $borderLess?: boolean;
  $stretch?: boolean;
}

export const MenuButton = styled.button<MenuButtonProps>`
  ${buttonReset};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ $borderLess }) =>
    $borderLess ? 0 : `1px solid ${colors.grey3}`};
  padding-bottom: ${({ $borderLess }) => ($borderLess ? 0 : spacing.s)};
  width: ${({ $stretch }) => ($stretch ? '100%' : 'auto')};
  cursor: pointer;
`;

export const MenuLevel = styled.div`
  background: ${colors.white};
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s ease;
  width: 100%;
  z-index: 100;

  &.Hide-Right {
    transform: translate3d(100%, 0, 0);
  }

  &.Hide-Left {
    transform: translate3d(-100%, 0, 0);
  }
`;

export const SiteLogoWrapper = styled.div`
  inline-size: 98.48px;
  block-size: 40px;
  position: relative;
`;
