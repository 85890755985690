import { css } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { spacing } from '../tokens';

export const marginContainer = (breakpoint: Breakpoint = 'mobileMax') => {
  return css`
    margin-inline: auto;
    width: calc(100vw - 2 * ${spacing.s});
    @media screen and (min-width: ${breakpoints[breakpoint]}) {
      width: calc(100vw - 2 * ${spacing.side});
    }
  `;
};
