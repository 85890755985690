import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

import { Dialog } from '../dialog/dialog';
import { IFrameContainer } from '../iframe-container/iframe-container';

export const BackDrop = styled.div`
  background-color: black;
  inset: 0;
  opacity: 0.3;
  position: fixed;
  z-index: 2;
`;

export const StyledDialog = styled(Dialog)`
  height: 80%;
  width: 90%;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    block-size: 65%;
    max-block-size: 600px;
    max-inline-size: 1250px;
    inline-size: 80%;
  }
`;

export const DialogContent = styled.div`
  align-items: center;
  inset: 0;
  display: flex;
  height: 80%;
  justify-content: space-between;
  margin: auto;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 100%;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0 ${spacing.m};
  }
`;

export const ImageWrapper = styled.div`
  margin-right: ${spacing.m};
  max-width: 400px;

  @media screen and (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const StyledIFrameContainer = styled(IFrameContainer)`
  min-width: 300px;
  overflow: hidden;
`;
