import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

export const StyledTitleAndTextBlock = styled.section`
  padding: ${spacing.xl} ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: ${spacing.xxl} ${spacing.side};
  }
`;
