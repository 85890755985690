import { CSSProperties, useRef } from 'react';

import { useClickOutside } from '@react-hookz/web';
import ReactModal from 'react-modal';
import { useKey } from 'react-use';

import { EscapeKey } from '@hultafors/shared/helpers';
import { useLockBodyScroll } from '@hultafors/shared/hooks';

import { useGlobal } from '@hultafors/wibe/hooks';

import { Icon } from '../icon/icon';

import {
  ChildrenWrapper,
  CloseButton,
  InnerModal,
  ModalStyled,
  OverlayStyled,
} from './modal.styled';

interface ModalProps {
  children?: React.ReactNode;
  isOpen: boolean;
  setModalVisible(val: boolean): void;
  wrapperStyle?: CSSProperties;
}
export const Modal: React.FC<ModalProps> = ({
  isOpen,
  setModalVisible,
  children,
  wrapperStyle,
}) => {
  const { global } = useGlobal();
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, close);
  useLockBodyScroll(isOpen);
  useKey(EscapeKey, close);

  function close() {
    setModalVisible(false);
  }

  // Accessibility https://reactcommunity.org/react-modal/accessibility/
  ReactModal.setAppElement('#__next');

  // Remove inline styles
  ReactModal.defaultStyles = {};

  return (
    <ReactModal
      contentElement={(contentProps, children) => (
        <ModalStyled {...contentProps} ref={ref}>
          {children}
        </ModalStyled>
      )}
      overlayElement={(overlayProps, children) => (
        <OverlayStyled {...overlayProps}>{children}</OverlayStyled>
      )}
      isOpen={isOpen}
    >
      <CloseButton
        onClick={() => setModalVisible(false)}
        aria-label={global?.close || ''}
      >
        <Icon svg="close-24" />
      </CloseButton>
      <InnerModal>
        <ChildrenWrapper style={wrapperStyle}>{children}</ChildrenWrapper>
      </InnerModal>
    </ReactModal>
  );
};
