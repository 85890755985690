import {
  MinMax,
  ProductDetailsValue,
  ProductPriceRange,
} from '@hultafors/shared/types';

import { renderPrice, routes, spacing } from '@hultafors/wibe/helpers';
import { WibeProduct } from '@hultafors/wibe/types';

import { Button } from '../button/button';
import { Favorite } from '../favorite/favorite';
import { Flex } from '../flex/flex';
import { Icon } from '../icon/icon';
import { ProductFeatures } from '../product-features/product-features';
import { TextButton } from '../text-button/text-button';

import {
  FeatureItem,
  FeatureList,
  InfoFirstBlock,
  ModelsButton,
  ModelsButtonWrapper,
  Name,
  Price,
  PriceWrapper,
  RrpLabel,
  StyledProductDetailData,
  VideoPlayerLink,
} from './product-detail-data.styled';

interface ProductDetailDataProps {
  name?: string;
  price: ProductPriceRange;
  priceWithVat: ProductPriceRange;
  rrpLabel?: string;
  rrpIncludingVatLabel?: string;
  isNew?: boolean;
  newLabel?: string;
  favoriteItem?: WibeProduct;
  features?: any[];
  videoLabel: string;
  videoUrl?: string;
  modelsLabel?: string;
  findRetailersLabel?: string;
  findSparePartsLabel?: string;
  brandSegments?: ProductDetailsValue[];
  sku?: string;
  priceRange?: MinMax;
  priceRangeWithVat?: MinMax;
  currency?: string;
}

export const ProductDetailData: React.FC<ProductDetailDataProps> = ({
  name,
  price,
  priceWithVat,
  rrpLabel,
  rrpIncludingVatLabel,
  isNew,
  newLabel,
  favoriteItem,
  modelsLabel,
  findRetailersLabel,
  findSparePartsLabel,
  features = [],
  brandSegments,
  videoLabel,
  videoUrl,
  sku,
  priceRange,
  priceRangeWithVat,
  currency = '',
}) => {
  const displayPrice = priceRange
    ? renderPrice(priceRange?.min || 0, priceRange?.max || 0, currency)
    : priceRangeWithVat
    ? renderPrice(
        priceRangeWithVat?.min || 0,
        priceRangeWithVat?.max || 0,
        currency,
      )
    : price?.value
    ? renderPrice(price.value.min || 0, price.value.max || 0, currency)
    : priceWithVat?.value
    ? renderPrice(
        priceWithVat.value.min || 0,
        priceWithVat.value.max || 0,
        currency,
      )
    : '';
  return (
    <StyledProductDetailData>
      <InfoFirstBlock>
        <ProductFeatures
          features={brandSegments?.map(({ text }) => text) || []}
          isNew={isNew}
          newLabel={newLabel}
          absolute={false}
          style={{ marginBottom: spacing.s }}
          direction="row"
        />
        {name && <Name>{name}</Name>}
        <PriceWrapper>
          <Price>{displayPrice}</Price>
          <RrpLabel>
            {displayPrice && price
              ? rrpLabel
              : displayPrice && priceWithVat
              ? rrpIncludingVatLabel
              : ''}
          </RrpLabel>
        </PriceWrapper>
        <FeatureList>
          {features.map((item) => (
            <FeatureItem key={`feature-${item}`}>{item}</FeatureItem>
          ))}
        </FeatureList>
        {videoUrl && (
          <VideoPlayerLink href="#product-video">
            <Icon svg="play-with-circle" />
            {videoLabel}
          </VideoPlayerLink>
        )}
        <ModelsButtonWrapper>
          <ModelsButton to="#models">
            <Icon svg="models" />
            {modelsLabel}
          </ModelsButton>
          {favoriteItem && (
            <Favorite product={favoriteItem} outerButton={true} />
          )}
        </ModelsButtonWrapper>
        <Button
          variant="primary"
          style={{ textAlign: 'center', width: '100%' }}
          to={`/${routes.RETAILERS}`}
        >
          <Icon
            svg="place-tag"
            style={{
              marginRight: spacing.xs,
            }}
          />
          {findRetailersLabel}
        </Button>
        <Flex align="center">
          <TextButton
            arrow
            to={`http://spareparts.wibeladders.com/en/uproduct/?id=${sku}`}
            style={{ marginTop: spacing.m }}
          >
            {findSparePartsLabel}
          </TextButton>
        </Flex>
      </InfoFirstBlock>
    </StyledProductDetailData>
  );
};
