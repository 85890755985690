import styled from 'styled-components';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { TextButton } from '../text-button/text-button';

interface BackgroundProps {
  $cover?: boolean;
}

export const Background = styled.div<BackgroundProps>`
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: center;
  position: fixed;
  z-index: 100;
  background-color: ${({ $cover }) => ($cover ? 'rgba(0, 0, 0, 0.66)' : null)};
`;

interface DialogWrapperProps {
  $isFullscreen?: boolean;
}

export const DialogWrapper = styled.div<DialogWrapperProps>`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  height: ${({ $isFullscreen }) => ($isFullscreen ? '100%' : null)};
  margin: auto;
  max-width: ${({ $isFullscreen }) => ($isFullscreen ? null : '400px')};
  min-height: ${({ $isFullscreen }) => ($isFullscreen ? null : '300px')};
  overflow-y: auto;
  padding: ${({ $isFullscreen }) => ($isFullscreen ? spacing.l : spacing.l)}
    ${spacing.s} ${spacing.m};
  position: relative;
  width: 100%;
`;

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin: 0 auto;
  width: 100%;
`;

export const StyledTextButton = styled(TextButton)`
  height: 40px;
  padding: 0;
  position: absolute;
  right: ${spacing.s};
  top: ${spacing.s};
  width: 40px;

  img {
    block-size: 24px;
    inline-size: 24px;
  }
`;
