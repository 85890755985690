import { ImageFragment } from '@hultafors/shared/types';

import { spacing } from '@hultafors/wibe/helpers';
import { CtaFragment } from '@hultafors/wibe/types';

import { Button } from '../button/button';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { H1 } from '../h1/h1';
import { Markdown } from '../markdown/markdown';
import { TextButton } from '../text-button/text-button';

import {
  ImageDesktop,
  ImageMobile,
  ImageWrapper,
  StyledInformationHero,
} from './information-hero.styled';

interface InformationHeroProps {
  title: string;
  description?: string;
  label?: string;
  image?: ImageFragment;
  imageMobile?: ImageFragment;
  type: 'contentView' | 'contactView' | 'NotFoundView' | 'ContentListView';
  cta?: CtaFragment;
  textButtonStyle?: boolean;
}

export const InformationHero: React.FC<InformationHeroProps> = ({
  cta,
  textButtonStyle,
  title,
  image,
  imageMobile,
  type = 'contentView',
  label = '',
  description = '',
}) => {
  return (
    <StyledInformationHero title={title} $imageMobile={!!imageMobile}>
      <div className="hero-content">
        <Grid columnGap={0}>
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 8, start: 3 },
              { breakpoint: 'desktop', columns: 6, start: 4 },
            ]}
          >
            <div className={`hero-text-wrapper ${type}`}>
              <H1 bold className={`hero-title ${type}`}>
                {title}
              </H1>
              {description && (
                <Markdown
                  className="hero-description bold"
                  options={{ forceBlock: true }}
                >
                  {description}
                </Markdown>
              )}
              {cta?.url &&
                (textButtonStyle ? (
                  <TextButton
                    to={cta.url}
                    style={{ alignSelf: 'flex-start', marginTop: spacing.m }}
                    arrow
                  >
                    {cta.label}
                  </TextButton>
                ) : (
                  <Button
                    variant="rounded"
                    to={cta.url}
                    style={{ alignSelf: 'flex-start', marginTop: spacing.m }}
                  >
                    {cta.label}
                  </Button>
                ))}
            </div>
          </GridChild>
        </Grid>
        {image?.responsiveImage?.src && (
          <ImageWrapper
            $aspectRatio={image.responsiveImage.aspectRatio || ''}
            $mobile={!!imageMobile?.responsiveImage?.src}
          >
            {imageMobile?.responsiveImage?.src && (
              <ImageMobile
                src={imageMobile.responsiveImage.src || ''}
                alt={imageMobile.alt || ''}
                blurDataURL={imageMobile.responsiveImage.base64 || ''}
                placeholder="blur"
                sizes="100vw"
                fill
                quality={100}
              />
            )}
            <ImageDesktop
              src={image.responsiveImage.src}
              alt={image.alt || ''}
              blurDataURL={image.responsiveImage.base64 || ''}
              placeholder="blur"
              sizes="100vw"
              fill
              $mobile={imageMobile?.responsiveImage?.src && 'true'}
              quality={100}
            />
          </ImageWrapper>
        )}
      </div>
    </StyledInformationHero>
  );
};
