import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

import { Button } from '../button/button';
import { FilterToggle } from '../filter-toggle/filter-toggle';

export const StyledFilterSection = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    margin-block-end: ${spacing.xs};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block-end: ${spacing.m};

    > div {
      flex-direction: row;
    }
  }
`;

/* Remove Icon margin-left on mobile. */
export const FilterButtonWrapper = styled.div`
  > button > svg {
    margin-inline-start: 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    > button > svg {
      margin-inline-start: ${spacing.xs};
    }
  }
`;

export const HighlightAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    inline-size: fit-content;
  }
`;

export const SectionFilterToggle = styled(FilterToggle)`
  > button {
    padding-block: 0;

    @media screen and (min-width: ${breakpoints.desktop}) {
      padding-block: ${spacing.s};
    }
  }
`;

export const SelectedFilterListAndButtonWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: 0;

  @media screen and (min-width: ${breakpoints.desktop}) {
    justify-content: space-between;
    margin-block-start: ${spacing.m};
  }
`;

export const FilterButton = styled(Button)`
  padding-bottom: ${spacing.s};
  padding-top: ${spacing.s};

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-block: 0;
  }
`;
