import { useRef } from 'react';

import { useClickOutside } from '@react-hookz/web';

import { StyledSlideInTop } from './slide-in-top.styled';

interface SlideInTopProps {
  open?: boolean;
  close(): void;
}

export const SlideInTop: React.FC<SlideInTopProps> = ({ open, close }) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, close);

  return <StyledSlideInTop ref={ref} $open={open} />;
};
