import { useEffect, useMemo, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import { useDebounce } from 'react-use';
import useSWR from 'swr';

import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import { routes } from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';
import { WibeProduct } from '@hultafors/wibe/types';

import { Grid } from '../grid/grid';
import { HTag } from '../h-tag/h-tag';
import { Loader } from '../loader/loader';
import { Products } from '../products/products';
import { SearchInput } from '../search-input/search-input';
import { TextButton } from '../text-button/text-button';

import { ProductWrapper, Search } from './auto-complete-search-box.styled';

interface AutoCompleteSearchBoxProps {
  toggleMenu?(): void;
  isOpen?: boolean;
  full?: boolean;
  focusInput?: boolean;
  focusCallback?(): void;
  productsCallback?(): void;
  onSearchActive?(value?: boolean): void;
  className?: string;
  passive?: boolean;
}

const MIN_CHARS_BEFORE_SEARCH = 2;
const MAX_NUMBER_OF_PRODUCTS = 3;

export const AutoCompleteSearchBox: React.FC<AutoCompleteSearchBoxProps> = ({
  full,
  isOpen,
  onSearchActive,
  className = '',
  passive,
  toggleMenu,
  focusCallback,
  focusInput,
  productsCallback,
}) => {
  const { locale, ...router } = useRouter();

  const { global, settings } = useGlobal();
  const searchRef = useRef<HTMLInputElement>(null);

  // const [searchTerm, setSearchTerm] = useState('');
  const [term, setTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  // const [products, setProducts] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  const { data, isLoading } = useSWR<ParsedProductsApiResponse<WibeProduct>>(
    !passive &&
      searchValue &&
      settings?.pt?.searchSection &&
      `/api/searchlist/${settings?.pt.searchSection}?${new URLSearchParams({
        includeFilters: 'false',
        pageSize: `${MAX_NUMBER_OF_PRODUCTS}`,
        searchValue,
        ...(locale && locale !== 'com' ? { locale } : {}),
      }).toString()}`,
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
    },
  );

  const products: WibeProduct[] = useMemo(() => {
    return data?.items || [];
  }, [data]);

  const [, cancel] = useDebounce(
    () => {
      setSearchValue(term);
    },
    300,
    [term],
  );

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setTerm(event.target.value);
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    if (term.length) {
      cancel();
      const query = new URLSearchParams({ q: term }).toString();
      router.push(`/${routes.SEARCH}?${query}`);
      clearSearch();
      // toggleMenu();
    }
  };

  const clearSearch = () => {
    setTerm('');
    setSearchValue('');
    cancel();
  };

  useEffect(() => {
    if (isOpen) {
      searchRef.current?.focus();
    }
    if (!isOpen) {
      clearSearch();
    }
  }, [isOpen]);

  const composeRender = () => {
    return (
      <Search className={className + isOpen ? '' : ' is-hidden'}>
        <SearchInput
          onChange={onChange}
          value={term}
          placeholder={global?.searchProducts || ''}
          onSubmit={onSubmit}
          clearSearch={clearSearch}
          name="Search"
          ref={searchRef}
          focusCallback={focusCallback}
          full={full}
          id="autocompleteSearch"
        />
        {(isLoading || products.length > 0) && (
          <ProductWrapper>
            {isLoading && <Loader />}
            {products.length < 1 &&
              !isLoading &&
              term.length >= MIN_CHARS_BEFORE_SEARCH && (
                <HTag type="h3" styleType="header5">
                  {global?.noResults || ''}
                </HTag>
              )}
            {products.length > 0 &&
              !isLoading &&
              term.length >= MIN_CHARS_BEFORE_SEARCH && (
                <>
                  <HTag type="h2" styleType="header4">
                    {global?.suggestions || ''}
                  </HTag>
                  <TextButton onClick={onSubmit} arrow space>
                    {global?.viewAll || ''}
                  </TextButton>
                  <Grid
                    columnGap={[{ columnGap: 16 }]}
                    columns={[
                      { columns: 1 },
                      { breakpoint: 'mobileMax', columns: 2 },
                      { breakpoint: 'desktop', columns: 3 },
                    ]}
                  >
                    <Products
                      products={products}
                      badgeText={global?.new || ''}
                      isLoading={isLoading}
                      numberOfItems={MAX_NUMBER_OF_PRODUCTS}
                      rrpLabel={global.rrpLabel || ''}
                      rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
                    />
                  </Grid>
                </>
              )}
          </ProductWrapper>
        )}
      </Search>
    );
  };

  return <>{composeRender()}</>;
};
