import { css } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { marginContainer } from './margin-container';

export const clampedContainer = (
  max: Breakpoint = 'maxPageWidth',
  breakpoint?: Breakpoint,
) => {
  return css`
    ${marginContainer(breakpoint)};
    max-width: ${breakpoints[max]};
  `;
};
