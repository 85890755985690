import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { Paragraph } from '../paragraph/paragraph';

export const StyledGreenInfoBox = styled.section`
  margin: ${spacing.l} ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.xl} 0 ${spacing.m} 0;
  }

  .content {
    background-color: ${colors.wibeGreen};
    border-radius: 12px;
    color: ${colors.white};
    padding: ${spacing.side};

    & > div {
      *:last-child {
        margin-block-end: 0;
      }
    }
  }
`;

export const StyledMarkdown = styled(Markdown).attrs({
  options: {
    overrides: {
      p: {
        component: Paragraph,
        props: {
          styleType: 'paragraph16',
        },
      },
    },
  },
})`
  & > *:not(:last-child) {
    margin-block-end: ${spacing.s};
  }
`;
