import Head from 'next/head';

import { MetaData } from '@hultafors/shared/types';

import styles from './page.module.scss';

interface PageProps {
  metadata: MetaData;
  children?: React.ReactNode;
  testId?: string;
}

export const Page: React.FC<PageProps> = ({
  metadata,
  children,
  testId = '',
}) => {
  return (
    <>
      {metadata && (
        <Head>
          {metadata.title && (
            <>
              <title>{metadata.title}</title>
              <meta property="og:title" content={metadata.title} />
              <meta name="twitter:title" content={metadata.title} />
            </>
          )}
          {metadata.description && (
            <>
              <meta name="description" content={metadata.description} />
              <meta property="og:description" content={metadata.description} />
              <meta name="twitter:description" content={metadata.description} />
            </>
          )}
          {metadata.type && <meta property="og:type" content={metadata.type} />}
          {metadata.image?.url && (
            <>
              <meta property="og:image" content={metadata.image.url} />
              <meta property="twitter:image" content={metadata.image.url} />
            </>
          )}
          {metadata.image?.width && (
            <meta
              property="og:image:width"
              content={`${metadata.image.width}`}
            />
          )}
          {metadata.image?.height && (
            <meta
              property="og:image:height"
              content={`${metadata.image.height}`}
            />
          )}
          {metadata.image?.alt && (
            <meta property="twitter:image:alt" content={metadata.image.alt} />
          )}
          <meta name="twitter:card" content="summary" />
        </Head>
      )}

      <main className={styles['main']} data-test-id={testId}>
        {children}
      </main>
    </>
  );
};
