import dynamic from 'next/dynamic';

import { FILTER_TYPE } from '@hultafors/wibe/api';

import { Button } from '../button/button';
import { CheckboxFilter } from '../checkbox-filter/checkbox-filter';
import { FilterSlider } from '../filter-slider/filter-slider';
import { FilterToggle } from '../filter-toggle/filter-toggle';
import { Flex } from '../flex/flex';
import { Icon } from '../icon/icon';
import { SelectedFilterList } from '../selected-filter-list/selected-filter-list';

import { StyledFilter } from './filter.styled';

const Accordion = dynamic(() =>
  import('../accordion/accordion').then((mod) => mod.Accordion),
);
interface FilterProps {
  filters: any[];
  filterLabel?: string;
  selectedFilters: any[];
  filterChange(value: any): void;
  fromLabel?: string;
  toLabel?: string;
  typeLabel?: string;
  clearFilters?(): void;
  clearFilterLabel?: string;
  clearParentFilter(): void;
  breakoutFilter?: any;
}

export const Filter: React.FC<FilterProps> = ({
  filters = [],
  filterLabel,
  selectedFilters,
  filterChange,
  fromLabel,
  toLabel,
  typeLabel,
  clearFilters,
  clearFilterLabel,
  clearParentFilter,
  breakoutFilter,
}) => {
  /**
   * @desc Numeric filter type is always rendered as a slider
   * Never render numeric filter if we have < 2 children. Range slider requires >=2 children to be useful.
   * @param {object} node
   */
  const renderNumeric = (node: any) => {
    return node.children.length > 1 ? (
      <li
        title={node.name}
        key={`filter-item-${node.id}`}
        className="FilterWrapper"
      >
        <div className="SubFilters">
          <FilterSlider
            filter={node}
            onChange={() => filterChange(node)}
            fromLabel={fromLabel}
            toLabel={toLabel}
            filterChange={filterChange}
            selectedFilters={selectedFilters}
          />
        </div>
      </li>
    ) : null;
  };

  /**
   * @desc Enumerated filter type is always rendered as list of checkboxes
   * @param {object} node
   */
  const renderEnumerated = (node: any) => {
    return (
      <li
        title={node.name}
        key={`filter-item-${node.id}`}
        className="FilterWrapper"
      >
        <CheckboxFilter
          filterNode={node}
          filterChange={filterChange}
          selectedFilters={selectedFilters}
        />
      </li>
    );
  };

  const isFilterToggleSelected = () => {
    if (selectedFilters.length > 0 && breakoutFilter?.filter) {
      if (selectedFilters.find((x) => x.id === breakoutFilter.filter.id)) {
        return true;
      }
    }
    return false;
  };

  /**
   * @desc We dont display "Bra arbetsmiljöval" filter in main filter list if present
   * @param {array} filters
   * @param {object} breakoutFilter
   * @returns {array} filters to render
   */
  const filterOutSpecialFilter = (filters: any[], breakoutFilter?: any) => {
    if (breakoutFilter?.filter) {
      return filters.filter((x) => x.id !== breakoutFilter.filter.id);
    }
    return filters;
  };
  if (!filters?.length) {
    return null;
  }
  return (
    <StyledFilter>
      {selectedFilters.length > 0 && (
        <Button onClick={clearFilters} variant="transparent">
          <Icon svg="trash" style={{ marginLeft: 0 }} />
          {clearFilterLabel}
        </Button>
      )}
      <SelectedFilterList
        selectedFilters={selectedFilters}
        filters={filters}
        filterChange={filterChange}
      />
      <Flex justify="flex-start">
        <FilterToggle
          style={{ width: '100%' }}
          filter={breakoutFilter.filter}
          helpText={breakoutFilter.helpText}
          filterChange={filterChange}
          isSelected={isFilterToggleSelected()}
        />
      </Flex>
      <Accordion className="FilterMenu">
        {filterOutSpecialFilter(filters, breakoutFilter).map((parent) => {
          if (parent.type === FILTER_TYPE.NUMERIC) {
            return renderNumeric(parent);
          }
          if (parent.type === FILTER_TYPE.ENUMERATED) {
            return renderEnumerated(parent);
          }
          return '';
        })}
      </Accordion>
    </StyledFilter>
  );
};
