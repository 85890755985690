import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

export const StyledSimpleTextAndCta = styled.section`
  padding: ${spacing.xl} ${spacing.s} ${spacing.l};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: ${spacing.xl} ${spacing.side} ${spacing.l};
  }
`;
