import { Maybe } from '@hultafors/wibe/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { HTag } from '../h-tag/h-tag';

import { StyledQuote } from './quote.styled';

interface QuoteProps {
  body?: Maybe<string>;
}

export const Quote: React.FC<QuoteProps> = ({ body }) => {
  return body ? (
    <StyledQuote>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <div className="content">
            <div className="text-wrapper">
              <HTag
                type="h2"
                styleType={[
                  { styleType: 'header3' },
                  { breakpoint: 'mobileMax', styleType: 'header2' },
                ]}
                color="wibeDarkGrey"
              >
                &quot;
                {body}
                &quot;
              </HTag>
            </div>
          </div>
        </GridChild>
      </Grid>
    </StyledQuote>
  ) : null;
};
