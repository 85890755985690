import { useFavorites } from '@hultafors/wibe/hooks';
import { WibeProduct } from '@hultafors/wibe/types';

import { FavoriteIndicator } from '../favorite-indicator/favorite-indicator';

import { FavoriteStyled } from './favorite.styled';

interface FavoriteProps {
  product: WibeProduct;
  outerButton?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const Favorite: React.FC<FavoriteProps> = ({
  product,
  outerButton,
  className,
  style,
}) => {
  const { toggleFavorite, favorites } = useFavorites();
  const active = !!favorites.find(
    ({ productId }) => product && productId === product.productId,
  );

  return (
    <FavoriteStyled
      onClick={() => toggleFavorite(product)}
      $outerButton={outerButton}
      className={className}
      style={style}
    >
      <FavoriteIndicator active={active} />
    </FavoriteStyled>
  );
};
