import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';
import { buttonReset } from '@hultafors/shared/helpers';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/wibe/helpers';

import { Paragraph } from '../paragraph/paragraph';

export const TransformWrapper = styled.div`
  background-color: ${colors.white};
  border-block-end: 1px solid ${colors.grey2};
  position: relative;
  z-index: 2;
  inline-size: 100vi;
  inline-size: 100dvi;
  block-size: 100%;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  margin: auto;
  width: 100%;
`;

interface FlexProps {
  $desktop?: boolean;
  $right?: boolean;
}

export const Flex = styled.div<FlexProps>`
  align-items: center;
  display: ${({ $desktop }) => ($desktop ? 'none' : 'flex')};
  height: 100%;
  margin-inline-start: ${({ $right }) => ($right ? 'auto' : '')};

  @media screen and (width >= 1200px) {
    display: ${({ $desktop }) => ($desktop ? 'flex' : null)};
    block-size: unset;
    margin-block-start: ${({ $desktop }) => ($desktop ? 'auto' : null)};
  }
`;

export const MenuIcon = styled.img`
  cursor: pointer;
  margin-inline-start: ${spacing.s};
  width: 24px;

  @media screen and (width >= 1200px) {
    display: none;
  }
`;

export const Logo = styled(Link)`
  height: ${spacing.l};
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (width >= 1200px) {
    margin-inline-end: ${spacing.m};
  }
`;

export const SiteLogoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface MenuItemProps {
  $hasChildrenStyled?: boolean;
  $menuOpen?: boolean;
}

const hover = css<MenuItemProps>`
  &::after {
    background-color: ${colors.systemBlack};
    content: '';
    block-size: 1px;
    inset-inline: 0
      ${({ $hasChildrenStyled }) => ($hasChildrenStyled ? spacing.s : 0)};
    margin: auto;
    position: absolute;
    inline-size: 26px;
  }
`;

export const MenuItem = styled(Link)<MenuItemProps>`
  height: 100%;
  font-size: ${fontSizes.paragraph16};
  font-weight: ${fontWeights.medium};
  margin-inline-end: ${spacing.s};
  display: flex;
  align-items: flex-end;
  padding-block-end: ${spacing.xs};
  border-block-start: 1px solid transparent;
  border-block-end: 1px solid transparent;
  cursor: pointer;
  position: relative;

  ${({ $menuOpen }) => $menuOpen && hover};
  @media (hover: hover) {
    :hover {
      ${hover};
    }
  }
  ${({ $hasChildrenStyled }) =>
    $hasChildrenStyled &&
    css`
      cursor: default;
      margin: 0;
      padding-inline-end: ${spacing.s};
    `};
`;

export const MenuButton = styled.button<MenuItemProps>`
  height: 100%;
  line-height: 1.5;
  font-size: ${fontSizes.paragraph16};
  font-weight: ${fontWeights.medium};
  margin-inline-end: ${spacing.s};
  display: flex;
  align-items: flex-end;
  padding-block-end: ${spacing.xs};
  background: none;
  border: none;
  border-block-start: 1px solid transparent;
  border-block-end: 1px solid transparent;
  cursor: pointer;
  position: relative;

  ${({ $menuOpen }) => $menuOpen && hover};
  @media (hover: hover) {
    :hover {
      ${hover};
    }
  }
  ${({ $hasChildrenStyled }) =>
    $hasChildrenStyled &&
    css`
      cursor: pointer;
      margin: 0;
      padding-inline-end: ${spacing.s};
    `};
`;
interface BackdropProps {
  $show?: boolean;
}
export const Backdrop = styled.div<BackdropProps>`
  background-color: ${({ $show }) => ($show ? colors.overlay : 'transparent')};
  block-size: 100vb;
  block-size: 100dvb;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: 0.3s;
  inline-size: 100vi;
  inline-size: 100dvi;
`;

interface MenuDrop {
  $showSubMenu?: boolean;
  $closeFast?: boolean;
}

export const MenuDrop = styled.div<MenuDrop>`
  display: none;
  width: 100%;

  @media screen and (width >= 1200px) {
    background-color: ${colors.white};
    display: initial;
    inset-inline-start: 0;
    max-block-size: calc(100vh - var(--header-height, 64px));
    overflow-y: auto;
    padding: ${spacing.l} ${spacing.xl};
    pointer-events: ${({ $showSubMenu }) =>
      $showSubMenu ? 'initial' : 'none'};
    position: absolute;
    inset-block-start: 100%;
    transform: ${({ $showSubMenu }) =>
      $showSubMenu ? 'translateY(0%)' : 'translateY(-100%)'};
    transform-origin: 0% 0%;
    transition: ${({ $closeFast }) => ($closeFast ? '0s' : '0.3s')};
    visibility: ${({ $showSubMenu }) => ($showSubMenu ? 'initial' : 'hidden')};
    z-index: 1;

    > div {
      opacity: ${({ $showSubMenu }) => ($showSubMenu ? '1' : '0')};
      transform: ${({ $showSubMenu }) =>
        $showSubMenu ? 'translateY(+6px)' : 'translateY(-6px)'};
      transition: 0.4s;
      transition-delay: ${({ $showSubMenu }) =>
        $showSubMenu ? '0.16s' : '0s'};
      transition-property: all;
    }
  }
`;

export const InnerDrop = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${breakpoints.maxPageWidth};
`;

export const GuidesExtraLinks = styled.div`
  padding-block-start: ${spacing.m};
`;

export const Arrow = styled.span`
  margin-inline-start: ${spacing.s};
`;

interface SubMenuItemProps {
  $products?: boolean;
}

export const SubMenuItem = styled(Link)<SubMenuItemProps>`
  display: flex;
  font-size: ${({ $products }) =>
    $products ? fontSizes.paragraph16 : fontSizes.paragraph14};
  font-weight: ${({ $products }) =>
    $products ? fontWeights.medium : fontWeights.regular};
  margin-block-end: ${spacing.s};
  width: auto;
  cursor: pointer;
`;

export const SubMenuHeader = styled(Paragraph)`
  color: ${colors.wibeDarkSteel};
  font-size: ${fontSizes.paragraph14};
  font-weight: ${fontWeights.medium};
  margin-block-end: ${spacing.s};
  margin-inline: 0;
`;

interface IconItemProps {
  $desktop?: boolean;
  $mobile?: boolean;
}

export const IconItem = styled.img<IconItemProps>`
  cursor: pointer;
  display: ${({ $desktop }) => $desktop && 'none'};
  margin-inline-start: ${spacing.s};

  @media screen and (width >= 1200px) {
    display: initial;
    display: ${({ $mobile }) => $mobile && 'none'};
  }
`;

export const IconLink = styled(Link)`
  cursor: pointer;
  display: none;

  @media screen and (width >= 1200px) {
    display: initial;
  }
`;

interface SearchWrapProps {
  $hide?: boolean;
}

export const SearchWrap = styled.div<SearchWrapProps>`
  align-items: center;
  background-color: ${colors.white};
  border-block-end: 1px solid ${colors.grey2};
  display: flex;
  height: var(--header-height);
  left: 0;
  opacity: 1;
  padding: 0 ${spacing.s};
  position: fixed;
  transition: 0.1s all ease-in-out;
  width: 100%;
  z-index: 3;
  ${({ $hide }) =>
    $hide &&
    css`
      opacity: 0;
      pointer-events: none;
      transition: 0.1s all ease-in-out;
    `}

  @media screen and (width >= 1200px) {
    background-color: transparent;
    inset-inline-start: initial;
    padding: initial;
    position: initial;
  }
`;

interface NavIconButtonProps {
  $mobile?: boolean;
}

function mobileDisplay({ $mobile }: NavIconButtonProps) {
  if (!$mobile) {
    return css``;
  }
  return css`
    @media screen and (width >= 1200px) {
      display: none;
    }
  `;
}

export const NavIconButton = styled.button<NavIconButtonProps>`
  ${buttonReset};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mobileDisplay}
`;

export const PartnerLink = styled(Link)`
  display: none;

  @media screen and (width >= 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ExtraNav = styled.nav`
  display: flex;
  margin-inline-start: ${spacing.s};
  gap: ${spacing.s};
  color: ${colors.grey4};
`;

export const IconLabel = styled.span`
  display: none;
  font-size: var(--font-size-paragraph12);
  line-height: var(--line-height-paragraph12);
  white-space: nowrap;

  @media screen and (width >= 1200px) {
    display: block;
  }
`;
