import { ImageBlock } from '../image-block/image-block';

import { StyledPicture } from './picture.styled';

interface PictureSource {
  alt?: string;
  src?: string;
  srcSet?: string;
  type: string; // ['image/webp', 'image/jpeg', 'image/png', 'image/gif'] ...
  sizes?: string; // Allow to override the "sizes" prop for one specific source if needed
}

interface PictureProps {
  caption: string;
  children?: React.ReactNode;
  pictureProps?: React.HTMLAttributes<HTMLPictureElement>;
  sizes?: string;
  sources: PictureSource[];
}

export const Picture: React.FC<PictureProps> = ({
  caption,
  children,
  pictureProps = {},
  sizes = '100vw',
  sources = [],
}) => (
  <StyledPicture {...pictureProps}>
    {sources.map(({ sizes: sourceSizes, ...source }) => (
      <source
        key={`source-${caption}-${source.type}`}
        sizes={sourceSizes || sizes}
        {...source}
      />
    ))}
    <ImageBlock src={sources[0]?.src || ''} sizes={sizes} alt={caption} />
    {children}
  </StyledPicture>
);
