import Link from 'next/link';

import { ColorPlugsFragment } from '@hultafors/wibe/types';

import { Grid } from '../grid/grid';
import { HTag } from '../h-tag/h-tag';
import { Icon } from '../icon/icon';

import { Arrow, StyledColorPlugs, StyledGridChild } from './color-plugs.styled';

export const getColorByIndex = (index: number) => {
  switch (index) {
    case 0:
      return 'wibeYellow';
    case 1:
      return 'white';
    default:
      return 'wibeDarkGrey';
  }
};

interface PlugProp {
  title?: string;
  url?: string;
  ctaLinkText?: string;
}

interface ColorPlugsProps {
  plugs: PlugProp[];
}

export const ColorPlugs: React.FC<ColorPlugsProps | ColorPlugsFragment> = ({
  plugs,
}) => {
  if (!plugs?.length) {
    return null;
  }

  return (
    <StyledColorPlugs>
      <Grid
        columnGap={2}
        rowGap={2}
        columns={[{ columns: 1 }, { breakpoint: 'desktop', columns: 3 }]}
      >
        {plugs?.map((item, index) => {
          if (!item.ctaLinkText && !item.title && !item.url) {
            return null;
          }
          const key = `ColorPlug-${index}`;
          return (
            <Link href={item.url || ''} key={key}>
              <StyledGridChild $index={index}>
                <HTag
                  type="h3"
                  styleType="header3"
                  color={getColorByIndex(index)}
                  style={{ marginTop: 'auto' }}
                >
                  {item.title}
                </HTag>
                <Arrow $index={index}>
                  <Icon svg="xl-arrow-right" />
                </Arrow>
              </StyledGridChild>
            </Link>
          );
        })}
      </Grid>
    </StyledColorPlugs>
  );
};
