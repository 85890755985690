import { CSSProperties } from 'styled-components';

import { Color } from '@hultafors/wibe/helpers';

import { ParagraphStyleType, StyledParagraph } from './paragraph.styled';

interface ParagraphProps {
  children?: React.ReactNode;
  className?: string;
  as?: 'p' | 'span' | 'a' | React.ComponentType<any>;
  style?: React.CSSProperties;
  color?: Color;
  styleType?: ParagraphStyleType;
  margin?: CSSProperties['margin'];
}

export const Paragraph: React.FC<ParagraphProps> = ({
  className,
  children,
  color = 'systemBlack',
  styleType,
  margin,
  as = 'p',
  style,
}) => (
  <StyledParagraph
    as={as}
    className={className}
    style={style}
    $color={color}
    $styleType={styleType}
    $margin={margin}
  >
    {children}
  </StyledParagraph>
);
