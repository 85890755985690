import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import {
  Container,
  Label,
  OuterGridChild,
  StyledImage,
} from './icon-box.styled';

export const IconBox: React.FC<IconBoxProps> = ({ items }) => {
  return (
    <Container className="IconBox">
      <Grid
        columns={[{ columns: 8 }, { breakpoint: 'desktop', columns: 12 }]}
        columnGap="s"
      >
        <OuterGridChild>
          <Grid
            columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 5 }]}
            columnGap="s"
          >
            {items.map((item, i) => (
              <GridChild
                key={`icon-${i}`}
                columnSpan={[
                  { columns: 1 },
                  { breakpoint: 'mobileMax', columns: 1 },
                ]}
              >
                <StyledImage src={item.image.url} alt={item.image.alt} />
                <Label>{item.label.replace('-', '­')}</Label>
              </GridChild>
            ))}
          </Grid>
        </OuterGridChild>
      </Grid>
    </Container>
  );
};

interface IconBoxProps {
  items: any[];
}
