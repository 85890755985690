import styled, { css } from 'styled-components';

export const LinkBlockStyled = styled.div<{ $square?: boolean }>`
  display: flex;
  justify-content: center;

  ${({ $square }) =>
    $square
      ? css`
          a {
            border-radius: 0;
            block-size: 56px;
            padding: 0 32px;
          }
        `
      : ''}
`;
