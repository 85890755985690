import { Icon } from '../icon/icon';
import { Paragraph } from '../paragraph/paragraph';

import { DownloadFileBoxStyled } from './download-file-box.styled';

interface DownloadFileBoxProps {
  url?: string;
  size?: string | number;
  label?: string;
  filetype?: string;
  className?: string;
}

export const DownloadFileBox: React.FC<DownloadFileBoxProps> = ({
  filetype = '',
  size = '',
  url,
  label,
  className,
}) => {
  // Add zero-width space to allow line break after underscore
  const soft = label?.replace(/_/g, '_\u200b') ?? '';

  return (
    <DownloadFileBoxStyled data-test="DownloadFileBox" className={className}>
      <a href={url}>
        <Icon svg="file-24" />
        <div className="TextWrapper">
          <p className="Label">{soft}</p>
          <Paragraph styleType="paragraph10" className="Size">
            {filetype} {size}
          </Paragraph>
        </div>
        <Icon svg="download" />
      </a>
    </DownloadFileBoxStyled>
  );
};
