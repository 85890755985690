import {
  Label,
  StyledSearchResultIntro,
  Term,
} from './search-result-intro.styled';

interface SearchResultIntroProps {
  label: string;
  searchTerm: string;
}

export const SearchResultIntro: React.FC<SearchResultIntroProps> = ({
  label,
  searchTerm,
}) => {
  return (
    <StyledSearchResultIntro>
      {label && <Label>{label}</Label>}
      {label && searchTerm && ' '}
      {searchTerm && (
        <Term>
          &ldquo;
          {searchTerm}
          &rdquo;
        </Term>
      )}
    </StyledSearchResultIntro>
  );
};
