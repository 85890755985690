import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

export const ProductDetailStyled = styled.div`
  margin-top: ${spacing.l};

  .VideoSection {
    @media screen and (min-width: ${breakpoints.desktop}) {
      padding: ${spacing.xl} 0;
    }
  }

  .Documents .CollapseContent.isOpen {
    padding-block-end: ${spacing.m};
  }
`;
