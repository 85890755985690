import { getParameterFromUrlByName } from './helpers';

/**
 * @desc The API have 2 filter types that sits on parent filter, all subsequent children inherit the same filter type
 */
export const FILTER_TYPE = {
  ENUMERATED: 'enumerated',
  NUMERIC: 'numeric',
};

const FILTER_KEYS = {
  ENUMERATED: 'ValueId',
  NUMERIC: 'Value',
  PARENT: 'AttrId',
};

/**
 * @desc Replaces or adds children filters under parent
 * @param {array} existingFilters
 * @param {object} parent, from api containing all children
 * @param {array} children, from api containg selected child
 * @returns {array}
 */
export const replaceProductFilter = ({
  existingFilters,
  parent,
  children,
}: {
  existingFilters: any[];
  parent: any;
  children: any;
}) => {
  let result: any[] = [];

  if (!parent || !children) {
    return existingFilters;
  }

  if (existingFilters && existingFilters.length > 0) {
    const existingParent = existingFilters.find((x: any) => x.id === parent.id);
    if (existingParent) {
      if (children.length > 0) {
        // add children to existing parent. If children is empty dont add to result, this will in effect remove parent
        result = addChildren({
          children,
          existingFilters,
          parent: existingParent,
        });
      }
    } else {
      // Current parent doesn´t exist, add new parent and children
      result = [...existingFilters, createParent({ child: children, parent })];
    }
  } else {
    // Filter is empty, add first parent and child
    result = [createParent({ child: children, parent })];
  }
  return result;
};

/**
 * @desc Adds or removes filter to exising array of filters
 * @param {array} existingFilters
 * @param {object} parent, from api containing all children
 * @param {object} child, from api containg selected child
 * @returns {array}
 */
export const toggleProductFilters = ({
  existingFilters,
  parent,
  child,
}: {
  existingFilters: any[];
  parent: any;
  child: any;
}) => {
  let result: any[] = [];
  let children: any[] = [];

  if (!parent || !child) {
    return existingFilters;
  }

  if (existingFilters && existingFilters.length > 0) {
    const existingParent = existingFilters.find((x: any) => x.id === parent.id);
    if (existingParent) {
      children = existingParent.children.filter((x: any) => x.id !== child.id);

      if (existingParent.children.length > children.length) {
        // Removing a child filter
        if (existingParent.children.length === 1) {
          // Last child of parent, remove both parent and child
          result = existingFilters.filter((x) => x.id !== parent.id);
        } else {
          // Remove child from parent
          result = addChildren({
            children,
            existingFilters,
            parent: existingParent,
          });
        }
      } else {
        // adding a child filter to existing parent
        children = [...existingParent.children, { ...child }];
        result = addChildren({
          children,
          existingFilters,
          parent: existingParent,
        });
      }
    } else {
      // Current parent doesn´t exist, add new parent and child
      result = [...existingFilters, createParent({ child, parent })];
    }
  } else {
    // Filter is empty, add first parent and child
    result = [createParent({ child, parent })];
  }

  return result;
};

/**
 * @desc Helper function to add array of children to its parent and return an immutable array result
 * @param {array} existingFilters
 * @param {object} parent
 * @param {array} children
 * @returns {array}
 */
const addChildren = ({
  existingFilters,
  parent,
  children,
}: {
  existingFilters: any[];
  parent: any;
  children: any;
}) => {
  const result: any[] = [];
  for (let i = 0, l = existingFilters.length; i < l; i++) {
    if (existingFilters[i].id === parent.id) {
      result.push({
        ...existingFilters[i],
        children,
      });
    } else {
      result.push({ ...existingFilters[i] });
    }
  }
  return result;
};

/**
 * @desc Helper to create structure of new parent node
 * @param {object} parent
 * @param {object} child
 * @returns
 */
const createParent = ({ parent, child }: { parent: any; child: any }) => {
  let children: any[] = [];
  if (Array.isArray(child)) {
    children = [...child];
  } else {
    children = [{ ...child }];
  }
  return {
    ...parent,
    children,
    // children: [{ ...child }]
  };
};

/**
 * @desc Creates url parameter for the api from array of filters
 * @param {array} filters
 * @returns {string}
 */
export const constructFilterUrlParameter = (filters = []) => {
  // Filtering criterias are enumerated by array index notation.
  // Parent filter id is notated by AttrId and child by either
  // ValueId for enumerated filter types and Value for Numeric filters-
  // Each parent/child pair has to have their own index in f[x] array
  // f[0].AttrId=54&f[0].Value=100&f[1].AttrId=55&f[1].ValueId=200&f[2].AttrId=55&f[2].ValueId=201

  if (!filters || filters.length === 0) {
    return '';
  }

  const params = filters.length > 0 ? '&' : '';

  let filterIndex = 0;

  const result = filters.reduce((prevParent: any, currentParent: any) => {
    const valueKey =
      currentParent.type === FILTER_TYPE.NUMERIC
        ? FILTER_KEYS.NUMERIC
        : FILTER_KEYS.ENUMERATED;

    return currentParent.children.reduce(
      (prevChild: any, currentChild: any) => {
        const temp =
          prevChild +
          `f[${filterIndex}].${FILTER_KEYS.PARENT}=${currentParent.id}&f[${filterIndex}].${valueKey}=${currentChild.id}&`;
        filterIndex++;
        return temp;
      },
      prevParent,
    );
  }, params);

  return result.slice(0, result.length - 1);
};

/**
 * @desc Takes string from browser. Used on reload when user comes in to page with a link with a filter.
 * @param {string} filterString string from url
 * @returns {array}
 */
export const constructFilterFromUrl = (filterString: string) => {
  // ?page=1&f[0].AttrId=845&f[0].ValueId=579133&f[1].AttrId=846&f[1].ValueId=579134
  if (!filterString) {
    return [];
  }

  const result: any[] = [];

  const re = /&f\[\d+\]\./;

  // split url to array removing f[x] notation
  let filters = filterString.split(re);

  if (filters.length > 1) {
    // we have a product filter in url

    // remove page param
    filters = filters.slice(1, filters.length);

    let index = 0;

    while (index < filters.length) {
      const filterValues = filters[index]?.split('=') ?? [];

      let filterObj: any = {};

      // ["AttrId", "666"]
      if (filterValues.length === 2) {
        if (filterValues[0] === FILTER_KEYS.PARENT) {
          filterObj = {
            children: [],
            id: parseInt(filterValues?.[1] ?? ''),
            name: '',
          };

          if (filters.length >= index + 1) {
            const childValues = filters[index + 1]?.split('=') ?? '';

            if (childValues.length === 2) {
              // [ Value | ValueId ]
              if (childValues[0] === FILTER_KEYS.NUMERIC) {
                filterObj.type = FILTER_TYPE.NUMERIC;
              } else {
                filterObj.type = FILTER_TYPE.ENUMERATED;
              }
              filterObj.children = [
                { id: parseInt(childValues?.[1] ?? ''), name: '' },
              ];
            }
          }
        }
      }

      result.push(filterObj);
      // increment by 2 to move on to the next filter
      index += 2;
    }
  }

  return sortChildrenUnderParent(result);
};

/**
 * @desc Sort children under parent. Assuming children always comes after their parents as they do in the url.
 * @param {array} Flat list of filters
 * @return {array} - Array of parents with arrays of children
 */
const sortChildrenUnderParent = (filters: any[]) => {
  const results: any[] = [];

  filters.map((filter: any, index) => {
    let foundChild = false;
    if (index === 0) {
      results.push(filter);
    } else {
      for (const result of results) {
        if (filter.id === result.id) {
          if (filter.children.length === 1) {
            result.children.push(filter.children[0]);
          }
          foundChild = true;
          break;
        }
      }

      if (!foundChild) {
        // Add parent filter
        results.push(filter);
      }
    }
  });

  return results;
};

/**
 * @desc Pass querystring for parsing filters to array and page to separate values
 * @param {string} queryString
 * @returns {object} { page: x, filters: []}
 */
export const getPageAndFilter = (queryString: string) => {
  const result: any = {
    filters: [],
    page: 1,
  };

  const page = getParameterFromUrlByName(queryString, 'page');

  if (page) {
    result.page = parseInt(page, 10);
  }

  result.filters = constructFilterFromUrl(queryString);

  return result;
};

/**
 * @desc Takes selectedFilters from url having only id:s but no names, take names from API call and set to selectedFilters
 * @param {array} productFilters, list of filters from API
 * @param {array} selectedFilters, list of filters from url
 * @returns
 */
export const resolveFilterNames = (
  productFilters: any[],
  selectedFilters: any[],
) => {
  let result: any[] = [];
  if (selectedFilters && productFilters) {
    result = selectedFilters.map((selectedFilter: any) => {
      const productParent = productFilters.find(
        (x) => x.id === selectedFilter.id,
      );
      if (productParent) {
        // Get names for child filters
        if (productParent.type === FILTER_TYPE.NUMERIC) {
          let unit = '';
          if (productParent.children.length > 0) {
            unit = productParent.children[0].unit;
          }
          const numericChildren = selectedFilter.children.map((sf: any) => {
            return {
              ...sf,
              unit: unit,
              value: sf.id,
            };
          });
          return {
            ...selectedFilter,
            children: numericChildren,
            name: productParent.name,
          };
        } else {
          const children = selectedFilter.children.map((selectedChild: any) => {
            return productParent.children.find((productChild: any) => {
              if (selectedChild.id === productChild.id) {
                return {
                  ...selectedChild,
                  name: productChild.name,
                };
              }
            });
          });

          return {
            ...selectedFilter,
            children,
            name: productParent.name,
          };
        }
      }
    });
  }

  return result;
};

/**
 * @desc Picks new array of filters from the first "numberOfFiltersToHighlight" in filters array
 * @param {array} filters
 * @param {number} numberOfFiltersToHighlight defaults to 2
 * @returns {array}
 */
export const getHighlightedFilters = (
  filters: any[],
  numberOfFiltersToHighlight = 2,
) => {
  if (filters) {
    return filters.slice(0, numberOfFiltersToHighlight);
  }
  return [];
};

/**
 * @desc Returns helper object from parent filter node containing min and max value from children + unit (mm, m etc)
 * @param {object} parent filterNode {id: number, children: []}
 * @returns {object} {min: number, max: number, unit: string}
 */
export const getFilterSummary = (filterNode: any) => {
  let summary = { max: 0, min: 0, unit: '' };
  if (filterNode && filterNode.children.length > 1) {
    try {
      summary = {
        max: parseInt(
          filterNode.children[filterNode.children.length - 1].value,
        ),
        min: parseInt(filterNode.children[0].value),
        unit: filterNode.children[0].unit,
      };
    } catch (error) {
      console.error(error);
    }
  }

  return summary;
};
