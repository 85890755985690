import styled from 'styled-components';

import { buttonReset } from '@hultafors/shared/helpers';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

interface MarginProps {
  $margin?: boolean;
}

export const StyledFlex = styled.div<{ $pt?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: ${({ $pt }) => $pt && spacing.m};
  position: relative;
`;

export const MenuItem = styled.div<MarginProps>`
  align-items: center;
  color: ${colors.wibeDarkGrey};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: ${({ $margin }) => !!$margin && `${spacing.xs} 0`};
`;

interface MenuButtonProps {
  $borderLess?: boolean;
  $stretch?: boolean;
}

export const MenuButton = styled.button<MenuButtonProps>`
  ${buttonReset};
  align-items: center;
  border-bottom: ${({ $borderLess }) =>
    $borderLess ? 0 : `1px solid ${colors.grey3}`};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ $borderLess }) => ($borderLess ? 0 : spacing.s)};
  width: ${({ $stretch }) => ($stretch ? '100%' : 'auto')};
`;

export const StyledMenuLevel = styled.div`
  background: ${colors.white};
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s ease;
  width: 100%;
  z-index: 100;

  &.Hide-Right {
    transform: translate3d(100%, 0, 0);
  }

  &.Hide-Left {
    transform: translate3d(-100%, 0, 0);
  }
`;

export const MenuLevelHeader = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 64px;
  padding: 0 ${spacing.s};
`;

export const MenuLevelBackButton = styled.button`
  ${buttonReset};
  cursor: pointer;
`;

export const MenuLevelBody = styled.div`
  padding: ${spacing.m} ${spacing.s};
`;

export const MenuLevelCategoryWrapper = styled.div`
  margin: 0 0 ${spacing.m};
`;

export const MenuLevelCategoryLabel = styled.h4`
  color: ${colors.wibeDarkSteel};
  font-size: ${fontSizes.paragraph14};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeights.paragraph14};
  margin: 0 0 ${spacing.s};
  text-transform: uppercase;
`;
