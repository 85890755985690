import { useRef, useState } from 'react';

import clsx from 'clsx';

import {
  VideoThumbnailFragment,
  VideoUrlFragment,
} from '@hultafors/wibe/types';

import styles from './video.module.scss';

interface VideoProps {
  src?: VideoUrlFragment | null;
  thumbnail?: VideoThumbnailFragment | null;
  autoPlay?: boolean;
}

export const Video: React.FC<VideoProps> = ({ src, thumbnail, autoPlay }) => {
  const [paused, setPaused] = useState(true);
  const videoPlayer = useRef<HTMLVideoElement>(null);

  const handleOnPlay = () => {
    videoPlayer.current?.setAttribute('controls', 'true');
    setPaused(false);
  };

  function playVideo() {
    videoPlayer.current?.play();
    handleOnPlay();
  }

  if (!src?.url) {
    return null;
  }

  return (
    <div className={styles['container']}>
      <div className={styles['wrapper']}>
        <video
          className={styles['video']}
          ref={videoPlayer}
          poster={thumbnail?.url ?? ''}
          autoPlay={autoPlay}
          onPlay={handleOnPlay}
        >
          <source src={`${src.url}`} />
          <p>
            Your browser doesn&apos;t support HTML5 video. Here is a{' '}
            <a href={src.url}>link to the video</a> instead.
          </p>
        </video>
        <button
          className={clsx(styles['play'], paused && styles['visible'])}
          title="play"
          onClick={playVideo}
        >
          <svg
            className={styles['icon']}
            fill="none"
            viewBox="0 0 12 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m12 7-11.9999997 6.9282.00000061-13.8564036z" />
          </svg>
        </button>
      </div>
    </div>
  );
};
