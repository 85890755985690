import { spacing } from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';

import { Icon } from '../icon/icon';
import { ProductFeatureItem } from '../product-features/product-features.styled';

interface GoodWorkEnvironmentChoiceProps {
  className?: string;
}

export const GoodWorkEnvironmentChoice: React.FC<
  GoodWorkEnvironmentChoiceProps
> = ({ className = '' }) => {
  const { global } = useGlobal();

  return (
    <ProductFeatureItem
      as="span"
      className={className}
      $variant="good-work-environment-choice"
      style={{ padding: spacing.xxs }}
    >
      <Icon svg="good-work-environment-choice" />
      <span>{global?.goodEnvironmentChoice || ''}</span>
    </ProductFeatureItem>
  );
};
