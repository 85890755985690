import { useState } from 'react';

import { Button } from '../button/button';
import { Flex } from '../flex/flex';
import { GoodWorkEnvironmentChoice } from '../good-work-environment-choice/good-work-environment-choice';
import { Icon } from '../icon/icon';
import { Paragraph } from '../paragraph/paragraph';

import { StyledQuestionHelp } from './question-help.styled';

export const QuestionHelp: React.FC<QuestionHelpProps> = ({ text }) => {
  const [activeHelp, setActiveHelp] = useState(false);

  const toggleHelp = () => {
    setActiveHelp(!activeHelp);
  };
  return (
    <StyledQuestionHelp>
      <Button variant="transparent" onClick={toggleHelp}>
        <Icon svg="question-with-circle" />
      </Button>
      <div className={`HelpText ${activeHelp ? 'Active' : ''}`}>
        <Flex direction="column" style={{ margin: 0 }}>
          <Flex>
            <Button variant="transparent" onClick={toggleHelp}>
              <Icon svg="cross" />
            </Button>
          </Flex>
          <GoodWorkEnvironmentChoice />
          <Paragraph style={{ margin: 0 }}>{text}</Paragraph>
        </Flex>
      </div>
    </StyledQuestionHelp>
  );
};

interface QuestionHelpProps {
  text?: string;
}
