import { useMemo } from 'react';

import dynamic from 'next/dynamic';

import {
  Maybe,
  OfficeFragment,
  OfficesBlockFragment,
} from '@hultafors/wibe/types';

import { ContactStoresStyled, Label } from './contact-stores.styled';

const Accordion = dynamic(() =>
  import('../accordion/accordion').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('../accordion-item/accordion-item').then((mod) => mod.AccordionItem),
);
const StoreItem = dynamic(() =>
  import('../store-item/store-item').then((mod) => mod.StoreItem),
);

interface ContactStoresProps {
  offices?: OfficeFragment[];
  localWebLabel?: string;
  viewOnMapLabel?: string;
  salesOfficeLabel: string;
  exportMarketsLabel: string;
}

export const ContactStores: React.FC<
  ContactStoresProps | OfficesBlockFragment
> = ({
  localWebLabel,
  viewOnMapLabel,
  offices,
  salesOfficeLabel,
  exportMarketsLabel,
}) => {
  const generateGoogleMapLinks = (
    name?: Maybe<string>,
    address?: Maybe<string>,
    zipCode?: Maybe<string>,
  ) => {
    const url = new URL('https://www.google.com/maps/search/?api=1');
    url.searchParams.set(
      'query',
      [name, address, zipCode].filter(Boolean).join(' ').trim(),
    );
    return url.toString();
  };

  function itemMapper(item: OfficeFragment) {
    const key = `Store-${item.id}-${item.name}`;
    const mapLink = generateGoogleMapLinks(
      item.name,
      item.address,
      item.zipCode,
    );

    return (
      <AccordionItem key={key} label={item.name}>
        <StoreItem
          nameLabel={item.label || ''}
          address={item.address}
          zipCode={item.zipCode}
          city={item.city}
          mapLink={mapLink}
          viewOnMap={viewOnMapLabel}
          emailLabel={item.emailLabel}
          email={item.email}
          phone={item.phoneNumber}
          website={item.websiteUrl}
          visitWebSite={localWebLabel}
        />
      </AccordionItem>
    );
  }

  const exportOffices = useMemo(() => {
    return offices?.filter(
      ({ typeOfOffice }) => typeOfOffice === 'Export markets',
    );
  }, [offices]);

  const salesOffices = useMemo(() => {
    return offices?.filter(
      ({ typeOfOffice }) => typeOfOffice === 'Sales office',
    );
  }, [offices]);

  return (
    <ContactStoresStyled>
      {!!salesOffices?.length && (
        <div>
          <Label>{salesOfficeLabel}</Label>
          <Accordion allowZeroExpanded>
            {salesOffices.map(itemMapper)}
          </Accordion>
        </div>
      )}
      {!!exportOffices?.length && (
        <div>
          <Label>{exportMarketsLabel}</Label>
          <Accordion allowZeroExpanded>
            {exportOffices.map(itemMapper)}
          </Accordion>
        </div>
      )}
    </ContactStoresStyled>
  );
};
