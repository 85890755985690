import { Button } from '../button/button';
import { ButtonVariant } from '../button/button.styled';

import { LinkBlockStyled } from './link-block.styled';

interface LinkBlockProps {
  linkText?: string;
  linkUrl?: string;
  external?: boolean;
  buttonVariant?: ButtonVariant;
  style?: React.CSSProperties;
  square?: boolean;
}

export const LinkBlock: React.FC<LinkBlockProps> = ({
  style,
  buttonVariant = 'primary',
  linkText,
  linkUrl,
  square,
}) => {
  if (!linkUrl || !linkText) {
    return null;
  }
  return (
    <LinkBlockStyled style={style} $square={square}>
      <Button to={linkUrl} variant={buttonVariant}>
        {linkText}
      </Button>
    </LinkBlockStyled>
  );
};
