import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Label } from '../label/label';

import { StyledSelect, StyledSelectContainer } from './select.styled';

interface SelectProps {
  children?: React.ReactNode;
  label?: string;
  id?: string;
  inline?: boolean;
  options?: string[];
  selected?: any;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  wrapperProps?: any;
}

export const Select: React.FC<SelectProps> = ({
  children,
  label,
  id,
  inline,
  options = [],
  selected,
  onChange,
  wrapperProps,
}) => (
  <FieldWrapper inline={inline} {...wrapperProps}>
    <StyledSelectContainer>
      <Label htmlFor={id}>{label}</Label>
      <StyledSelect id={id} value={selected} onChange={onChange}>
        {children
          ? children // If children are provided, they will take precedence over the "options" prop
          : options.map((option: any) => (
              <option id={option} value={option} key={`select.${id}.${option}`}>
                {option}
              </option>
            ))}
      </StyledSelect>
    </StyledSelectContainer>
  </FieldWrapper>
);
