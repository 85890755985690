import { useEffect, useState } from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import { SubscriptionDialog } from '../subscription-dialog/subscription-dialog';

const TIMED_DIALOG_RENEW_DATE_LOCAL_STORAGE_KEY =
  'wibe_timed_dialog_renew_date';
const RENEW_INTERVAL = 6 * 30 * 24 * 3600 * 1000; // 6 MONTHS

interface TimedDialogProps {
  timedDialogData?: any;
}

export const TimedDialog: React.FC<TimedDialogProps> = ({
  timedDialogData,
}) => {
  const date = useLocalStorageValue<number>(
    TIMED_DIALOG_RENEW_DATE_LOCAL_STORAGE_KEY,
    { defaultValue: undefined, initializeWithValue: false },
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => setIsDialogOpen(false);
  useEffect(() => {
    if (timedDialogData) {
      if (!date.value || Date.now() > Number(date.value)) {
        setTimeout(() => {
          const now = Date.now();

          setIsDialogOpen(true);
          date.set(now + RENEW_INTERVAL);
        }, timedDialogData.delay * 1000);
      }
    }
  }, [timedDialogData]);

  return isDialogOpen ? (
    <SubscriptionDialog
      formUrl={timedDialogData.formUrl}
      image={timedDialogData.image}
      onHandleClose={closeDialog}
    />
  ) : null;
};
