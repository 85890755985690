import { Spacing } from '@hultafors/wibe/helpers';
import { PagePlugItem } from '@hultafors/wibe/types';

import { PagePlugs } from '../page-plugs/page-plugs';

import { Header, StyledSection } from './page-plugs-section.styled';

interface PagePlugsSectionProps {
  header?: string;
  plugs?: PagePlugItem[];
  mt?: Spacing;
  mb?: Spacing;
  className?: string;
}

export const PagePlugsSection: React.FC<PagePlugsSectionProps> = ({
  header,
  plugs = [],
  className,
}) => {
  if (!plugs.length) {
    return null;
  }
  return (
    <StyledSection className={className}>
      {header && <Header>{header}</Header>}
      <PagePlugs plugs={plugs} style={{ marginLeft: 0, marginRight: 0 }} />
    </StyledSection>
  );
};
