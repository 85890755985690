import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

export const BackContainerStyled = styled.div`
  margin: ${spacing.s};

  button,
  a {
    margin-block-end: ${spacing.m};
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: 0;

    hr {
      margin-block: ${spacing.l};
    }
  }
`;
