import { ListApiPaging } from '@hultafors/shared/types';

import { Button } from '../button/button';

import { Buttons, PagerInner, PagerStyled } from './pager.styled';

interface PagerProps {
  loadMore?(): void;
  pagerData?: ListApiPaging;
  loading?: boolean;
  showMoreText: string;
  ofText?: string;
  productsText?: string;
  itemCount?: number;
}

export const Pager: React.FC<PagerProps> = ({
  showMoreText,
  pagerData,
  loadMore,
  loading,
}) => {
  if (!pagerData) {
    return null;
  }
  const { itemCount, pageNumber, pageCount } = pagerData;
  if (!itemCount || pageNumber >= pageCount) {
    return null;
  }
  return (
    <PagerStyled>
      <PagerInner>
        <Buttons>
          <Button
            variant="secondary"
            loading={loading}
            onClick={loadMore}
            center
            className={loading ? 'Loading Outline' : 'Outline'}
          >
            {showMoreText}
          </Button>
        </Buttons>
      </PagerInner>
    </PagerStyled>
  );
};
