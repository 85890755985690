import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { Spacing, spacing } from '@hultafors/wibe/helpers';

type GapValue = number | Spacing;

interface ColumnGap {
  /* columns?: number; */
  breakpoint?: Breakpoint;
  columnGap: GapValue;
  /* start?: number; */
  /* display?: CSSProperties['display']; */
}

interface Column {
  columns?: number;
  breakpoint?: Breakpoint;
}

interface RowGap {
  breakpoint?: Breakpoint;
  rowGap: GapValue;
}

type ColumnAutoValue = 'auto-fit' | 'auto-fill';

export type GridColumnGapProp = ColumnGap[] | Spacing | number;

export type GridRowGapProp = RowGap[] | Spacing | number;

export type GridColumnsProp = Column[] | number | ColumnAutoValue;

interface StyledGridProps {
  $autoFlow?: CSSProperties['gridAutoFlow'];
  $align?: CSSProperties['alignItems'];
  $margin?: CSSProperties['margin'];
  $maxWidth?: CSSProperties['maxWidth'];
  $center?: boolean;
  $columnGap?: GridColumnGapProp;
  $rowGap?: GridRowGapProp;
  $columns?: GridColumnsProp;
  $minColumnWidth?: string;
}

const getColumns = ({ $columns, $minColumnWidth }: StyledGridProps) => {
  if (!Array.isArray($columns)) {
    const size = $minColumnWidth
      ? `minmax(${$minColumnWidth}, 1fr) `
      : 'minmax(0, 1fr)';
    return css`
      grid-template-columns: repeat(${$columns}, ${size});
    `;
  }
  return $columns.map(({ breakpoint, columns }) => {
    const value = css`
      grid-template-columns: repeat(${columns}, minmax(0, 1fr));
    `;
    if (!breakpoint) {
      return value;
    }
    return css`
      @media screen and (min-width: ${breakpoints[breakpoint]}) {
        ${value};
      }
    `;
  });
};

const getGapValue = (input: GapValue): string =>
  typeof input === 'number' ? `${input}px` : spacing[input];

const getRowGap = ({ $rowGap = 0 }: StyledGridProps) => {
  if (!Array.isArray($rowGap)) {
    return css`
      row-gap: ${getGapValue($rowGap)};
    `;
  }
  return $rowGap.map(({ breakpoint, rowGap }) => {
    const value = css`
      row-gap: ${getGapValue(rowGap)};
    `;
    if (!breakpoint) {
      return value;
    }
    return css`
      @media screen and (min-width: ${breakpoints[breakpoint]}) {
        ${value};
      }
    `;
  });
};
const getColumnGap = ({ $columnGap = 0 }: StyledGridProps) => {
  if (!Array.isArray($columnGap)) {
    return css`
      column-gap: ${getGapValue($columnGap)};
    `;
  }
  return $columnGap.map(({ breakpoint, columnGap }) => {
    const value = css`
      column-gap: ${getGapValue(columnGap)};
    `;
    if (!breakpoint) {
      return value;
    }
    return css`
      @media screen and (min-width: ${breakpoints[breakpoint]}) {
        ${value};
      }
    `;
  });
};
export const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-auto-flow: ${({ $autoFlow = 'inherit' }) => $autoFlow};
  place-items: ${({ $align = 'inherit' }) => $align}
    ${({ $align }) => ($align === 'flex-start' ? 'stretch' : $align)};
  justify-content: ${({ $center }) => ($center ? 'center' : 'initial')};
  margin: ${({ $margin = 'inherit' }) => $margin};
  max-width: ${({ $maxWidth = 'inherit' }) => $maxWidth};
  width: 100%;

  ${getColumns};
  ${getRowGap};
  ${getColumnGap};
`;
