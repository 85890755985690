import { useState } from 'react';

import { Paragraph } from '../paragraph/paragraph';
import { TextButton } from '../text-button/text-button';

import { ProductInformationTabsStyled } from './product-information-tabs.styled';

interface ProductInformationTabsProps {
  detailsLabel: string;
  documentsLabel: string;
  intro?: string;
  documents: any[];
  detailsInfo?: string;
}

export const ProductInformationTabs: React.FC<ProductInformationTabsProps> = ({
  detailsLabel,
  intro,
  documentsLabel,
  documents = [],
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const shouldRenderTabs = () => {
    let count = 0;
    if (intro) count++;
    if (haveDocuments()) count++;
    // if (detailsInfo) count++;
    return count > 1;
  };

  const renderTabs = () => {
    if (!shouldRenderTabs()) return null;
    return (
      <div className="Tabs">
        {intro && (
          <TextButton
            clean
            tab
            tabActive={1 === activeTab}
            onClick={() => setActiveTab(1)}
          >
            {detailsLabel}
          </TextButton>
        )}
        {haveDocuments() && (
          <TextButton
            clean
            tab
            tabActive={2 === activeTab}
            onClick={() => setActiveTab(2)}
          >
            {documentsLabel}
          </TextButton>
        )}
      </div>
    );
  };

  const haveDocuments = () => {
    return documents && documents.length > 0;
  };
  const renderDocuments = () => {
    return documents.map((document, i) => {
      return <p key={`document-${i}`}>{document.name}</p>;
    });
  };

  const renderTabInfo = () => {
    return (
      <>
        {intro && activeTab === 1 && (
          <Paragraph styleType="paragraph16" className="OverviewText">
            {intro}
          </Paragraph>
        )}
        {haveDocuments() && activeTab === 2 && renderDocuments()}
      </>
    );
  };

  return (
    <ProductInformationTabsStyled>
      {renderTabs()}
      {renderTabInfo()}
    </ProductInformationTabsStyled>
  );
};
