import { StyledFieldWrapper } from './field-wrapper.styled';

export interface FieldWrapperProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  inline?: boolean;
}

export const FieldWrapper: React.FC<FieldWrapperProps> = ({
  inline,
  children,
  style,
  className,
}) => {
  return (
    <StyledFieldWrapper $inline={inline} style={style} className={className}>
      {children}
    </StyledFieldWrapper>
  );
};
