import { Paragraph } from '../paragraph/paragraph';

import { StyledCheckboxFilter } from './checkbox-filter.styled';

interface CheckboxFilterProps {
  filterNode: any;
  selectedFilters: any[];
  filterChange(e: React.ChangeEvent, filterNode: any, child: any): void;
  isActive?: boolean;
}

export const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  filterNode,
  filterChange,
  selectedFilters,
  isActive,
}) => {
  const isEnumeratedSelected = (child: any) => {
    return selectedFilters.find((parent) => {
      return parent.children.find((sub: any) => {
        return sub.id === child.id;
      });
    })
      ? true
      : false;
  };

  return (
    <StyledCheckboxFilter>
      <div className={`SubFilters ${isActive ? 'Active' : ''}`}>
        {filterNode.children.map((child: any, childIndex: number) => (
          <div key={`SubFilter-${childIndex}`} className="SubFilter">
            <label className={isEnumeratedSelected(child) ? 'checked' : ''}>
              <input
                type="checkbox"
                onChange={(e) => filterChange(e, filterNode, child)}
                checked={isEnumeratedSelected(child)}
              />
              <Paragraph styleType="paragraph16">{child.name}</Paragraph>
            </label>
          </div>
        ))}
      </div>
    </StyledCheckboxFilter>
  );
};
