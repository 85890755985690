import { spacing } from '@hultafors/wibe/helpers';
import { TitleAndTextBlockFragment } from '@hultafors/wibe/types';

import { Box } from '../box/box';
import { Button } from '../button/button';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

import { StyledTitleAndTextBlock } from './title-and-text-block.styled';

interface TitleAndTextBlockProps {
  id?: string;
  title: string;
  description?: string;
  linkText?: string;
  linkUrl?: string;
}

export const TitleAndTextBlock: React.FC<
  TitleAndTextBlockProps | TitleAndTextBlockFragment
> = ({ title, description, linkUrl, linkText }) => {
  if (!title && !linkText && !linkUrl) {
    return null;
  }
  return (
    <StyledTitleAndTextBlock>
      <Box>
        <Grid rowGap="s">
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 12 },
              { breakpoint: 'desktop', columns: 4, start: 2 },
            ]}
          >
            <HTag
              type="h2"
              styleType={[
                { styleType: 'header3' },
                { breakpoint: 'desktop', styleType: 'header2' },
              ]}
            >
              {title}
            </HTag>
          </GridChild>
          {description && (
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 12 },
                { breakpoint: 'desktop', columns: 5, start: 7 },
              ]}
            >
              <Paragraph styleType="paragraph16">{description}</Paragraph>
              {linkText && linkUrl && (
                <Button
                  variant="rounded"
                  to={linkUrl}
                  style={{ marginTop: spacing.m }}
                >
                  {linkText}
                </Button>
              )}
            </GridChild>
          )}
        </Grid>
      </Box>
    </StyledTitleAndTextBlock>
  );
};
