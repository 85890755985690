import styled from 'styled-components';

import { fontSizes, spacing } from '@hultafors/wibe/helpers';

export const ProductInformationTabsStyled = styled.div`
  margin-bottom: ${spacing.m};

  .Tabs {
    display: flex;
    margin-block-end: ${spacing.l};
    text-align: center;

    &.Center {
      justify-content: center;
    }
  }

  button {
    padding: 0;
  }

  .Tab {
    margin-inline-end: ${spacing.m};
  }

  .OverviewText {
    margin: 0;
  }

  .DetailInfo {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      font-size: ${fontSizes.paragraph16};
    }
  }
`;
