import { Select } from '../select/select';
import { ToggleSwitch } from '../toggle-switch/toggle-switch';

import { StyledRetailersAdditionalSelections } from './retailers-additional-selections.styled';

interface RetailersAdditionalSelectionsProps {
  radiusAlternatives?: number[];
  radiusSelectChange?: React.ChangeEventHandler<HTMLSelectElement>;
  selectedRadius?: number;
  radiusHelpText?: string;
  internationalSearchToggleChange?: React.ChangeEventHandler<HTMLInputElement>;
  isRetailerSearchExpandable?: boolean;
  isSearchInternational?: boolean;
  otherCountriesIncluded?: string;
  style?: React.CSSProperties;
}

export const RetailersAdditionalSelections: React.FC<
  RetailersAdditionalSelectionsProps
> = ({
  radiusSelectChange,
  internationalSearchToggleChange,
  isRetailerSearchExpandable,
  isSearchInternational,
  selectedRadius,
  radiusAlternatives = [],
  radiusHelpText,
  otherCountriesIncluded,
  style,
}) => {
  const renderRadiusSelect = () => {
    return isRetailerSearchExpandable ? (
      <div className="RadiusSelectContainer">
        <Select
          id="radiusSelect"
          label={radiusHelpText}
          onChange={radiusSelectChange}
          selected={`${selectedRadius} km`}
          options={radiusAlternatives.map(
            (radiusAlternative) => `${radiusAlternative} km`,
          )}
        />
      </div>
    ) : null;
  };

  const renderInternationalSearchToggle = () => {
    if (isSearchInternational) return null;
    return (
      <ToggleSwitch
        className="InternationalSearchToggle"
        label={otherCountriesIncluded}
        checked={isSearchInternational}
        onChange={internationalSearchToggleChange}
      />
    );
  };

  return (
    <StyledRetailersAdditionalSelections style={style}>
      <div className="AdditionalSelections">
        {radiusAlternatives.length > 0 && renderRadiusSelect()}
        {renderInternationalSearchToggle()}
      </div>
    </StyledRetailersAdditionalSelections>
  );
};
