import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

import { GridChild } from '../grid-child/grid-child';
import { ImageBlock } from '../image-block/image-block';

export const Container = styled.div`
  margin: ${spacing.l} ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.xl} 0;
    padding: 0 ${spacing.s};
  }
`;

export const ImageGridChild = styled(GridChild).attrs({
  columnSpan: [
    { columns: 4 },
    { breakpoint: 'mobileMax', columns: 6 },
    { breakpoint: 'desktop', columns: 5, start: 2 },
  ],
})`
  overflow: hidden;
`;

export const ImageGridChild2 = styled(GridChild).attrs({
  columnSpan: [
    { columns: 4 },
    { breakpoint: 'mobileMax', columns: 6 },
    { breakpoint: 'desktop', columns: 5, start: 7 },
  ],
})`
  overflow: hidden;
`;

export const SingleImage = styled(ImageBlock)`
  height: auto;
  max-width: 100%;
  min-width: 100%;
`;
