import styled from 'styled-components';

import { colors, fontSizes, radii, spacing } from '@hultafors/wibe/helpers';

export const StyledCheckboxFilter = styled.div`
  .SubFilters {
    background-color: ${colors.white};
    min-inline-size: 220px;
    inset-block-start: 36px;
    z-index: 3;

    .SubFilter {
      cursor: pointer;

      &:not(:last-of-type) {
        margin-block-end: ${spacing.s};
      }

      span {
        font-size: ${fontSizes.paragraph10};
        letter-spacing: 0.04em;
      }

      input {
        visibility: hidden;
        inline-size: 0;
      }

      label {
        align-items: center;
        display: flex;
        position: relative;

        &::before {
          background-color: ${colors.grey1};
          border: thin solid ${colors.grey3};
          border-radius: ${radii.light};
          content: '';
          display: block;
          block-size: ${spacing.m};
          margin-inline-end: ${spacing.xs};
          position: relative;
          inline-size: ${spacing.m};
        }

        &.checked::before {
          background: url('/assets/gfx/check.svg');
          background-color: ${colors.grey1};
          background-position: center;
          background-repeat: no-repeat;
          background-size: 60%;
        }

        &:hover {
          color: ${colors.grey2};

          &::before {
            background-color: ${colors.grey2};
          }
        }
      }
    }
  }
`;
