import {
  BulletListFragment,
  CampaignInfoFragment,
  ContactCardFragment,
  DotDigitalFormFragment,
  GreenInfoBoxFragment,
  GreyInfoBoxFragment,
  IconBoxFragment,
  ImageBlockFragment,
  ImageSliderFragment,
  LargeHeaderFragment,
  QuoteFragment,
  TextBlockFragment,
  TwoImageBlockFragment,
  VideoFragment,
  WideTextBlockFragment,
} from '../dato/graphql-types';

export type DynamicContentField =
  | BulletListFragment
  | CampaignInfoFragment
  | ContactCardFragment
  | DotDigitalFormFragment
  | GreenInfoBoxFragment
  | GreyInfoBoxFragment
  | IconBoxFragment
  | ImageBlockFragment
  | ImageSliderFragment
  | LargeHeaderFragment
  | QuoteFragment
  | TextBlockFragment
  | TwoImageBlockFragment
  | VideoFragment
  | WideTextBlockFragment;

function isBulletList(item: DynamicContentField): item is BulletListFragment {
  if (!Object.prototype.hasOwnProperty.call(item, 'id')) {
    return false;
  }
  if (!Object.prototype.hasOwnProperty.call(item, 'name')) {
    return false;
  }
  if (!Object.prototype.hasOwnProperty.call(item, 'type')) {
    return false;
  }
  return true;
}

export const DynamicContentTypeGuards = {
  isBulletList: (item: DynamicContentField): item is BulletListFragment =>
    item.__typename === 'BulletListRecord',
  isCampaignInfo: (item: DynamicContentField): item is CampaignInfoFragment =>
    item.__typename === 'CampaignInfoRecord',
  isContactCard: (item: DynamicContentField): item is ContactCardFragment =>
    item.__typename === 'ContactCardRecord',
  isDotDigitalForm: (
    item: DynamicContentField,
  ): item is DotDigitalFormFragment =>
    item.__typename === 'DotDigitalFormRecord',
  isGreenInfoBox: (item: DynamicContentField): item is GreenInfoBoxFragment =>
    item.__typename === 'GreenInfoBoxRecord',
  isGreyInfoBox: (item: DynamicContentField): item is GreyInfoBoxFragment =>
    item.__typename === 'GreyInfoBoxRecord',
  isIconBox: (item: DynamicContentField): item is IconBoxFragment =>
    item.__typename === 'IconBoxRecord',
  isImageBlock: (item: DynamicContentField): item is ImageBlockFragment =>
    item.__typename === 'ImageRecord',
  isImageSlider: (item: DynamicContentField): item is ImageSliderFragment =>
    item.__typename === 'ImageSliderRecord',
  isLargeHeader: (item: DynamicContentField): item is LargeHeaderFragment =>
    item.__typename === 'LargeHeaderRecord',
  isQuote: (item: DynamicContentField): item is QuoteFragment =>
    item.__typename === 'QuoteRecord',
  isTextBlock: (item: DynamicContentField): item is TextBlockFragment =>
    item.__typename === 'TextBlockRecord',
  isTwoImageBlock: (item: DynamicContentField): item is TwoImageBlockFragment =>
    item.__typename === 'TwoImageBlockRecord',
  isVideo: (item: DynamicContentField): item is VideoFragment =>
    item.__typename === 'VideoRecord',
  isWideTextBlock: (item: DynamicContentField): item is WideTextBlockFragment =>
    item.__typename === 'WideTextBlockRecord',
};
