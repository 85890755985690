import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';
import { BigPlugFragment, ImageFragment } from '@hultafors/wibe/types';

import { Button } from '../button/button';
import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

import { Column, Container, ImageContainer } from './big-plug.styled';

interface BigPlugProps {
  image?: ImageFragment;
  title: string;
  description?: string;
  linkText: string;
  linkUrl: string;
}

export const BigPlug: React.FC<BigPlugProps | BigPlugFragment> = ({
  title,
  description,
  linkUrl,
  linkText,
  image,
}) => {
  if (!linkText && !linkUrl) {
    return null;
  }
  const sizes = [`(min-width: ${breakpoints.mobileMax}) 60vw`, '100vw'].join(
    ', ',
  );
  return (
    <Container>
      <ImageContainer>
        <Image
          src={image?.responsiveImage?.src || ''}
          alt={image?.alt || title || ''}
          placeholder={image?.responsiveImage?.base64 ? 'blur' : 'empty'}
          blurDataURL={image?.responsiveImage?.base64 || ''}
          sizes={sizes}
          fill
        />
      </ImageContainer>
      <Column>
        {title && (
          <HTag
            type="h2"
            styleType={[
              { styleType: 'header3' },
              { breakpoint: 'desktop', styleType: 'header1' },
            ]}
            style={{ marginBottom: spacing.s }}
          >
            {title}
          </HTag>
        )}
        {description && (
          <Paragraph styleType="paragraph16">{description}</Paragraph>
        )}
        {linkText && linkUrl && (
          <Button to={linkUrl} style={{ marginTop: spacing.m }}>
            {linkText}
          </Button>
        )}
      </Column>
    </Container>
  );
};
