import styled from 'styled-components';

import { colors, fontWeights, spacing } from '@hultafors/wibe/helpers';

export const StyledFilter = styled.div`
  .DropDownFilters {
    display: flex;
    flex-direction: column;
  }

  .Accordion > div {
    border-block-end: 1px solid ${colors.grey3};
    position: relative;

    .FilterType {
      padding-block-end: ${spacing.s};
    }

    button {
      margin-inline-end: ${spacing.m};
      padding: 0;

      p {
        font-weight: ${fontWeights.medium};
      }

      &.ChildSelected {
        span {
          position: relative;
        }

        span::before {
          background-color: #000;
          border-radius: 50%;
          inset-block-end: -10px;
          content: '';
          display: block;
          block-size: 4px;
          inset-inline-start: 50%;
          position: absolute;
          inline-size: 4px;
        }
      }
    }

    .CollapseContent.isOpen {
      padding-block-end: 20px;
    }

    .SubFilters {
      background-color: ${colors.white};
      min-inline-size: 220px;
      inset-block-start: 36px;
      z-index: 1;
    }
  }
`;
