import {
  Background,
  DialogContent,
  DialogWrapper,
  StyledTextButton,
} from './dialog.styled';

interface DialogProps {
  children?: React.ReactNode;
  closeButtonLabel: React.ReactNode;
  onClose?(): void;
  isFullscreen?: boolean;
  cover?: boolean;
  height?: string;
  width?: string;
  top?: string | number;
  right?: string | number;
  bottom?: string | number;
  left?: string | number;
}

export const Dialog: React.FC<DialogProps> = ({
  children,
  closeButtonLabel = 'Close',
  onClose,
  isFullscreen = true,
  cover,
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
  height,
  width,
}) => {
  return (
    <Background $cover={cover}>
      <DialogWrapper $isFullscreen={isFullscreen}>
        <StyledTextButton onClick={onClose}>
          {closeButtonLabel}
        </StyledTextButton>
        <DialogContent>{children}</DialogContent>
      </DialogWrapper>
    </Background>
  );
};
