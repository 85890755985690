import styled from 'styled-components';

import { lineHeights } from '@hultafors/wibe/helpers';

import { Markdown } from '../markdown/markdown';

export const Answer = styled(Markdown)`
  > * + * {
    margin-block-start: ${lineHeights.paragraph16};
  }
`;
