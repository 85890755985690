import { forwardRef, useState } from 'react';

import { AnimatePresence, m } from 'framer-motion';

import { useGlobal } from '@hultafors/wibe/hooks';

import { Icon } from '../icon/icon';
import { Input } from '../input/input';
import { Paragraph } from '../paragraph/paragraph';

import {
  ClearButton,
  SearchButton,
  SearchInputStyled,
} from './search-input.styled';

interface SearchInputProps {
  onChange: React.ChangeEventHandler;
  onSubmit: React.FormEventHandler;

  clearSearch?(e?: any): void;
  value: string;
  placeholder?: string;
  name?: string;
  iconUrl?: string;
  focusCallback?(focus?: any): void;
  className?: string;
  displaySearchMessage?: string;
  id: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  full?: boolean;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      name = 'SearchBox',
      placeholder = '',
      className = '',
      displaySearchMessage = '',
      onChange,
      clearSearch,
      onSubmit,
      value,
      iconUrl,
      focusCallback,
      id,
      style,
      children,
      onKeyDown,
      full,
    },
    ref,
  ) => {
    const { global } = useGlobal();
    const [, setAnimate] = useState(false);
    const [focus, setFocus] = useState(false);

    // const inputRef = useRef<HTMLInputElement>(null);
    // useImperativeHandle(ref, () => inputRef.current);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange(e);
      setAnimate(!!e.target.value);
    };

    const handleClearSearch: React.MouseEventHandler = (e) => {
      e.preventDefault();
      setAnimate(false);
      clearSearch?.(e);
    };

    const handleFocus = (focus: any) => {
      setFocus(focus);
      focusCallback?.(focus);
    };

    return (
      <SearchInputStyled className={className} style={style} $full={full}>
        <form
          name="search-form"
          onSubmit={onSubmit}
          autoComplete="off"
          className={focus ? 'focus' : ''}
        >
          <SearchButton
            type="submit"
            aria-label={global?.search || ''}
            disabled={!value.length}
          >
            <Icon svg="search" />
          </SearchButton>
          <Input
            value={value}
            id={id}
            aria-label={global?.search || ''}
            name={name}
            placeholder={placeholder}
            onChange={handleChange}
            onFocus={() => handleFocus(true)}
            onBlur={() => handleFocus(false)}
            onKeyDown={onKeyDown}
            ref={ref}
            wrapperStyle={{ margin: 0 }}
          />
          <AnimatePresence>
            {!!value.length && (
              <m.div
                initial={{ x: 44, y: 0 }}
                animate={{ x: 0, y: 0 }}
                exit={{ x: 44, y: 0 }}
                transition={{ duration: 0.2 }}
                // style={{ position: 'absolute', top: 0, right: 0, bottom: 0 }}
              >
                <ClearButton onClick={handleClearSearch}>
                  <Icon svg="cross-circle" />
                </ClearButton>
              </m.div>
            )}
          </AnimatePresence>

          {/* <ClearButton

          className={(animate ? 'animate ' : '') + ''.trim()}
          type="button"
          onClick={handleClearSearch}
        >
          <img
            src="/assets/gfx/close_circle_16.svg"
            alt="close icon"
            title="Close"
            width="16"
            height="16"
          />
        </Clear> */}
        </form>
        {children}
        {displaySearchMessage && (
          <Paragraph styleType="paragraph16" className="ResultText">
            {displaySearchMessage}
          </Paragraph>
        )}
      </SearchInputStyled>
    );
  },
);
// export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
//   RefSearchInput
// );
