import { Grid } from '../grid/grid';

import {
  Container,
  ImageGridChild,
  ImageGridChild2,
  SingleImage,
} from './dual-image.styled';

interface DualImageProps {
  imageOne: any;
  imageTwo: any;
}

export const DualImage: React.FC<DualImageProps> = ({ imageOne, imageTwo }) => {
  return (
    <Container>
      <Grid rowGap={16}>
        <ImageGridChild>
          <SingleImage src={imageOne.url} alt={imageOne.alt || ''} />
        </ImageGridChild>
        <ImageGridChild2>
          <SingleImage src={imageTwo.url} alt={imageTwo.alt || ''} />
        </ImageGridChild2>
      </Grid>
    </Container>
  );
};
