import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

import { DownloadFileBox } from '../download-file-box/download-file-box';
import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

export const StyledDocumentsContent = styled.div`
  margin-bottom: ${spacing.l};

  &:empty {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-end: ${spacing.xl};
  }
`;

export const BigTitle = styled(HTag).attrs({
  styleType: [
    { styleType: 'header4' },
    { breakpoint: 'mobileMax', styleType: 'header3' },
  ],
  type: 'h3',
})`
  margin-bottom: ${spacing.m};
  margin-top: ${spacing.l};
`;

export const Title = styled(Paragraph).attrs({
  styleType: 'paragraph16Medium',
})`
  margin-bottom: ${spacing.m};
  margin-top: ${spacing.m};
`;

export const StyledDownloadFileBox = styled(DownloadFileBox)`
  & + ${BigTitle} {
    margin-block-start: ${spacing.xl};
  }
  & + ${Title} {
    margin-block-start: ${spacing.l};
  }

  a {
    margin-block-end: ${spacing.s};
  }
`;
