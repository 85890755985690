import { Button } from '../button/button';
import { Flex } from '../flex/flex';
import { HighlightedFilters } from '../highlighted-filters/highlighted-filters';
import { Icon } from '../icon/icon';
import { SelectedFilterList } from '../selected-filter-list/selected-filter-list';

import {
  FilterButton,
  FilterButtonWrapper,
  HighlightAndButtonWrapper,
  SectionFilterToggle,
  SelectedFilterListAndButtonWrapper,
  StyledFilterSection,
} from './filter-section.styled';

interface FilterSectionProps {
  toggleFilter(): void;
  clearFilters(): void;
  allFiltersLabel: string;
  clearFiltersLabel: string;
  selectedFilters: any[];
  filters: any[];
  filterChange(): void;
  filtersToHighlight?: any[];
  fromLabel: string;
  toLabel: string;
  breakoutFilter?: any;
}

export const FilterSection: React.FC<FilterSectionProps> = ({
  filtersToHighlight = [],
  allFiltersLabel,
  clearFiltersLabel,
  clearFilters,
  toggleFilter,
  selectedFilters,
  filters,
  filterChange,
  fromLabel,
  toLabel,
  breakoutFilter,
}) => {
  const isFilterToggleSelected = () => {
    if (selectedFilters.length > 0 && breakoutFilter?.filter) {
      if (selectedFilters.find((x) => x.id === breakoutFilter.filter.id)) {
        return true;
      }
    }
    return false;
  };

  /**
   * Dont render component of we have no filters or selected filters. We may end up with selected filters but no filters
   * In this case we need to render the clear filters button
   */
  if (filters.length === 0 && selectedFilters.length === 0) {
    return null;
  }

  return (
    <StyledFilterSection>
      <Flex justify="space-between">
        <HighlightAndButtonWrapper>
          <HighlightedFilters
            filters={filtersToHighlight}
            selectedFilters={selectedFilters}
            filterChange={filterChange}
            fromLabel={fromLabel}
            toLabel={toLabel}
          />
          {filters.length > 0 && (
            <FilterButtonWrapper>
              <Button variant="transparent" onClick={toggleFilter}>
                <Icon svg="filter" />
                {allFiltersLabel}
              </Button>
            </FilterButtonWrapper>
          )}
        </HighlightAndButtonWrapper>
        <SectionFilterToggle
          filter={breakoutFilter.filter}
          helpText={breakoutFilter.helpText}
          filterChange={filterChange}
          isSelected={isFilterToggleSelected()}
        />
      </Flex>
      {selectedFilters.length > 0 && (
        <SelectedFilterListAndButtonWrapper>
          <Flex>
            <SelectedFilterList
              selectedFilters={selectedFilters}
              filters={filters}
              filterChange={filterChange}
            />
          </Flex>
          <Flex>
            {selectedFilters.length > 0 && (
              <FilterButtonWrapper>
                <FilterButton onClick={clearFilters} variant="transparent">
                  <Icon svg="trash" />
                  {clearFiltersLabel}
                </FilterButton>
              </FilterButtonWrapper>
            )}
          </Flex>
        </SelectedFilterListAndButtonWrapper>
      )}
    </StyledFilterSection>
  );
};
