import {
  StyledInput,
  StyledSliderSpan,
  StyledToggleSwitch,
  StyledToggleSwitchContainer,
  StyledToggleSwitchLabel,
} from './toggle-switch.styled';

interface ToggleSwitchProps {
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  onChange,
  label,
  className,
  style,
}) => (
  <StyledToggleSwitchContainer className={className} style={style}>
    {label && <StyledToggleSwitchLabel>{label}</StyledToggleSwitchLabel>}
    <StyledToggleSwitch>
      <StyledInput type="checkbox" checked={checked} onChange={onChange} />
      <StyledSliderSpan className="slider" />
    </StyledToggleSwitch>
  </StyledToggleSwitchContainer>
);
