import { QuestionFragment } from '@hultafors/wibe/types';

import { Answer } from './faq.styled';

interface FaqProps {
  questions: QuestionFragment[];
}
// TODO fix accordion
export const Faq: React.FC<FaqProps> = ({ questions }) => {
  const questionMapper = ({ id, title, description }: QuestionFragment) => {
    return (
      <article key={`Faq-Question-${id}`}>
        <h2>{title}</h2>
        <Answer>{description}</Answer>
      </article>
    );
  };
  return <div>{questions.map(questionMapper)}</div>;
};
