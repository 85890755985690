import { FooterFragment } from '@hultafors/wibe/types';

import { ImageBlock } from '../image-block/image-block';

import {
  BackDrop,
  DialogContent,
  ImageWrapper,
  StyledDialog,
  StyledIFrameContainer,
} from './subscription-dialog.styled';

export const SubscriptionDialog: React.FC<SubscriptionDialogProps> = ({
  formUrl,
  image,
  onHandleClose,
}) => {
  const { url: imageUrl, title: imageTitle } = image || {};

  return (
    <>
      <BackDrop />
      <StyledDialog
        isFullscreen={false}
        onClose={onHandleClose}
        closeButtonLabel={
          <ImageBlock src="/assets/gfx/close_24.svg" alt="Close Icon" />
        }
      >
        <DialogContent>
          {imageUrl && (
            <ImageWrapper>
              <ImageBlock src={imageUrl} alt={imageTitle || ''} />
            </ImageWrapper>
          )}
          <StyledIFrameContainer title="dialog-form" src={formUrl} />
        </DialogContent>
      </StyledDialog>
    </>
  );
};

interface SubscriptionDialogProps {
  formUrl?: string;
  image?: FooterFragment['userEmailFormImage'];
  onHandleClose?(): void;
}
