import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Label } from '../label/label';

import { StyledTextarea } from './textarea.styled';

interface TextareaProps {
  id: string;
  inline?: boolean;
  label: string;
  wrapperProps?: any;
}

export const Textarea: React.FC<TextareaProps> = ({
  label,
  id,
  inline,
  wrapperProps,
}) => (
  <FieldWrapper inline={inline} {...wrapperProps}>
    <Label htmlFor={id}>{label}</Label>
    <StyledTextarea id={id} />
  </FieldWrapper>
);
