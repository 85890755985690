import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { HTag } from '../h-tag/h-tag';

export const StyledVisibleSeoBlock = styled.section`
  background-color: ${colors.grey1};
  padding: ${spacing.l};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: ${spacing.xl};
  }
`;

export const TextWrapper = styled.div`
  /* max-width: 0px; */

  /* line-break: loose; */
  @media screen and (min-width: ${breakpoints.mobileMax}) {
    max-inline-size: 550px;
  }

  widows: 3;
  orphans: 3;
`;

export const Title = styled(HTag).attrs({
  styleType: 'header3',
  type: 'h2',
})`
  margin-bottom: ${spacing.m};
`;

export const Description = styled(Markdown).attrs({
  options: { forceBlock: true },
})``;
