import { useMemo, useState } from 'react';

import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';

import { useGlobal } from '@hultafors/wibe/hooks';

import { HTag } from '../h-tag/h-tag';
import { Icon } from '../icon/icon';
import { MenuLevel } from '../menu-level/menu-level';
import { MenuPlugs } from '../menu-plugs/menu-plugs';
import { Paragraph } from '../paragraph/paragraph';
import { PartnerLoginLink } from '../partner-login-link/partner-login-link';

import {
  Flex,
  MenuButton,
  MenuItem,
  MenuStyled,
  SiteLogoWrapper,
} from './menu.styled';

const SlideOutHeader = dynamic(
  () =>
    import('@hultafors/shared/components').then((mod) => mod.SlideOutHeader),
  { ssr: false },
);

const SlideOut = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.SlideOut),
  { ssr: false },
);

const ClearButton = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.ClearButton),
);

function hasChildren(menuItem: any) {
  return 'children' in menuItem && (menuItem.children || []).length > 0;
}

function recursivelyFlattenItemsToMenuLevels(items: any[], depth = 0) {
  let levels: any[] = [];

  items.forEach((item) => {
    if (hasChildren(item)) {
      levels = levels.concat(
        [
          {
            ...item,
            depth,
          },
        ],
        recursivelyFlattenItemsToMenuLevels(item.children, depth + 1),
      );
    }
  });

  return levels;
}

export type Direction = 'forward' | 'backward';

interface MenuProps {
  toggleMenu(): void;
  items?: any[];
  menuType?: string;
  isOpen: boolean;
  secondaryProductMenu?: any[];
  partnerPortalLabel?: string;
  urlToPartnerPortal: string;
  documentsPart?: any[];
}

export const Menu: React.FC<MenuProps> = ({
  isOpen,
  items = [],
  toggleMenu,
  secondaryProductMenu,
  documentsPart,
  partnerPortalLabel,
  urlToPartnerPortal,
  menuType,
}) => {
  const { global } = useGlobal();
  const [menuState, setMenuState] = useState<{
    currentLevel: number;
    items: any[];
  }>({
    currentLevel: 0,
    items: [],
  });

  const toggleSubMenu = (menuItem: any, direction: Direction = 'forward') => {
    switch (direction) {
      case 'backward':
        setMenuState({
          currentLevel: menuState.currentLevel - 1,
          // slice returns a new array, preserving immutability
          items: menuState.items.slice(0, -1),
        });
        break;
      case 'forward':
      default:
        setMenuState({
          currentLevel: menuState.currentLevel + 1,
          items: [...menuState.items, menuItem],
        });
        break;
    }
  };

  /**
   * Parse all menu items to get the list of menu levels to render
   * (All levels having children are rendered, hidden in CSS for a transition to work)
   *
   * NB: The structure of "<SlideIn />" prevents to transition the root level in the same way at the moment,
   * sublevels of depth 1 will slide on top of it
   */
  const allLevels = useMemo(
    () => recursivelyFlattenItemsToMenuLevels(items),
    [items],
  );

  const closeMenu = () => toggleMenu();
  const header = (
    <SlideOutHeader
      onClick={closeMenu}
      closeLabel={global.close ?? ''}
      position="inline-start"
    >
      {global?.siteLogo?.url && (
        <SiteLogoWrapper>
          <Image
            src={global.siteLogo.url}
            alt={global.siteLogo?.alt ?? 'Site Logo'}
            fill
          />
        </SiteLogoWrapper>
      )}
    </SlideOutHeader>
  );

  return (
    <SlideOut
      isOpen={isOpen}
      toggle={toggleMenu}
      header={header}
      position="inline-start"
    >
      <MenuStyled>
        {/* <SlideIn
        toggle={toggleMenu}
        isOpen={isOpen}
        isMenu
        isFilter
        padContent
        fromLeft={false}
      > */}
        <Flex>
          {items.map((menuItem) => {
            return hasChildren(menuItem) ? (
              <MenuItem
                key={`menu-item.${menuItem.id}.${menuItem.title}`}
                $margin
              >
                <MenuButton
                  onClick={() => toggleSubMenu(menuItem, 'forward')}
                  $stretch
                >
                  <HTag type="h3" styleType="header3">
                    {menuItem.title}
                  </HTag>
                  <Icon svg="xl-arrow-right" />
                </MenuButton>
              </MenuItem>
            ) : (
              <MenuItem $margin>
                <Link
                  href={menuItem.url}
                  key={`menu-item.${menuItem.id}.${menuItem.title}`}
                >
                  <Paragraph as="span" styleType="paragraph16">
                    {menuItem.title}
                  </Paragraph>
                </Link>
              </MenuItem>
            );
          })}
          {secondaryProductMenu && <MenuPlugs plugs={secondaryProductMenu} />}
          {partnerPortalLabel && urlToPartnerPortal && (
            <PartnerLoginLink
              partnerPortalLabel={partnerPortalLabel}
              urlToPartnerPortal={urlToPartnerPortal}
            />
          )}
        </Flex>
        {allLevels.map((menuItem) => (
          <MenuLevel
            key={`menu-level.${menuItem.id}.${menuItem.title}`}
            menuItem={menuItem}
            menuState={menuState}
            toggleMenu={toggleMenu}
            toggleSubMenu={toggleSubMenu}
            documentsPart={documentsPart}
          />
        ))}
        {/* </SlideIn> */}
      </MenuStyled>
    </SlideOut>
  );
};
