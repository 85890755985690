import { StyledH2 } from './h2.styled';

interface H2Props {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  center?: boolean;
}

export const H2: React.FC<H2Props> = ({
  children,
  center,
  className,
  style,
}) => {
  return (
    <StyledH2 $center={center} className={className} style={style}>
      {children}
    </StyledH2>
  );
};
