import { useEffect, useState } from 'react';

import { spacing } from '@hultafors/wibe/helpers';

import { Paragraph } from '../paragraph/paragraph';
import { QuestionHelp } from '../question-help/question-help';
import { ToggleSwitch } from '../toggle-switch/toggle-switch';

import { StyledFilterToggle } from './filter-toggle.styled';

interface FilterToggleProps {
  filter?: any;
  helpText?: string;
  filterChange(a: any, b?: any): void;
  isSelected?: boolean;
  style?: React.CSSProperties;
}

export const FilterToggle: React.FC<FilterToggleProps> = ({
  filter,
  helpText,
  filterChange,
  isSelected,
  style,
}) => {
  const [toggleChecked, setToggleChecked] = useState(isSelected);

  const toggleChange = () => {
    if (filter.children.length > 0) {
      setToggleChecked(!toggleChecked);
      filterChange(filter, filter?.children?.[0]);
    }
  };

  useEffect(() => {
    if (isSelected !== toggleChecked) {
      setToggleChecked(isSelected);
    }
  }, [isSelected]);

  if (!filter) {
    return null;
  }
  return (
    <StyledFilterToggle style={style}>
      <Paragraph styleType="paragraph16">{filter.name}</Paragraph>
      <ToggleSwitch
        style={{ marginLeft: spacing.xs }}
        checked={toggleChecked}
        onChange={toggleChange}
      />
      <QuestionHelp text={helpText} />
    </StyledFilterToggle>
  );
};
