import { breakpoints } from '@hultafors/shared/constants';

import { createDetailPageUrl, renderPrice } from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';
import { WibeProduct } from '@hultafors/wibe/types';

import { GoodWorkEnvironmentChoice } from '../good-work-environment-choice/good-work-environment-choice';
import { Icon } from '../icon/icon';
import { Paragraph } from '../paragraph/paragraph';
import { ProductFeatures } from '../product-features/product-features';
import { ProductImage } from '../product-image/product-image';
import { RouterLink } from '../router-link/router-link';

import {
  Favorite,
  ImageWrapper,
  ProductPrice,
  ProductStyled,
  UspList,
} from './product.styled';

export interface ProductProps {
  product: WibeProduct;
  isFavorite?: boolean;
  heightLabel?: string;
  toggleMenu?(): void;
  routerState?: any;
  usp?: string[];
  onClick?: React.MouseEventHandler;
  className?: string;
  badgeText?: string;
  rrpLabel?: string;
  rrpIncludingVatLabel?: string;
  multipleHeightsLabel?: string;
  sizes?: string;
  url?: string;
  noHover?: boolean;
  noMargin?: boolean;
  isSmall?: boolean;
  inFavorites?: boolean;
}

export const Product: React.FC<ProductProps> = ({
  usp = [],
  className,
  noHover,
  noMargin,
  isSmall,
  inFavorites,
  product,
  sizes = [
    `(min-width: ${breakpoints.desktopLarge}) 382px`,
    `(min-width: ${breakpoints.desktop}) calc((100vw - 296px) / 3)`,
    `(min-width: ${breakpoints.tablet}) calc((100vw - 208px) / 2)`,
    'calc(100vw - 98px)',
  ].join(', '),
  url: forcedUrl,
}) => {
  const { global } = useGlobal();
  // TODO set correct category
  const url = forcedUrl || createDetailPageUrl(product);

  return (
    <ProductStyled
      className={className}
      $noHover={noHover}
      $noMargin={noMargin}
      $isSmall={isSmall}
      $inFavorites={inFavorites}
    >
      <ImageWrapper>
        <ProductImage product={product} sizes={sizes} />
        <Favorite product={product} />
        <ProductFeatures
          features={product.brandSegments}
          isNew={product.isNew}
          newLabel={global?.new || ''}
          co2Label={global?.co2Label || ''}
          showCo2Label={product.isReducedCo2 && global?.showCo2Label}
          showAluminiumLabel={
            product.isRecycledAluminium && global?.showAluminiumLabel
          }
          aluminiumLabel={global?.aluminiumLabel || ''}
          absolute={true}
          direction="column"
        />
        {product.measurement?.min &&
          product.measurement.max &&
          product.measurement.min !== product.measurement.max && (
            <Paragraph styleType="paragraph12" className="HeightInformation">
              <Icon svg="up-down-arrow" />
              {`${global?.multipleHeights || ''} (${product.measurement.min}-${
                product.measurement.max
              } ${product.measurement.unit})`}
            </Paragraph>
          )}

        {product.measurement?.min &&
          product.measurement.max &&
          product.measurement.min === product.measurement.max && (
            <Paragraph styleType="paragraph12" className="HeightInformation">
              <Icon svg="up-down-arrow" />
              {`${global?.height || ''}: ${product.measurement.min} ${
                product.measurement.unit
              }`}
            </Paragraph>
          )}
      </ImageWrapper>

      <div className="ProductInfo">
        <RouterLink className="ProductName" to={url}>
          <Paragraph styleType="paragraph16Medium" color="wibeDarkGrey">
            {product.name}
          </Paragraph>
          {product.price?.value.min && product.price.value.max ? (
            <ProductPrice>
              <Paragraph styleType="paragraph14">
                {renderPrice(
                  product.price.value.min,
                  product.price.value.max,
                  product.price.currency || '',
                )}
              </Paragraph>
              <Paragraph styleType="paragraph10" className="rrp">
                {global.rrpLabel || ''}
              </Paragraph>
            </ProductPrice>
          ) : product.priceWithVat?.value.min &&
            product.priceWithVat.value.max ? (
            <ProductPrice>
              <Paragraph styleType="paragraph14">
                {renderPrice(
                  product.priceWithVat.value.min,
                  product.priceWithVat.value.max,
                  product.priceWithVat.currency || '',
                )}
              </Paragraph>
              <Paragraph styleType="paragraph10" className="rrp">
                {global.rrpIncludingVatLabel || ''}
              </Paragraph>
            </ProductPrice>
          ) : null}
          {product.isWorkingEnvironment && <GoodWorkEnvironmentChoice />}
          {/* {hasGoodEnvironmentChoice(product.brandSegments) && <GoodWorkEnvironmentChoice />} */}
          {!!usp?.length && (
            <ul style={{ marginBottom: 0, marginTop: 12, paddingLeft: 15 }}>
              {usp.map((uspEntry, index) => (
                <Paragraph
                  as={UspList}
                  key={`${product.productId}-${index}`}
                  styleType="paragraph12"
                >
                  {uspEntry}
                </Paragraph>
              ))}
            </ul>
          )}
        </RouterLink>
      </div>
    </ProductStyled>
  );
};
