import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';
import { buttonReset } from '@hultafors/shared/helpers';

import { colors, radii, spacing } from '@hultafors/wibe/helpers';

export const InnerModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ChildrenWrapper = styled.div`
  width: 100%;
  max-height: 100vh;
  position: relative;
  height: auto;
`;

export const CloseButton = styled.button`
  ${buttonReset};
  align-self: flex-end;
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  &:hover {
    opacity: 0.5;
  }
`;

export const ModalStyled = styled.div`
  width: 100vw;
  height: 100vh;
  inset: 0;
  position: relative;
  background-color: ${colors.white};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    max-inline-size: calc(100vw - 2 * ${spacing.s});
    max-block-size: calc(100vh - 2 * ${spacing.s});
    border: thin solid ${colors.grey3};
    border-radius: ${radii.rounded};
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    max-inline-size: calc(${breakpoints.desktopSmall} - 2 * ${spacing.s});
  }
`;

export const OverlayStyled = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgb(255 255 255 / 85%);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
`;
