import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

import { HTag } from '../h-tag/h-tag';
import { Section } from '../section/section';

export const StyledSection = styled(Section)`
  margin-bottom: ${spacing.l};
  margin-top: ${spacing.l};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block: ${spacing.xl};
  }
`;

export const Header = styled(HTag).attrs({
  styleType: 'header3',
  type: 'h2',
})`
  margin-top: 'auto';
  margin-bottom: ${spacing.l};
`;
