import { CSSProperties, useMemo } from 'react';

import ArrowLeft24 from '../../svg/arrow-left-24.svg';
import Bookmark24Active from '../../svg/bookmark-24-active.svg';
import Bookmark24 from '../../svg/bookmark-24.svg';
import Bookmark from '../../svg/bookmark.svg';
import BreadcrumbArrow from '../../svg/breadcrumb-arrow.svg';
import ChevronDown from '../../svg/chevron-down.svg';
import ChevronLeft from '../../svg/chevron-left.svg';
import ChevronRight from '../../svg/chevron-right.svg';
import ChevronUp from '../../svg/chevron-up.svg';
import Close24 from '../../svg/close-24.svg';
import Cross24 from '../../svg/cross-24.svg';
import CrossCircle from '../../svg/cross-circle.svg';
import Cross from '../../svg/cross.svg';
import Download from '../../svg/download.svg';
import ExternalLink from '../../svg/external-link.svg';
import Favorite from '../../svg/favorite.svg';
import File24 from '../../svg/file-24.svg';
import File from '../../svg/file.svg';
import Filter from '../../svg/filter.svg';
import GoodWorkEnvironmentChoice from '../../svg/good-work-environment-choice.svg';
import Hamburger from '../../svg/hamburger.svg';
import Info from '../../svg/info.svg';
import Language from '../../svg/language.svg';
import LargeArrowLeft from '../../svg/large-arrow-left.svg';
import LargeArrowRight from '../../svg/large-arrow-right.svg';
import Loader from '../../svg/loader.svg';
import Mail from '../../svg/mail.svg';
import Minus from '../../svg/minus.svg';
import Models from '../../svg/models.svg';
import PartnerLogin24 from '../../svg/partner-login-24.svg';
import PartnerLogin from '../../svg/partner-login.svg';
import Phone from '../../svg/phone.svg';
import PlaceTag from '../../svg/place-tag.svg';
import Play24 from '../../svg/play-24.svg';
import Play48 from '../../svg/play-48.svg';
import Play56 from '../../svg/play-56.svg';
import PlayWithCircle from '../../svg/play-with-circle.svg';
import Plus24 from '../../svg/plus-24.svg';
import Plus from '../../svg/plus.svg';
import QuestionWithCircle from '../../svg/question-with-circle.svg';
import ReducedAluminium from '../../svg/reduced_aluminium.svg';
import ReducedCo2 from '../../svg/reduced_co2.svg';
import Search24 from '../../svg/search-24.svg';
import Search from '../../svg/search.svg';
import TextLogo from '../../svg/text-logo.svg';
import Trash from '../../svg/trash.svg';
import UpDownArrow from '../../svg/up-down-arrow.svg';
import XlArrowLeft from '../../svg/xl-arrow-left.svg';
import XlArrowRight from '../../svg/xl-arrow-right.svg';
import Zoom from '../../svg/zoom.svg';

type IconSvg =
  | 'arrow-left-24'
  | 'bookmark-24-active'
  | 'bookmark-24'
  | 'bookmark'
  | 'breadcrumb-arrow'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'close-24'
  | 'cross-24'
  | 'cross-circle'
  | 'cross'
  | 'download'
  | 'external-link'
  | 'favorite'
  | 'file-24'
  | 'file'
  | 'filter'
  | 'good-work-environment-choice'
  | 'hamburger'
  | 'info'
  | 'language'
  | 'large-arrow-left'
  | 'large-arrow-right'
  | 'loader'
  | 'mail'
  | 'minus'
  | 'models'
  | 'partner-login-24'
  | 'partner-login'
  | 'phone'
  | 'place-tag'
  | 'play-24'
  | 'play-48'
  | 'play-56'
  | 'play-with-circle'
  | 'plus-24'
  | 'plus'
  | 'question-with-circle'
  | 'reduced_co2'
  | 'reduced_aluminium'
  | 'search-24'
  | 'search'
  | 'text-logo'
  | 'trash'
  | 'up-down-arrow'
  | 'xl-arrow-left'
  | 'xl-arrow-right'
  | 'zoom';

const icons16: IconSvg[] = [
  'bookmark',
  'breadcrumb-arrow',
  'cross',
  'cross-circle',
  'file',
  'loader',
  'partner-login',
  'plus',
  'search',
  'zoom',
];

const icons40: IconSvg[] = ['xl-arrow-left', 'xl-arrow-right'];

const getDefaultSize = (
  svg?: IconSvg | null,
): { width: number; height?: number } => {
  if (!svg) {
    return { height: 24, width: 24 };
  }
  if (svg === 'loader') {
    return { height: 30, width: 120 };
  }
  if (svg === 'models') {
    return { height: 25, width: 25 };
  }
  if (svg === 'play-48') {
    return { height: 48, width: 48 };
  }
  if (svg === 'play-56') {
    return { height: 56, width: 56 };
  }
  if (svg === 'text-logo') {
    return { height: 40, width: 99 };
  }
  if (svg === 'reduced_aluminium') {
    return { height: 70, width: 70 };
  }
  if (svg === 'reduced_co2') {
    return { height: 70, width: 70 };
  }
  if (svg === 'arrow-left-24') {
    return { height: 46, width: 24 };
  }
  if (svg === 'good-work-environment-choice') {
    return { height: 14, width: 14 };
  }
  if (icons40.includes(svg)) {
    return { height: 40, width: 40 };
  }
  if (icons16.includes(svg)) {
    return { height: 16, width: 16 };
  }
  return { height: 24, width: 24 };
};

interface IconProps extends React.SVGProps<SVGSVGElement> {
  svg?: IconSvg;
  title?: string;
  rotation?: number;
}
export const Icon: React.FC<IconProps> = ({
  svg,
  width,
  height,
  style,
  title,
  rotation,
  ...rest
}) => {
  const SvgComponent = useMemo(() => {
    switch (svg) {
      case 'arrow-left-24':
        return ArrowLeft24;
      case 'bookmark-24-active':
        return Bookmark24Active;
      case 'bookmark-24':
        return Bookmark24;
      case 'bookmark':
        return Bookmark;
      case 'breadcrumb-arrow':
        return BreadcrumbArrow;
      case 'chevron-down':
        return ChevronDown;
      case 'chevron-left':
        return ChevronLeft;
      case 'chevron-right':
        return ChevronRight;
      case 'chevron-up':
        return ChevronUp;
      case 'close-24':
        return Close24;
      case 'cross-24':
        return Cross24;
      case 'cross-circle':
        return CrossCircle;
      case 'cross':
        return Cross;
      case 'download':
        return Download;
      case 'external-link':
        return ExternalLink;
      case 'favorite':
        return Favorite;
      case 'file-24':
        return File24;
      case 'file':
        return File;
      case 'filter':
        return Filter;
      case 'good-work-environment-choice':
        return GoodWorkEnvironmentChoice;
      case 'hamburger':
        return Hamburger;
      case 'info':
        return Info;
      case 'language':
        return Language;
      case 'large-arrow-left':
        return LargeArrowLeft;
      case 'large-arrow-right':
        return LargeArrowRight;
      case 'loader':
        return Loader;
      case 'mail':
        return Mail;
      case 'minus':
        return Minus;
      case 'models':
        return Models;
      case 'partner-login-24':
        return PartnerLogin24;
      case 'partner-login':
        return PartnerLogin;
      case 'phone':
        return Phone;
      case 'place-tag':
        return PlaceTag;
      case 'play-24':
        return Play24;
      case 'play-48':
        return Play48;
      case 'play-56':
        return Play56;
      case 'play-with-circle':
        return PlayWithCircle;
      case 'plus-24':
        return Plus24;
      case 'plus':
        return Plus;
      case 'question-with-circle':
        return QuestionWithCircle;
      case 'reduced_co2':
        return ReducedCo2;
      case 'reduced_aluminium':
        return ReducedAluminium;
      case 'search-24':
        return Search24;
      case 'search':
        return Search;
      case 'text-logo':
        return TextLogo;
      case 'trash':
        return Trash;
      case 'up-down-arrow':
        return UpDownArrow;
      case 'xl-arrow-left':
        return XlArrowLeft;
      case 'xl-arrow-right':
        return XlArrowRight;
      case 'zoom':
        return Zoom;
      default:
        return null;
    }
  }, [svg]);

  if (!SvgComponent) {
    return null;
  }

  const defaultSize = getDefaultSize(svg);

  const iconStyle: CSSProperties = {
    ...style,
  };

  if (typeof rotation === 'number') {
    iconStyle.transform = `rotate(${rotation}deg)`;
  }

  return (
    <SvgComponent
      aria-hidden={true}
      focusable={false}
      width={width ?? defaultSize.width}
      height={height ?? width ?? defaultSize.height ?? defaultSize.width}
      style={iconStyle}
      title={title}
      {...rest}
    />
  );
};
