import { EmptyProductStyled } from './empty-product.styled';

export const EmptyProduct: React.FC = () => (
  <EmptyProductStyled>
    <div className="ImagePlaceholder" />
    <div className="ProductInfoPlaceholder">
      <span />
      <span />
    </div>
  </EmptyProductStyled>
);
