import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

export type TextButtonVariant = 'black' | 'white';

interface TextButtonStyledProps {
  $tab?: boolean;
  $tabActive?: boolean;
  $small?: boolean;
  $leftIcon?: boolean;
  $arrow?: boolean;
  $space?: boolean;
  $clean?: boolean;
  $loading?: boolean;
  $variant?: TextButtonVariant;
  $iconSize?: number;
  $icon?: React.ReactNode;
}

export const TextButtonStyled = styled.button<TextButtonStyledProps>`
  /* Reset */
  appearance: none;
  border: none;
  background-color: transparent;

  /* Styles */
  display: inline-flex;
  align-items: center;
  height: ${({ $tab }) => ($tab ? '32px' : spacing.m)};
  border-bottom: ${({ $tab, $tabActive }) =>
    $tab
      ? $tabActive
        ? `2px solid ${colors.wibeYellow}`
        : '2px solid transparent'
      : null};
  color: ${({ $variant = 'black', $tab }) =>
    $tab
      ? colors.wibeDarkGrey
      : $variant === 'black'
      ? colors.systemBlack
      : colors.white};
  text-decoration: none;
  padding: ${spacing.xs} 0;
  cursor: pointer;
  transition: 0.2s;

  /* Text style */
  font-size: ${({ $small }) =>
    $small ? fontSizes.paragraph12 : fontSizes.paragraph16};
  line-height: ${({ $small }) =>
    $small ? lineHeights.$paragraph12 : lineHeights.paragraph16};
  font-weight: ${({ $tab }) =>
    $tab ? fontWeights.medium : fontWeights.regular};
  gap: ${spacing.xs};

  /* In case of tab */
  &:not(:last-child) {
    margin-inline-end: ${({ $tab }) => ($tab ? spacing.m : null)};
  }

  /* In case of Icon */
  svg,
  img {
    inline-size: ${({ $iconSize }) => `${$iconSize}px`};
    block-size: ${({ $iconSize }) => `${$iconSize}px`};
    transition: 0.2s;
  }

  svg {
    path,
    circle,
    line {
      fill: ${({ $variant }) =>
        $variant === 'black' ? colors.systemBlack : colors.white};
    }
  }

  ${({ $space }) =>
    $space &&
    css`
      margin: 20px 0;
    `}

  :hover {
    color: ${({ $variant, $arrow }) =>
      !$arrow && ($variant === 'black' ? colors.grey4b : colors.grey2)};

    svg {
      * {
        fill: ${({ $variant, $arrow }) =>
          !$arrow && ($variant === 'black' ? colors.grey4b : colors.grey2)};
      }
    }
  }

  ${({ $icon, $arrow, $clean }) =>
    !$icon &&
    !$arrow &&
    !$clean &&
    css`
      text-decoration: underline;
    `}

  ${({ $arrow, $leftIcon }) =>
    $arrow &&
    css`
      font-size: ${fontSizes.paragraph14};
      line-height: ${lineHeights.paragraph14};
      font-weight: ${fontWeights.medium};

      img {
        inline-size: fit-content;
        block-size: fit-content;
      }

      &:hover {
        img {
          transform: ${$leftIcon
            ? `translateX(-${spacing.xs})`
            : `translateX(${spacing.xs})`};
        }
      }
    `}
`;
