import styled, { css, keyframes } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  spacing,
} from '@hultafors/wibe/helpers';

export type ButtonVariant =
  | 'primary'
  | 'primary-dark'
  | 'secondary'
  | 'rounded'
  | 'small'
  | 'transparent';

interface StyledButtonProps {
  $loading?: boolean;
  $variant?: ButtonVariant;
  $center?: boolean;
}

const getColor = (variant?: ButtonVariant) => {
  switch (variant) {
    case 'primary':
      return css`
        background-color: ${colors.wibeYellow};
        color: ${colors.systemBlack};

        &:hover {
          background-color: ${colors.wibeYellowHover};
        }
      `;
    case 'primary-dark':
      return css`
        background-color: ${colors.wibeDarkGrey};
        color: ${colors.wibeYellow};

        svg path {
          fill: ${colors.wibeYellow};
        }

        &:hover {
          background-color: ${colors.wibeDarkGreyHover};
        }
      `;
    case 'secondary':
      return css`
        background-color: ${colors.grey2};
        color: ${colors.systemBlack};

        &:hover {
          background-color: ${colors.grey3};
        }
      `;
    case 'rounded':
      return css`
        background-color: ${colors.wibeYellow};
        color: ${colors.wibeDarkGrey};
        border-radius: 100px;
        padding: ${spacing.xs} ${spacing.side};
        min-width: 0;

        &:hover {
          background-color: ${colors.wibeYellowHover};
        }
      `;
    case 'small':
      return css`
        background-color: ${colors.wibeYellow};
        color: ${colors.wibeDarkGrey};
        border-radius: 100px;
        padding: ${spacing.xs};
        min-width: 0;
        font-size: ${fontSizes.paragraph12};

        &:hover {
          background-color: ${colors.wibeYellowHover};
        }
      `;
    case 'transparent':
      return css`
        background: none;
        color: ${colors.systemBlack};
        padding: ${spacing.s} 0;

        &:hover {
          background: none;
        }

        svg {
          margin-inline-end: ${spacing.xs};
        }
      `;
    default:
      return css`
        background-color: ${colors.wibeYellow};
        color: ${colors.wibeDarkGrey};

        &:hover {
          background-color: ${colors.wibeYellowHover};
        }
      `;
  }
};

const getBackgroundVariantColor = (variant?: ButtonVariant) => {
  switch (variant) {
    case 'primary':
      return css`
        color: ${colors.wibeYellow};
      `;
    case 'secondary':
      return css`
        color: ${colors.grey2};
      `;
    case 'rounded':
      return css`
        color: ${colors.wibeYellow};
      `;
    default:
      return css`
        color: ${colors.wibeYellow};
      `;
  }
};

const rotate = keyframes`
  from { transform: translate(-50%, -50%) rotate(0deg); opacity: 1; }
  to   { transform: translate(-50%, -50%) rotate(360deg); opacity: 1; }
`;

export const StyledButton = styled.button<StyledButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: ${spacing.s} ${spacing.side};
  cursor: pointer;
  border: none;
  transition: 0.2s;
  border-radius: ${radii.light};
  font-size: ${fontSizes.paragraph16};
  line-height: ${lineHeights.paragraph16};
  font-weight: ${fontWeights.regular};

  ${({ $variant }) => getColor($variant)};
  ${({ $variant, $loading }) =>
    $loading &&
    css`
      position: relative;
      ${getBackgroundVariantColor($variant)};

      &::after {
        content: '';
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        inline-size: 24px;
        block-size: 24px;
        background-image: url('/assets/gfx/loading-circle.svg');
        background-repeat: no-repeat;
        background-position: center;
        animation: ${rotate} 1s linear;
        animation-iteration-count: infinite;
        transform-origin: center;
      }
    `};
  svg {
    margin-inline-start: ${spacing.xs};
  }
`;
