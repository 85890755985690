import Link from 'next/link';
import styled from 'styled-components';

import { spacing } from '@hultafors/wibe/helpers';

export const ProductImageStyled = styled.div`
  inset: 0;
  overflow: hidden;
  padding: ${spacing.side};
  padding-bottom: 28px;
  padding-top: 28px;
  position: absolute;
  width: 100%;
  z-index: 2;

  .ImageWrapper {
    inline-size: 100%;

    img {
      block-size: 100%;
      object-fit: contain;
      transform: scale(1);
      transition: transform 0.3s ease-in-out;
      inline-size: 100%;
    }
  }

  a {
    box-sizing: border-box;
    text-align: center;
    inline-size: 100%;
  }
`;

export const ImageWrapper = styled(Link)`
  display: block;
  position: relative;
  width: 100%;
  aspect-ratio: 1;

  img {
    object-fit: contain;
  }
`;
