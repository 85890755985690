import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, radii, spacing } from '@hultafors/wibe/helpers';

import { paragraph16Style } from '../paragraph/paragraph.styled';

export const HighlightedFilterStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.desktop}) {
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
  }

  .FilterName {
    display: block;
    ${paragraph16Style}
  }

  .TextAndIcon {
    display: flex;
    align-items: center;

    svg {
      transition-duration: 0.2s;
      margin-block-start: 2px;
      margin-inline-start: ${spacing.xs};
    }
  }

  .DropDownFilters {
    display: flex;
    flex-direction: column;
    margin-block-end: -${spacing.s}; /* To cancel out FilterWrapper margin-bottom. */

    @media screen and (min-width: ${breakpoints.desktop}) {
      flex-direction: row;
      margin-block-end: 0;
    }
  }

  .FilterWrapper {
    position: relative;
    margin-block-end: ${spacing.s};

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-block-end: 0;
    }

    button {
      padding: ${spacing.s} ${spacing.m};
      margin-inline-end: 0;
      border: thin solid ${colors.grey2};
      border-radius: ${radii.light};
      text-decoration: none;
      block-size: auto;
      inline-size: 100%;
      justify-content: space-between;

      @media screen and (min-width: ${breakpoints.desktop}) {
        margin-inline-end: ${spacing.m};
        inline-size: fit-content;
        justify-content: center;
      }

      &.Active {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .SubFilters {
      display: none;
      inset-block-start: 65px;
      border: thin solid ${colors.grey2};
      padding: ${spacing.s};
      background-color: ${colors.white};
      z-index: 3;
      position: absolute;
      inline-size: 100%;

      @media screen and (min-width: ${breakpoints.desktop}) {
        inline-size: fit-content;
      }

      &.Active {
        display: block;
      }
    }
  }
`;
