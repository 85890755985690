import Link from 'next/link';
import styled from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

import { Paragraph } from '../paragraph/paragraph';

export const Address = styled(Paragraph).attrs({
  styleType: 'paragraph16',
})`
  margin: 0;
`;

export const AddressRow = styled.span``;
export const NameLabel = styled(Paragraph).attrs({
  styleType: 'paragraph16',
})`
  font-weight: ${fontWeights.medium};
  margin: 0;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s};

  &:empty {
    display: none;
  }
`;

export const FieldLink = styled(Paragraph).attrs({
  color: 'wibeDarkGrey',
  styleType: 'paragraph16',
})`
  display: flex;
  gap: ${spacing.s};
  margin: 0;
  color: ${colors.wibeDarkGrey};

  &:hover {
    color: ${colors.systemBlack};
  }
`;

export const OpeningHours = styled(Paragraph).attrs({
  styleType: 'paragraph16',
})``;
export const StoreItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.m};
  padding-block-end: ${spacing.s};

  /* a {
    font-size: ${fontSizes.paragraph16};
    text-decoration: none;

    &:hover {
      color: ${colors.systemBlack};
    }
  } */

  .Label {
    margin: 0;
  }

  .LeftIconLink img {
    inset-inline-start: 0;
    position: absolute;
    inset-block-start: 50%;
    transform: translateY(-50%);
  }

  .Contact {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0;

    &.EmailAndPhoneWrapper {
      align-items: flex-start;
      color: ${colors.wibeDarkGrey};
      flex-direction: column;
      block-size: auto;
      padding-block-end: ${spacing.s};
    }

    .Phone {
      padding-block-end: ${spacing.s};
    }

    .Email {
      .EmailLink {
        padding: 0 ${spacing.s};
      }

      .EmailLink:first-child {
        padding: 0 ${spacing.xs} 0 ${spacing.s};
      }

      .AddLeftBorder {
        border-inline-start: 1px solid ${colors.grey3};
      }
    }

    &.VisitSite {
      justify-content: flex-start;
      padding: 0 0 ${spacing.s} 0;

      a {
        align-items: center;
        color: ${colors.wibeDarkGrey};
        display: flex;
        flex-direction: row;

        img {
          margin-inline-end: ${spacing.s};
        }
      }
    }

    label {
      margin-block-end: 6px;
      inline-size: 100%;
    }

    &.StoreSells {
      align-items: center;
      padding: ${spacing.s} 0;

      .StoresWrapper {
        flex-direction: column;

        .storeText {
          color: ${colors.black};
          font-size: ${fontSizes.paragraph16};
          line-height: ${lineHeights.paragraph16};
        }
      }

      a {
        color: ${colors.black};
        font-size: ${fontSizes.paragraph16};
        line-height: ${lineHeights.paragraph16};
        text-decoration: underline;
      }
    }

    .Stores {
      display: flex;
      flex-wrap: wrap;
      inline-size: 100%;

      div {
        .Separator {
          display: inline-flex;
          margin: 0 5px;
        }
      }
    }
  }

  .MapLink {
    text-decoration: none;
  }

  .LeftIconLink {
    padding-inline-start: 40px;
    position: relative;
  }
`;

export const OtherBrands = styled.div``;
export const OtherBrandsLabel = styled.p`
  color: ${colors.black};
  font-size: ${fontSizes.paragraph16};
  line-height: ${lineHeights.paragraph16};
  margin: 0;

  &:empty {
    display: none;
  }
`;
export const OtherBrandsList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.xs};
  list-style: none;
  width: 100%;
`;
export const OtherBrandsItem = styled.li`
  &:not(:last-child)::after {
    content: '|';
    margin-inline-start: ${spacing.xs};
  }
`;

export const OtherBrandsLink = styled(Link)`
  color: ${colors.black};
  font-size: ${fontSizes.paragraph16};
  line-height: ${lineHeights.paragraph16};
  text-decoration: underline;
`;
