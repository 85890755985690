import styled, { CSSProperties } from 'styled-components';

interface StyledFlexProps {
  $align?: CSSProperties['alignItems'];
  $justify?: CSSProperties['justifyContent'];
  $margin?: CSSProperties['margin'];
  $padding?: CSSProperties['padding'];
  $wrap?: CSSProperties['flexWrap'];
  $direction?: CSSProperties['flexDirection'];
}

export const StyledFlex = styled.div<StyledFlexProps>`
  align-items: ${({ $align = 'flex-start' }) => $align};
  display: flex;
  flex-flow: ${({ $direction = 'row' }) => $direction}
    ${({ $wrap = 'nowrap' }) => $wrap};
  justify-content: ${({ $justify = 'center' }) => $justify};
  margin: ${({ $margin = 'inherit' }) => $margin};
  padding: ${({ $padding = 'inherit' }) => $padding};
`;
