import { StyledIndicator } from './favorite-indicator.styled';

interface FavoriteIndicatorProps {
  active?: boolean;
}

export const FavoriteIndicator: React.FC<FavoriteIndicatorProps> = ({
  active = false,
}) => {
  return <StyledIndicator $active={active} />;
};
