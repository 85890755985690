import styled from 'styled-components';

import { spacing } from '@hultafors/wibe/helpers';

export const StyledIconButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  height: auto;
  margin: 0;
  min-height: ${spacing.m};
  min-width: ${spacing.m};
  outline: inherit;
  padding: 0;
  width: auto;
  transition: opacity 0.2s ease-out;

  &:disabled {
    opacity: 0.333;
    cursor: not-allowed;
  }
`;
