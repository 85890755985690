import { spacing } from '@hultafors/wibe/helpers';
import { GridCategoryFragment } from '@hultafors/wibe/types';

import { Icon } from '../icon/icon';
import { Paragraph } from '../paragraph/paragraph';

import {
  Arrow,
  CategoryName,
  ProdImage,
  StyledCategoryBox,
} from './category-box.styled';

interface CategoryBoxProps {
  name: string;
  url: string;
  image: GridCategoryFragment['image'];
  description?: string;
}

export const CategoryBox: React.FC<CategoryBoxProps> = ({
  name,
  url,
  image,
  description,
}) => {
  return (
    <StyledCategoryBox href={url} $dark={!image} $margin={!image}>
      {image && <ProdImage src={image?.url} alt="Product category" />}
      <CategoryName styleType={image ? 'header4' : 'header3'}>
        {name}
      </CategoryName>
      {description && !image && (
        <Paragraph styleType="paragraph16" margin={`${spacing.xs} 0 0 0`}>
          {description}
        </Paragraph>
      )}
      <Arrow>
        <Icon svg="xl-arrow-right" />
      </Arrow>
    </StyledCategoryBox>
  );
};
