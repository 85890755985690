import dynamic from 'next/dynamic';

import { ParsedImage } from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/wibe/hooks';
import { WibeProduct } from '@hultafors/wibe/types';

import { Icon } from '../icon/icon';
import { Model } from '../model/model';

import {
  ErrorMessage,
  Header,
  Models,
  ModelSectionStyled,
  ProductName,
  Title,
} from './models-section.styled';
const Skeleton = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Skeleton),
);

interface ModelsSectionProps {
  header: string;
  productName: string;
  models?: WibeProduct[];
  measureImage?: ParsedImage;
  heightFieldLabel: string;
  loading?: boolean;
  error?: Error;
}

export const ModelsSection: React.FC<ModelsSectionProps> = ({
  models = [],
  header,
  productName,
  heightFieldLabel,
  measureImage,
  loading,
  error,
}) => {
  const { global } = useGlobal();
  const modelMapper = (model: WibeProduct) => {
    return (
      <div key={`Model-${model.sku}`}>
        <Model
          model={model}
          measureImage={measureImage}
          heightFieldLabel={heightFieldLabel}
        />
      </div>
    );
  };

  const skeletonMapper = (_value: number, index: number) => (
    <Skeleton key={`ModelSkeleton-${index}`} width="100%" height={209} />
  );

  return (
    <ModelSectionStyled id="models">
      <Header>
        <Icon svg="models" />
        <div>
          <Title>{header}</Title>
          <ProductName>{productName}</ProductName>
        </div>
      </Header>
      <Models>
        {error && global?.errorMessage && !models.length ? (
          <ErrorMessage>{global.errorMessage}</ErrorMessage>
        ) : loading ? (
          new Array(3).fill(1).map(skeletonMapper)
        ) : (
          models.map(modelMapper)
        )}
      </Models>
    </ModelSectionStyled>
  );
};
