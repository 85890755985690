import { MarkdownToJSX } from 'markdown-to-jsx';

import { StyledMarkdown } from './markdown.styled';

interface MarkdownProps {
  children?: string;
  className?: string;
  options?: MarkdownToJSX.Options;
}

export const Markdown: React.FC<MarkdownProps> = ({
  children,
  className,
  options,
}) => (
  <StyledMarkdown className={className} options={options}>
    {children ?? ''}
  </StyledMarkdown>
);
