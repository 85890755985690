import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';
import { buttonReset } from '@hultafors/shared/helpers';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  spacing,
} from '@hultafors/wibe/helpers';

import { AlertSize, alertSizes, AlertStatus, alertStatuses } from './alert';

export type AlertPlacement = 'bottom' | 'top';

interface StyledAlertProps {
  $size: AlertSize;
  $status: AlertStatus;
  $position: CSSProperties['position'];
  $placement: AlertPlacement /* to combine with position: "fixed" */;
}

const getStatusStyle = ({ $status }: StyledAlertProps) =>
  ({
    error: css`
      background: ${colors.grey4};
      color: ${colors.white};

      button {
        background: ${colors.white};
        color: ${colors.systemBlack};
      }
    `,
    info: css`
      align-items: flex-start;
      background: ${colors.white};
      color: ${colors.systemBlack};
      flex-direction: column;
      margin: 0;
      margin: ${spacing.s};
      width: calc(100% - 32px);

      p {
        padding-block-end: ${spacing.s};
      }

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        margin: ${spacing.m};
        max-inline-size: 524px;
      }
    `,
    success: css`
      background: ${colors.wibeGreen};
      color: ${colors.systemBlack};

      button {
        background: ${colors.systemBlack};
        color: ${colors.white};
      }
    `,
    warning: css`
      background: ${colors.wibeYellow};
      color: ${colors.systemBlack};

      button {
        background: ${colors.systemBlack};
        color: ${colors.white};
      }
    `,
  }[$status in alertStatuses ? $status : 'info']);

const getSizeStyle = ({ $size }: StyledAlertProps) =>
  ({
    large: css({
      padding: spacing.m,
    }),
    medium: css({
      padding: spacing.s,
    }),
    small: css({
      padding: spacing.xs,
    }),
  }[$size in alertSizes ? $size : 'medium']);

const getPositionStyle = ({ $position, $placement }: StyledAlertProps) => css`
  position: ${$position};
  ${$position === 'fixed' &&
  css`
    inset: ${$placement === 'top' ? 0 : 'auto'} 0
      ${$placement === 'bottom' ? 0 : 'auto'} 0;
    margin: 0 auto;
    width: 100%;
  `}
  ${$position === 'absolute' &&
  css`
    inset: ${$placement === 'top' ? 0 : 'auto'} 0
      ${$placement === 'bottom' ? 0 : 'auto'} 0;
    margin: 0 auto;
    width: 100%;
  `}
`;

export const StyledAlert = styled.div<StyledAlertProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing.s} auto;
  z-index: 3;
  box-shadow: 0 1px 10px rgb(0 0 0 / 10%);
  border-radius: ${radii.light};

  button {
    ${buttonReset};
    font-size: ${fontSizes.paragraph14};
    min-block-size: 0;
    padding: ${spacing.xs} ${spacing.s};
  }

  ${getPositionStyle};
  ${getStatusStyle};
  ${getSizeStyle};
  .CookieConsentText {
    font-size: ${fontSizes.paragraph16};
    font-weight: ${fontWeights.regular};
    line-height: ${lineHeights.paragraph16};

    a {
      color: ${colors.systemBlack};
      font-size: ${fontSizes.paragraph16};
      font-weight: ${fontWeights.regular};
      line-height: ${lineHeights.paragraph16};
      text-decoration: none;
      text-decoration: underline;
    }
  }

  .closeButton {
    display: flex;
    justify-content: flex-end;
    inline-size: 100%;

    &:hover {
      cursor: pointer;
    }
  }
`;
