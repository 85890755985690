import { CSSProperties } from 'styled-components';

import { StyledFlex } from './flex.styled';

interface FlexProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
  margin?: CSSProperties['margin'];
  padding?: CSSProperties['padding'];
  wrap?: CSSProperties['flexWrap'];
  direction?: CSSProperties['flexDirection'];
}

export const Flex: React.FC<FlexProps> = ({
  align = 'flex-start',
  justify = 'center',
  direction = 'row',
  wrap = 'nowrap',
  children,
  margin,
  padding,
  className,
  style,
}) => {
  return (
    <StyledFlex
      className={className}
      style={style}
      $wrap={wrap}
      $direction={direction}
      $justify={justify}
      $align={align}
      $margin={margin}
      $padding={padding}
    >
      {children}
    </StyledFlex>
  );
};
