import { Fragment, useId } from 'react';

import { Maybe } from '@hultafors/wibe/types';

import { Icon } from '../icon/icon';

import {
  Address,
  FieldLink,
  Fields,
  NameLabel,
  OpeningHours,
  OtherBrands,
  OtherBrandsItem,
  OtherBrandsLabel,
  OtherBrandsLink,
  OtherBrandsList,
  StoreItemStyled,
} from './store-item.styled';

// TODO replace with proper type
interface TempStoreSells {
  url: string;
  name: string;
}

interface StoreItemProps {
  label?: Maybe<string>;
  coordinates?: {
    lng: string | number;
    lat: string | number;
  };
  address?: Maybe<string>;
  zipCode?: Maybe<string>;
  city?: Maybe<string>;
  country?: Maybe<string>;
  mapLink?: Maybe<string>;
  view_on_map?: Maybe<string>;
  emailAddress?: Maybe<string>;
  phone?: Maybe<string>;
  storeSells?: TempStoreSells[];
  this_store_also_sells?: Maybe<string>;
  website?: Maybe<string>;
  visit_web_site?: Maybe<string>;
  local_web?: Maybe<string>;
  openingHours?: Maybe<string>;
  nameLabel?: Maybe<string>;
  email?: Maybe<string>;
  emailLabel?: Maybe<string>;
  viewOnMap?: Maybe<string>;
  visitWebSite?: Maybe<string>;
}

export const StoreItem: React.FC<StoreItemProps> = ({
  nameLabel,
  address,
  zipCode,
  city,
  country,
  mapLink,
  viewOnMap,
  email,
  emailLabel,
  phone,
  storeSells = [],
  this_store_also_sells,
  website,
  visitWebSite,
  openingHours,
}) => {
  const uid = useId();

  const brandMapper = (brand: TempStoreSells) => {
    return (
      <OtherBrandsItem key={`Store-${uid}-Brand-${brand.name}`}>
        <OtherBrandsLink
          href={brand.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {brand.name}
        </OtherBrandsLink>
      </OtherBrandsItem>
    );
  };
  const addressRows = [
    address,
    [zipCode, city].filter(Boolean).join(' '),
    country,
  ].filter(Boolean);

  function addressMapper(
    row: Maybe<string | undefined> = '',
    index: number,
    array: Maybe<string | undefined>[],
  ) {
    if (!row) {
      return null;
    }
    const key = `Store-${uid}-Address-${index}`;
    const end = index < array.length - 1 ? <br /> : '';
    return (
      <Fragment key={key}>
        {row}
        {end}
      </Fragment>
    );
  }

  return (
    <StoreItemStyled>
      <Address>
        {nameLabel && <NameLabel>{nameLabel}</NameLabel>}
        {addressRows.map(addressMapper)}
      </Address>
      <Fields>
        {viewOnMap && mapLink && (
          <FieldLink
            as="a"
            href={mapLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon svg="place-tag" />
            {viewOnMap}
          </FieldLink>
        )}
        {phone && (
          <FieldLink as="a" href={`tel:${phone}`}>
            <Icon svg="phone" />
            {phone}
          </FieldLink>
        )}
        {email && (
          <FieldLink as="a" href={`mailto:${email}`}>
            <Icon svg="mail" />
            {emailLabel || email}
          </FieldLink>
        )}
      </Fields>
      {openingHours && <OpeningHours>{openingHours}</OpeningHours>}

      {!!storeSells.length && (
        <OtherBrands>
          <OtherBrandsLabel>{this_store_also_sells}</OtherBrandsLabel>
          <OtherBrandsList>{storeSells.map(brandMapper)}</OtherBrandsList>
        </OtherBrands>
      )}

      {website && (
        <FieldLink
          as="a"
          href={website}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon svg="external-link" />
          {visitWebSite || website}
        </FieldLink>
      )}
    </StoreItemStyled>
  );
};
