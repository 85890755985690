import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

export const Container = styled.div`
  background-color: ${colors.grey1};
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(2, auto);
  gap: ${spacing.s};
  padding: ${spacing.l} ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: ${spacing.m} ${spacing.side};
    grid-template-columns: 6fr 4fr;
    grid-template-rows: auto;
    gap: ${spacing.m};
  }
`;

export const Column = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &:empty {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 4 / 3;

  &:empty {
    display: none;
  }
`;
