import { useId } from 'react';

import clsx from 'clsx';

import { CtaFragment, HeroFragment } from '@hultafors/wibe/types';

import { Button } from '../button/button';
import { HTag } from '../h-tag/h-tag';
import { HeroImage } from '../hero-image/hero-image';
import { Markdown } from '../markdown/markdown';
import { Paragraph } from '../paragraph/paragraph';

import styles from './hero.module.scss';

interface HeroProps extends Omit<HeroFragment, 'id'> {
  ingress?: string;
  cta?: CtaFragment[];
  priority?: boolean;
}

export const Hero: React.FC<HeroProps> = ({
  cta,
  description,
  firstCtaLink,
  firstCtaText,
  image,
  imageMobile,
  imageTablet,
  ingress,
  label,
  priority,
  secondCtaLink,
  secondCtaText,
  title,
  titleAtTheTop,
  wide,
}) => {
  const uid = useId();
  if (!image && !title) {
    return null;
  }

  const style: any = {};
  if (image?.responsiveImage?.aspectRatio) {
    style['--aspect-ratio-desktop'] = image.responsiveImage.aspectRatio;
  }
  if (!image) {
    style['--aspect-ratio-desktop'] = 'auto';
  }

  const ctaList: Pick<CtaFragment, 'url' | 'label'>[] = [];
  if (firstCtaLink) {
    ctaList.push({ label: firstCtaText, url: firstCtaLink });
  }
  if (secondCtaLink) {
    ctaList.push({ label: secondCtaText, url: secondCtaLink });
  }

  const ctaMapper = (item: Partial<CtaFragment>, index: number) => {
    if (!item?.url) {
      return null;
    }
    const key = `Hero-${uid}-cta-${item.id || index}`;
    const variant = index === 0 ? 'rounded' : 'secondary';
    return (
      <Button to={item.url} key={key} variant={variant}>
        {item.label || item.url}
      </Button>
    );
  };

  // Below function is only used while component is still used outside of content area
  // to ensure smooth transitioning
  function checkIfIsContentArea() {
    if (ingress && cta?.length) {
      return (
        <>
          <HTag
            className={styles['preamble']}
            color="white"
            styleType="heroIngress"
            type="h2"
          >
            {ingress}
          </HTag>
          {!!cta?.length && (
            <div className={styles['button-wrapper']}>{cta.map(ctaMapper)}</div>
          )}
        </>
      );
    }
    return (
      <>
        {description && (
          <Markdown
            className={clsx('hero-description', 'bold', styles['description'])}
            options={{ forceBlock: true }}
          >
            {description}
          </Markdown>
        )}
        {!!ctaList?.length && (
          <div className={styles['button-wrapper']}>
            {ctaList.map(ctaMapper)}
          </div>
        )}
      </>
    );
  }

  return (
    <div
      className={clsx(
        styles['hero'],
        titleAtTheTop && styles['above'],
        wide && styles['wide'],
      )}
      style={style}
    >
      {titleAtTheTop && (
        <>
          <div className={styles['content']}>
            <HTag
              type="h1"
              styleType={[
                { styleType: 'header2' },
                { breakpoint: 'mobileMax', styleType: 'mega1' },
              ]}
              className={styles['title']}
            >
              {title}
            </HTag>
            {description && (
              <Markdown
                className={clsx(
                  'hero-description',
                  'bold',
                  styles['description'],
                )}
                options={{ forceBlock: true }}
              >
                {description}
              </Markdown>
            )}
            {!!ctaList?.length && (
              <div className={styles['button-wrapper']}>
                {ctaList.map(ctaMapper)}
              </div>
            )}
          </div>
          {image && (
            <div className={clsx(styles['background'])}>
              <HeroImage
                desktop={image}
                tablet={imageTablet}
                mobile={imageMobile}
                priority={priority}
              />
            </div>
          )}
        </>
      )}
      {!titleAtTheTop && (
        <div className={clsx(styles['background'])}>
          <div className={styles['content']}>
            {label && (
              <Paragraph
                className={styles['label']}
                color="white"
                styleType="heroLabel"
              >
                {label}
              </Paragraph>
            )}
            <HTag
              color="white"
              type="h1"
              styleType={[
                { styleType: 'header2' },
                { breakpoint: 'mobileMax', styleType: 'mega1' },
              ]}
              className={styles['title']}
            >
              {title}
            </HTag>
            {checkIfIsContentArea()}
          </div>
          {image && (
            <div className={styles['image-container']}>
              <HeroImage
                desktop={image}
                tablet={imageTablet}
                mobile={imageMobile}
                priority={priority}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
