import {
  Description,
  StyledVisibleSeoBlock,
  TextWrapper,
  Title,
} from './visible-seo-block.styled';

interface VisibleSeoBlockProps {
  __typename?: 'VisibleSeoBlockRecord' | undefined;
  id?: any;
  title?: string | null | undefined;
  description?: string | null | undefined;
}

export const VisibleSeoBlock: React.FC<VisibleSeoBlockProps> = ({
  title,
  description,
}) => {
  if (!title && !description) {
    return null;
  }

  const getNoOrphanText = () => {
    if (!title) {
      return null;
    }
    const words = title.split(' ');

    if (title.length > 50) {
      const lastPart = words.slice(-2);
      words.slice(-2);
      const firstRow = words.slice(0, -2);
      return (
        <>
          <span>{firstRow.join(' ')}</span>
          <span> {' ' + lastPart.join(' ')}</span>
        </>
      );
    }
    return title;
  };
  return (
    <StyledVisibleSeoBlock>
      <TextWrapper>
        {title && <Title>{getNoOrphanText()}</Title>}
        {description && <Description>{description}</Description>}
      </TextWrapper>
    </StyledVisibleSeoBlock>
  );
};
