import { spacing } from '@hultafors/wibe/helpers';
import { SimpleTextAndCtaFragment } from '@hultafors/wibe/types';

import { Box } from '../box/box';
import { Button } from '../button/button';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { HTag } from '../h-tag/h-tag';

import { StyledSimpleTextAndCta } from './simple-text-and-cta.styled';

interface SimpleTextAndCtaProps extends SimpleTextAndCtaFragment {
  cta?: {
    label: string;
    link: string;
  };
}

export const SimpleTextAndCta: React.FC<SimpleTextAndCtaProps> = ({
  text,
  cta,
  ctaLabel,
  ctaLink,
}) => {
  if ((!text && !cta) || (!text && !ctaLabel && !ctaLink)) {
    return null;
  }

  // Below function is only used while component is still used outside of content area
  // to ensure smooth transitioning
  function checkIfIsContentArea() {
    if (cta && cta.link && cta.label) {
      return (
        <Button to={cta.link} style={{ marginTop: spacing.m }}>
          {cta.label}
        </Button>
      );
    }
    return (
      <Button to={ctaLink || ''} style={{ marginTop: spacing.m }}>
        {ctaLabel}
      </Button>
    );
  }
  return (
    <StyledSimpleTextAndCta>
      <Box>
        <Grid columns={10} columnGap="s" rowGap="m">
          <GridChild
            columnSpan={[
              { columns: 10 },
              { breakpoint: 'desktop', columns: 6 },
            ]}
          >
            <HTag type="h2" styleType="header3">
              {text}
            </HTag>
            {checkIfIsContentArea()}
          </GridChild>
        </Grid>
      </Box>
    </StyledSimpleTextAndCta>
  );
};
