import { StyledCategorySection } from './category-section.styled';

interface CategorySectionProps {
  children?: React.ReactNode;
}

export const CategorySection: React.FC<CategorySectionProps> = ({
  children,
}) => {
  return <StyledCategorySection>{children}</StyledCategorySection>;
};
