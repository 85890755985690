import styled from 'styled-components';

import {
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

export const SliderWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr ${spacing.l} 1fr;
  gap: ${spacing.xs};

  /* justify-content: center; */

  /* align-items: center; */
`;

export const Index = styled.span`
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  font-size: ${fontSizes.paragraph16};
  font-weight: ${fontWeights.regular};
  line-height: ${lineHeights.paragraph16};
`;

export const StepButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  min-height: 44px;
  min-width: 44px;
  place-self: center;

  /* margin-block: ${spacing.s}; */

  /* margin-inline: ${spacing.l}; */

  /* padding: ${spacing.m}; */
  padding: 0;

  &:hover {
    opacity: 0.5;
  }
`;
