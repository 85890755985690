import Link from 'next/link';

import { Icon } from '../icon/icon';
import { Paragraph } from '../paragraph/paragraph';

import {
  PartnerLoginLinkContainer,
  PartnerLoginLinkWrapper,
} from './partner-login-link.styled';

interface PartnerLoginLinkProps {
  partnerPortalLabel: string;
  urlToPartnerPortal: string;
}

export const PartnerLoginLink: React.FC<PartnerLoginLinkProps> = ({
  partnerPortalLabel,
  urlToPartnerPortal,
}) => {
  return (
    <PartnerLoginLinkContainer>
      <PartnerLoginLinkWrapper>
        <Link
          href={urlToPartnerPortal}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Paragraph styleType="paragraph16">{partnerPortalLabel}</Paragraph>
          <Icon svg="partner-login-24" />
        </Link>
      </PartnerLoginLinkWrapper>
    </PartnerLoginLinkContainer>
  );
};
