import styled from 'styled-components';

import { colors, spacing } from '@hultafors/wibe/helpers';

export const PartnerLoginLinkContainer = styled.div`
  background-color: ${colors.grey2};
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const PartnerLoginLinkWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 72px;

  a {
    display: flex;

    svg {
      margin-inline: ${spacing.xs} ${spacing.m};
    }
  }
`;
