import styled, { css } from 'styled-components';

import { buttonReset } from '@hultafors/shared/helpers';

import { colors, radii, spacing } from '@hultafors/wibe/helpers';

interface FavoriteStyledProps {
  $active?: boolean;
  $outerButton?: boolean;
}

export const FavoriteStyled = styled.button<FavoriteStyledProps>`
  ${buttonReset};
  width: auto;
  border-radius: ${radii.light};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  z-index: 4;
  ${({ $outerButton }) =>
    $outerButton &&
    css`
      background: ${colors.grey2};
      padding: ${spacing.s};
    `}
`;
