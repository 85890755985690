import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontWeights, spacing } from '@hultafors/wibe/helpers';

import { H2 } from '../h2/h2';

export const Container = styled.div`
  margin: ${spacing.l} ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.xl} 0;
  }
`;

export const StyledMarkdown = styled(Markdown)`
  em,
  strong {
    font-weight: ${fontWeights.medium};
    font-style: inherit;
  }

  em {
    color: ${colors.wibeYellow};
  }

  strong {
    color: ${colors.wibeGreen};
  }
`;

export const Header = styled(H2)`
  font-weight: ${fontWeights.thin};
  font-size: clamp(1.75rem, 5.56vw, 5rem);
  line-height: 1.25;
  max-width: 18ch;
`;
