import { useId } from 'react';

import {
  DynamicContentField,
  DynamicContentTypeGuards,
} from '@hultafors/wibe/types';

import { CampaignInfo } from '../campaign-info/campaign-info';
import { ContactCard } from '../contact-card/contact-card';
import { DualImage } from '../dual-image/dual-image';
import { DynamicImage } from '../dynamic-image/dynamic-image';
import { GreenInfoBox } from '../green-info-box/green-info-box';
import { GreyInfoBox } from '../grey-info-box/grey-info-box';
import { IconBox } from '../icon-box/icon-box';
import { IFrameContainer } from '../iframe-container/iframe-container';
import { ImageSlider } from '../image-slider/image-slider';
import { LargeHeader } from '../large-header/large-header';
import { List } from '../list/list';
import { Quote } from '../quote/quote';
import { TextBlock } from '../text-block/text-block';
import { Video } from '../video/video';
import { WideTextBlock } from '../wide-text-block/wide-text-block';

import { StyledDynamicContent } from './dynamic-content.styled';

interface DynamicContentProps {
  content: DynamicContentField[];
}

export const DynamicContent: React.FC<DynamicContentProps> = ({ content }) => {
  const uid = useId();

  const {
    isBulletList,
    isCampaignInfo,
    isContactCard,
    isDotDigitalForm,
    isGreenInfoBox,
    isGreyInfoBox,
    isIconBox,
    isImageBlock,
    isImageSlider,
    isLargeHeader,
    isQuote,
    isTextBlock,
    isTwoImageBlock,
    isVideo,
    isWideTextBlock,
  } = DynamicContentTypeGuards;

  const contentMapper = (item: DynamicContentField) => {
    const key = `DynamicContent-${uid}-${item.id}`;
    if (isBulletList(item)) {
      return (
        <List
          key={key}
          title={item.title || ''}
          body={item.list || ''}
          isBig={item.isBigBulletList}
          ctaText={item.ctaText || ''}
          ctaLink={item.ctaLink || ''}
          ctaIsLink={item.ctaIsLink}
        />
      );
    }
    if (isCampaignInfo(item)) {
      return (
        <CampaignInfo
          key={key}
          background={item.background}
          backgroundTablet={item.backgroundTablet}
          backgroundMobile={item.backgroundMobile}
          image={item.image}
          body={item.body || ''}
        />
      );
    }
    if (isContactCard(item)) {
      return (
        <ContactCard
          key={key}
          header={item.header}
          body={item.body}
          image={item.contactImage}
        />
      );
    }
    if (isDotDigitalForm(item)) {
      return (
        <IFrameContainer
          key={key}
          title="Test"
          src={item.formUrl ?? ''}
          height={`${item.formHeight}px`}
        />
      );
    }
    if (isGreenInfoBox(item)) {
      return <GreenInfoBox key={key} body={item.body} />;
    }
    if (isGreyInfoBox(item)) {
      return <GreyInfoBox key={key} title={item.title} body={item.body} />;
    }
    if (isIconBox(item)) {
      return <IconBox key={key} items={item.icons} />;
    }
    if (isImageBlock(item)) {
      return (
        <DynamicImage
          key={key}
          image={item.image}
          label={item.label}
          ctaText={item.ctaText}
          ctaLink={item.ctaLink}
          ctaIsLink={item.ctaIsLink}
        />
      );
    }
    if (isImageSlider(item)) {
      return <ImageSlider key={key} images={item.images} />;
    }
    if (isLargeHeader(item)) {
      return <LargeHeader key={key} text={item.text} />;
    }
    if (isQuote(item)) {
      return (
        <Quote
          key={key}
          body={item.body}
          // author={item.author}
          // authorProfession={item.authorProfession}
        />
      );
    }
    if (isTextBlock(item)) {
      return (
        <TextBlock
          key={key}
          body={item.body}
          header={item.header}
          isBigHeader={item.isBigHeader}
          ctaText={item.ctaText}
          ctaLink={item.ctaLink}
          ctaIsLink={item.ctaIsLink}
        />
      );
    }
    if (isTwoImageBlock(item)) {
      return (
        <DualImage
          key={key}
          imageOne={item.images?.[0]}
          imageTwo={item.images?.[1]}
        />
      );
    }
    if (isVideo(item)) {
      return (
        <Video key={key} src={item.video} thumbnail={item.videoThumbnail} />
      );
    }
    if (isWideTextBlock(item)) {
      return <WideTextBlock key={key} body={item.body} />;
    }
    return null;
  };

  return (
    <StyledDynamicContent>{content.map(contentMapper)}</StyledDynamicContent>
  );
};
