import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

export const StyledFilterToggle = styled.div`
  align-items: center;
  display: flex;
  padding-left: 0;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block-start: 0;
    inline-size: fit-content;
  }

  div {
    margin-inline: 0 ${spacing.xs};
  }

  p {
    margin-inline-end: auto;
  }

  div:last-child {
    margin-inline-end: 0;
  }

  .HelpText {
    display: none;

    .Active {
      display: block;
    }
  }
`;
