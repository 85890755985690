import { StyledGraphic, StyledGraphicWrapper } from './graphic.styled';

interface GraphicProps {
  src: string;
  alt: string;
  width?: string;
  wrapperProps?: any;
}

export const Graphic: React.FC<GraphicProps> = ({
  wrapperProps,
  width = '40px',
  src,
  alt,
}) => (
  <StyledGraphicWrapper $width={width}>
    <StyledGraphic src={src} alt={alt} width={width} />
  </StyledGraphicWrapper>
);
