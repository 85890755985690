import { CSSProperties } from 'styled-components';

import {
  GridColumnGapProp,
  GridColumnsProp,
  GridRowGapProp,
  StyledGrid,
} from './grid.styled';

interface GridProps {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  id?: string;
  autoFlow?: CSSProperties['gridAutoFlow'];
  align?: CSSProperties['alignItems'];
  margin?: CSSProperties['margin'];
  maxWidth?: CSSProperties['maxWidth'];
  center?: boolean;
  columnGap?: GridColumnGapProp;
  rowGap?: GridRowGapProp;
  columns?: GridColumnsProp;
  minColumnWidth?: string;
}

export const Grid: React.FC<GridProps> = ({
  className,
  children,
  align = 'stretch',
  columnGap = 's',
  columns = [{ columns: 4 }, { breakpoint: 'mobileMax', columns: 12 }],
  margin = '0 auto',
  maxWidth,
  rowGap = 0,
  autoFlow = 'row',
  center,
  minColumnWidth,
  style,
  id,
}) => {
  return (
    <StyledGrid
      id={id}
      className={className}
      style={style}
      $align={align}
      $columnGap={columnGap}
      $columns={columns}
      $margin={margin}
      $maxWidth={maxWidth}
      $rowGap={rowGap}
      $autoFlow={autoFlow}
      $center={center}
      $minColumnWidth={minColumnWidth}
    >
      {children}
    </StyledGrid>
  );
};
