import { Maybe } from '@hultafors/wibe/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import { Container, Header, StyledMarkdown } from './large-header.styled';

interface LargeHeaderProps {
  text?: Maybe<string>;
  className?: string;
}

export const LargeHeader: React.FC<LargeHeaderProps> = ({
  text,
  className,
}) => {
  return (
    <Container className={className}>
      <Grid columnGap={0}>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <Header>
            <StyledMarkdown>{text}</StyledMarkdown>
          </Header>
        </GridChild>
      </Grid>
    </Container>
  );
};
