import { StyledImage } from './image-block.styled';

interface ImageProps {
  caption?: string;
  className?: string;
  sizes?: string;
  src: string;
  srcset?: string;
  alt?: string;
  width?: number;
  height?: number;
}

export const ImageBlock: React.FC<ImageProps> = ({
  alt = '',
  caption = '',
  className,
  src,
  srcset,
  sizes = '100vw',
  width,
  height,
}) => {
  if (!src) {
    return null;
  }
  return (
    <StyledImage
      alt={alt || caption}
      src={src}
      srcSet={srcset}
      sizes={sizes}
      loading="lazy"
      title={caption || alt}
      className={className}
      width={width}
      height={height}
    />
  );
};
