import { breakpoints } from '@hultafors/shared/constants';

import { Maybe } from '@hultafors/wibe/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import { StyledGreenInfoBox, StyledMarkdown } from './green-info-box.styled';

interface GreenInfoBoxProps {
  body?: Maybe<string>;
}

export const GreenInfoBox: React.FC<GreenInfoBoxProps> = ({ body }) => {
  return body ? (
    <StyledGreenInfoBox>
      <Grid maxWidth={breakpoints.maxPageWidth} columnGap={0}>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <div className="content">
            <StyledMarkdown>{body || ''}</StyledMarkdown>
          </div>
        </GridChild>
      </Grid>
    </StyledGreenInfoBox>
  ) : null;
};
