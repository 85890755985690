import { WibeProduct } from '@hultafors/wibe/types';

import { EmptyProducts } from '../empty-products/empty-products';
import { Product } from '../product/product';

import { StyledProducts } from './products.styled';

interface ProductsProps {
  products: WibeProduct[];
  badgeText: string;
  isLoading: boolean;
  numberOfItems?: number;
  rrpLabel?: string;
  rrpIncludingVatLabel?: string;
  multipleHeightsLabel?: string;
  fromText?: string;
  modelText?: string;
  onClick?: React.MouseEventHandler;
}

export const Products: React.FC<ProductsProps> = ({
  isLoading,
  numberOfItems = 12,
  products = [],
  rrpLabel = '',
  rrpIncludingVatLabel = '',
  multipleHeightsLabel = '',
  badgeText,
}) => {
  const productMapper = (product: WibeProduct, i: number) => (
    <Product
      key={`product-item-${i}`}
      product={product}
      badgeText={badgeText}
      rrpLabel={rrpLabel}
      rrpIncludingVatLabel={rrpIncludingVatLabel}
      multipleHeightsLabel={multipleHeightsLabel}
    />
  );
  return isLoading ? (
    <EmptyProducts pageSize={numberOfItems} />
  ) : products?.length ? (
    <StyledProducts>{products.map(productMapper)}</StyledProducts>
  ) : null;
};
