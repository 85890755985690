import { useFavorites, useGlobal } from '@hultafors/wibe/hooks';

import { FavoriteIndicator } from '../favorite-indicator/favorite-indicator';

import { NavFavoritesStyled } from './nav-favorites.styled';

interface NavFavoritesProps {
  toggle?(): void;
  inTopMenu?: boolean;
  hasFavorites?: boolean;
}

export const NavFavorites: React.FC<NavFavoritesProps> = ({
  toggle,
  inTopMenu,
  hasFavorites,
}) => {
  const { global } = useGlobal();
  const { favorites } = useFavorites();
  const onClick = () => {
    toggle?.();
  };

  return (
    <NavFavoritesStyled
      onClick={onClick}
      aria-label={global?.favorites || ''}
      $inTopMenu={inTopMenu}
      $hasFavorites={hasFavorites}
    >
      <FavoriteIndicator active={favorites.length > 0} />
    </NavFavoritesStyled>
  );
};
