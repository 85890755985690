export const colors = {
  black: '#000',
  grey1: 'rgb(247, 247, 247)',
  grey2: 'rgb(234, 234, 234)',
  grey2Hover: 'rgb(208, 210, 212)',
  grey3: 'rgb(208, 210, 212)',
  grey4: 'rgb(124, 124, 124)',
  grey4b: 'rgb(97, 97, 97)',
  grey5: 'rgb(64, 64, 64)',
  // TODO: Replace
  hellSalmon: '#FA826E',

  overlay: 'rgba(0, 0, 0, 0.3)',

  systemActive: 'rgb(108, 135, 205)',

  systemBlack: 'rgb(17, 17, 17)',

  systemOk: 'rgb(116, 202, 140)',

  systemWarning: 'rgb(225, 95, 85)',

  white: '#ffffff',

  wibeDarkGrey: 'rgb(48, 62, 71)',

  wibeDarkGreyHover: 'rgb(17, 17, 17)',

  wibeDarkSteel: 'rgb(140, 150, 157)',

  wibeDarkSteelHover: 'rgb(125, 135, 142)',

  wibeGreen: 'rgb(120, 170, 100)',

  wibeGreenAlpha: 'rgba(120, 170, 100, 0.9)',

  wibeLightSteel: 'rgb(216, 224, 228)',

  wibeLightSteelHover: 'rgb(202, 210, 214)',

  wibeNew: 'rgb(54, 182, 176)',

  wibeSuperlightSteel: 'rgb(243, 246, 247)',

  wibeSuperlightSteelHover: 'rgb(247, 247, 247)',

  wibeYellow: 'rgb(255, 205, 0)',

  wibeYellowAlpha: 'rgba(255, 205, 0, 0.9)',

  wibeYellowHover: 'rgb(251, 217, 77)',
} as const;

export type Color = keyof typeof colors;
