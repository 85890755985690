import Markdown from 'markdown-to-jsx';

import { Button } from '../button/button';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { TextButton } from '../text-button/text-button';

import { ButtonWrapper, Container, Header, StyledList } from './list.styled';

interface ListProps {
  title?: string;
  body: string;
  isBig: boolean;
  ctaText?: string;
  ctaLink?: string;
  ctaIsLink?: boolean;
  dynamicContent?: boolean;
}

export const List: React.FC<ListProps> = ({
  title,
  body,
  isBig,
  ctaText,
  ctaLink,
  ctaIsLink,
}) => {
  return (
    <Container>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          {title && (
            <Header styleType={isBig ? 'header3' : 'header4'}>{title}</Header>
          )}
          <StyledList $isBig={isBig}>
            <Markdown
              options={{ forceBlock: true }}
              className={isBig ? 'markdown-wrapper big' : 'markdown-wrapper'}
            >
              {body}
            </Markdown>
            {ctaText &&
              ctaLink &&
              (ctaIsLink ? (
                <TextButton to={ctaLink} arrow>
                  {ctaText}
                </TextButton>
              ) : (
                <ButtonWrapper>
                  <Button to={ctaLink} variant="rounded">
                    {ctaText}
                  </Button>
                </ButtonWrapper>
              ))}
          </StyledList>
        </GridChild>
      </Grid>
    </Container>
  );
};
