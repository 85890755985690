import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

export const Container = styled.div`
  margin: ${spacing.s} 0 ${spacing.l};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.s} 0 ${spacing.xl};
  }
`;

export const StyledMarkdown = styled(Markdown).attrs({
  options: {
    forceBlock: true,
  },
})`
  /* align with IconBox */
  padding: 0 ${spacing.s};

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 0 ${spacing.xl};
  }

  p:not(:last-child) {
    margin-block-end: ${spacing.m};
  }

  a {
    color: ${colors.black};
    text-decoration: none;
    text-decoration: underline;
  }
`;
