import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

export const StyledInput = styled.input`
  appearance: none;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid white;
  border-radius: 5px;
  box-sizing: border-box;
  flex: 1;
  font-size: ${fontSizes.paragraph16};
  font-weight: ${fontWeights.regular};
  line-height: 1;
  line-height: ${lineHeights.paragraph16};
  padding: ${spacing.xs};
  width: 100%;

  &:focus {
    background-color: ${colors.grey1};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.s};
  }
`;
