import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { Spacing, spacing } from '@hultafors/wibe/helpers';

export type SectionPaddingProp = Spacing | [Spacing, Spacing];

interface StyledSectionProps {
  $paddingBlock?: SectionPaddingProp;
  $paddingBlockStart?: SectionPaddingProp;
  $paddingBlockEnd?: SectionPaddingProp;
  $hide?: boolean;
}

export const defaultPy: SectionPaddingProp = ['m', 'l'];

export const StyledSection = styled.div<StyledSectionProps>`
  display: ${({ $hide }) => ($hide ? 'none' : 'block')};
  margin-left: auto;
  margin-right: auto;
  max-width: ${breakpoints.maxPageWidth};
  padding-inline: ${spacing.s};
  padding-block: ${({ $paddingBlock = defaultPy }) =>
    `${
      Array.isArray($paddingBlock)
        ? spacing[$paddingBlock[0]]
        : spacing[$paddingBlock]
    } ${spacing.s}`};

  ${({ $paddingBlockEnd }) =>
    $paddingBlockEnd &&
    css`
      padding-bottom: ${Array.isArray($paddingBlockEnd)
        ? spacing[$paddingBlockEnd[0]]
        : spacing[$paddingBlockEnd]};
    `}
  ${({ $paddingBlockStart }) =>
    $paddingBlockStart &&
    css`
      padding-top: ${Array.isArray($paddingBlockStart)
        ? spacing[$paddingBlockStart[0]]
        : spacing[$paddingBlockStart]};
    `}
  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-inline: ${spacing.side};
    padding-block: ${({ $paddingBlock = defaultPy }) =>
      `${
        Array.isArray($paddingBlock)
          ? spacing[$paddingBlock[1]]
          : spacing[$paddingBlock]
      } ${spacing.s}`};
    ${({ $paddingBlockEnd }) =>
      $paddingBlockEnd &&
      css`
        padding-bottom: ${Array.isArray($paddingBlockEnd)
          ? spacing[$paddingBlockEnd[1]]
          : spacing[$paddingBlockEnd]};
      `}
    ${({ $paddingBlockStart }) =>
      $paddingBlockStart &&
      css`
        padding-top: ${Array.isArray($paddingBlockStart)
          ? spacing[$paddingBlockStart[1]]
          : spacing[$paddingBlockStart]};
      `}
  }
`;
