import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/wibe/helpers';

export const StyledProducts = styled.div`
  display: grid;
  gap: ${spacing.l} ${spacing.s};
  grid-template-columns: 1fr;

  &:empty {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: ${spacing.m};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
