import { RouterLinkVariant, StyledLink } from './router-link.styled';

interface RouterLinkProps {
  children?: React.ReactNode;
  to: string;
  name?: string;
  id?: string;
  onClick?(): void;
  dataValue?: string;
  className?: string;
  routerState?: any;
  search?: string;
  clean?: boolean;
  small?: boolean;
  variant?: RouterLinkVariant;
}

export const RouterLink: React.FC<RouterLinkProps> = ({
  dataValue,
  to,
  children,
  routerState,
  search,
  clean,
  small,
  variant = 'black',
  className,
  id,
  onClick,
}) => {
  const query: any = {};
  if (routerState) {
    query.state = routerState;
  }
  if (search) {
    query.search = search;
  }
  const target =
    typeof to === 'string' &&
    (to.includes('http://') || to.includes('https://'))
      ? '_blank'
      : undefined;

  return (
    <StyledLink
      id={id}
      onClick={onClick}
      className={className}
      href={{ pathname: to, query }}
      data-value={dataValue}
      rel="noreferrer noopener"
      target={target}
      $variant={variant}
      $clean={clean}
      $small={small}
    >
      {children}
    </StyledLink>
  );
};
