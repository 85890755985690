import React, { Fragment, useId } from 'react';

import dynamic from 'next/dynamic';

import type { ContentAreaField } from '@hultafors/wibe/types';
import {
  isAddressBlock,
  isBigPlug,
  isBulletList,
  isCategoryGrid,
  isColorPlugs,
  isContactCard,
  isContactInfoBlock,
  isDividedTitleAndText,
  isDividerBlock,
  isDotDigitalForm,
  isGreyInfoBox,
  isHero,
  isImageBlock,
  isImageSlider,
  isOfficesBlock,
  isPagePlugs,
  isParentLinkBlock,
  isPlainHeroBlock,
  isQuote,
  isRelatedProductsBlock,
  isSimpleTextAndCta,
  isTextBlock,
  isTitleAndTextBlock,
  isTwoImageBlock,
  isVideo,
  isVisibleSeoBlock,
} from '@hultafors/wibe/types';

const AddressBlock = dynamic(() =>
  import('../address-block/address-block').then((mod) => mod.AddressBlock),
);
const BigPlug = dynamic(() =>
  import('../big-plug/big-plug').then((mod) => mod.BigPlug),
);
const Divider = dynamic(() =>
  import('../divider/divider').then((mod) => mod.Divider),
);
const DualImage = dynamic(() =>
  import('../dual-image/dual-image').then((mod) => mod.DualImage),
);
const CategoryGrid = dynamic(() =>
  import('../category-grid/category-grid').then((mod) => mod.CategoryGrid),
);
const ColorPlugs = dynamic(() =>
  import('../color-plugs/color-plugs').then((mod) => mod.ColorPlugs),
);
const ContactCard = dynamic(() =>
  import('../contact-card/contact-card').then((mod) => mod.ContactCard),
);
const ContactInfoBlock = dynamic(() =>
  import('../contact-info-block/contact-info-block').then(
    (mod) => mod.ContactInfoBlock,
  ),
);
const DividedTitleAndText = dynamic(() =>
  import('../divided-text-and-title/divided-title-and-text').then(
    (mod) => mod.DividedTitleAndText,
  ),
);
const DynamicImage = dynamic(() =>
  import('../dynamic-image/dynamic-image').then((mod) => mod.DynamicImage),
);
const GreyInfoBox = dynamic(() =>
  import('../grey-info-box/grey-info-box').then((mod) => mod.GreyInfoBox),
);
const Hero = dynamic(() => import('../hero/hero').then((mod) => mod.Hero));
const IFrameContainer = dynamic(() =>
  import('../iframe-container/iframe-container').then(
    (mod) => mod.IFrameContainer,
  ),
);
const ImageSlider = dynamic(() =>
  import('../image-slider/image-slider').then((mod) => mod.ImageSlider),
);
const List = dynamic(() => import('../list/list').then((mod) => mod.List));
const OfficesBlock = dynamic(() =>
  import('../offices-block/offices-block').then((mod) => mod.OfficesBlock),
);
const HeroPlain = dynamic(() =>
  import('../hero-plain/hero-plain').then((mod) => mod.HeroPlain),
);
const PagePlugs = dynamic(() =>
  import('../page-plugs/page-plugs').then((mod) => mod.PagePlugs),
);
const ParentLinkBlock = dynamic(() =>
  import('../parent-link-block/parent-link-block').then(
    (mod) => mod.ParentLinkBlock,
  ),
);
const RelatedProductsBlock = dynamic(() =>
  import('../related-products-block/related-products-block').then(
    (mod) => mod.RelatedProductsBlock,
  ),
);
const Quote = dynamic(() => import('../quote/quote').then((mod) => mod.Quote));
const SimpleTextAndCta = dynamic(() =>
  import('../simple-text-and-cta/simple-text-and-cta').then(
    (module) => module.SimpleTextAndCta,
  ),
);
const TextBlock = dynamic(() =>
  import('../text-block/text-block').then((mod) => mod.TextBlock),
);
const TitleAndTextBlock = dynamic(() =>
  import('../title-and-text-block/title-and-text-block').then(
    (mod) => mod.TitleAndTextBlock,
  ),
);
const Video = dynamic(() => import('../video/video').then((mod) => mod.Video));
const VisibleSeoBlock = dynamic(() =>
  import('../visible-seo-block/visible-seo-block').then(
    (mod) => mod.VisibleSeoBlock,
  ),
);

export interface ContentAreaProps {
  content: ContentAreaField[];
}

export const ContentArea: React.FC<ContentAreaProps> = ({ content }) => {
  const uid = useId();
  const contentMapper = (item: ContentAreaField, index: number) => {
    const key = `ContentArea-${uid}-${item.__typename}-${index}`;

    if (isAddressBlock(item)) {
      return <AddressBlock {...item} key={key} />;
    }
    if (isBigPlug(item)) {
      return <BigPlug {...item} key={key} />;
    }
    if (isBulletList(item)) {
      return (
        <List
          key={key}
          title={item.title || ''}
          body={item.list || ''}
          isBig={item.isBigBulletList}
          ctaText={item.ctaText || ''}
          ctaLink={item.ctaLink || ''}
          ctaIsLink={item.ctaIsLink}
        />
      );
    }
    if (isCategoryGrid(item)) {
      return <CategoryGrid {...item} key={key} />;
    }

    if (isColorPlugs(item)) {
      return <ColorPlugs {...item} key={key} />;
    }

    if (isContactCard(item)) {
      return (
        <ContactCard
          key={key}
          header={item.header}
          body={item.body}
          image={item.contactImage}
        />
      );
    }
    if (isContactInfoBlock(item)) {
      return <ContactInfoBlock {...item} key={key} />;
    }
    if (isDividedTitleAndText(item)) {
      return <DividedTitleAndText {...item} key={key} />;
    }
    if (isDividerBlock(item)) {
      return <Divider {...item} key={key} />;
    }
    if (isDotDigitalForm(item)) {
      return (
        <IFrameContainer
          key={key}
          title="Test"
          src={item.formUrl ?? ''}
          height={`${item.formHeight}px`}
        />
      );
    }
    if (isGreyInfoBox(item)) {
      return <GreyInfoBox {...item} key={key} />;
    }
    if (isHero(item)) {
      return <Hero {...item} key={key} priority={index === 0} />;
    }
    if (isImageBlock(item)) {
      return (
        <DynamicImage
          key={key}
          image={item.image}
          label={item.label}
          ctaText={item.ctaText}
          ctaLink={item.ctaLink}
          ctaIsLink={item.ctaIsLink}
        />
      );
    }
    if (isImageSlider(item)) {
      return <ImageSlider key={key} images={item.images} />;
    }
    if (isOfficesBlock(item)) {
      return <OfficesBlock {...item} key={key} />;
    }
    if (isPagePlugs(item)) {
      return <PagePlugs {...item} key={key} />;
    }
    if (isParentLinkBlock(item)) {
      return <ParentLinkBlock {...item} key={key} />;
    }
    if (isPlainHeroBlock(item)) {
      return <HeroPlain {...item} key={key} />;
    }
    if (isRelatedProductsBlock(item)) {
      return <RelatedProductsBlock {...item} key={key} />;
    }

    if (isQuote(item)) {
      return (
        <Quote
          key={key}
          body={item.body}
          // author={item.author}
          // authorProfession={item.authorProfession}
        />
      );
    }
    if (isSimpleTextAndCta(item)) {
      return <SimpleTextAndCta {...item} key={key} />;
    }
    if (isTextBlock(item)) {
      return (
        <TextBlock
          key={key}
          body={item.body}
          header={item.header}
          isBigHeader={item.isBigHeader}
          ctaText={item.ctaText}
          ctaLink={item.ctaLink}
          ctaIsLink={item.ctaIsLink}
        />
      );
    }
    if (isTitleAndTextBlock(item)) {
      return <TitleAndTextBlock {...item} key={key} />;
    }
    if (isTwoImageBlock(item)) {
      return (
        <DualImage
          imageOne={item.images?.[0]}
          imageTwo={item.images?.[1]}
          key={key}
        />
      );
    }
    if (isVisibleSeoBlock(item)) {
      return <VisibleSeoBlock {...item} key={key} />;
    }
    if (isVideo(item)) {
      return (
        <Video key={key} src={item.video} thumbnail={item.videoThumbnail} />
      );
    }

    return null;
  };
  return <Fragment>{content.map(contentMapper)}</Fragment>;
};
