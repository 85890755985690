import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

export const StyledHR = styled.hr`
  border-color: ${colors.grey2};
  border-top: none;
  margin-bottom: ${spacing.s};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-end: ${spacing.l};
  }
`;
