import styled, { CSSProperties } from 'styled-components';

interface StyledGraphicWrapperProps {
  $width?: CSSProperties['width'];
}

export const StyledGraphicWrapper = styled.div<StyledGraphicWrapperProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  width: ${({ $width }) => $width};
`;

export const StyledGraphic = styled.img`
  display: inline-flex;
  width: 50%;
`;
