import { TextButton } from '../text-button/text-button';

interface LinkMarkdownWrapperProps {
  href?: string;
}

export const LinkMarkdownWrapper: React.FC<LinkMarkdownWrapperProps> = ({
  href,
}) => {
  return <TextButton to={href} />;
};
